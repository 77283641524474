import React, { FC } from 'react';
import AccordionItem from '../../../../kit/AccordionItem';

const Faq: FC = (): JSX.Element => {
    return (
        <div>
            <AccordionItem
                title="Waarom moet ik eerst mijn tijdvakken verwijderen als ik overstap naar een gratis account?"
                content={<p>
                    Met een gratis account kun je maximaal 3 tijdvakken met ieder 5 stoelen instellen. Omdat een betaald account geen limieten kent heb je waarschijnlijk meer dan 3 tijdvakken aangemaakt. Je kunt er voor kiezen om EasyZeats gratis te blijven gebruiken maar dan wel met de limieten die hiervoor staan ingesteld.
                </p>}
            />
            <AccordionItem
                title="Wat gebeurt er als ik mijn abonnement 
                opzeg?"
                content={<p>
                    Met een gratis account kun je maximaal 3 tijdvakken met ieder 5 stoelen instellen. Omdat een betaald account geen limieten kent heb je waarschijnlijk meer dan 3 tijdvakken aangemaakt. Je kunt er voor kiezen om EasyZeats gratis te blijven gebruiken maar dan wel met de limieten die hiervoor staan ingesteld.
                </p>}
            />
        </div>
    );
}

export default Faq;

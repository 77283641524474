import React from "react";
import StyledText from "../../components/StyledText";
import "./subscription-box.scss";
import Button from "../../kit/Button";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import useAlert from "../../hooks/useAlert";
import usePost from "../../hooks/rest/usePost";
import { useHistory } from "react-router-dom";
import { logout, updateProfile } from "../../redux/auth";
import ChangeSubscription from "./ChangeSubscription";
import Message from "../../kit/Message";

const SubscriptionBox = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [sendAlert] = useAlert();
	const [cancelSubscription] = usePost("/subscriptions/cancel");
	const subscription = useSelector(
		(state: any) => state.auth.current_subscription
	);

	const handleCancel = () => {
		sendAlert({
			type: "subscription-cancel",
			title: "weet u het zeker?",
			text:
				"Wanneer u uw account opzegt kunnen uw klanten geen reserveringen meer plaatsen en zullen al uw gegevens worden verwijderd",

			onConfirm: (params: any) => {
				if (params.type === 'free') {
					cancelSubscription({
						plan: subscription?.name,
						toFree: true,
					})
						.then(({ data }) => {
							dispatch(updateProfile(data));
							sendAlert({
								type: "error",
								title: "Uw account is omgezet naar een gratis account",
							});
						})
						.catch((e) => {
							sendAlert({
								type: "error",
								title: "Er is iets misgegaan",
							});
						});
				} else {
					cancelSubscription({ plan: subscription?.name })
						.then(() => {
							dispatch(logout());
							history.push("/");
						})
						.catch((e) => {
							sendAlert({
								type: "error",
								title: "Er is iets misgegaan",
							});
						});
				}
			},
		});
	};

	return (
		<div className="subscription-box">
			<StyledText className="partner-name">
				<span className="name">easyzeats</span>
				<span className="partner"> partner</span>
			</StyledText>
			<div className="info">
				<div className="info-box">
					{subscription && subscription?.cycle_ends_at ? (
						<>
							<StyledText className="status">
								Easyzeats abonnement wordt op{" "}
								{moment(subscription?.cycle_ends_at).format(
									"DD MMMM YYYY"
								)}{" "}
								verlengd.
							</StyledText>
							<div style={{ marginTop: 24 }}>
								<Message
									content="De betalingen blijven doorlopen totdat je het
									abonnement opzegt. Gedane betalingen worden niet
									terugbetaald, tenzij dit wettelijk verplicht is."
								/>
							</div>
						</>
					) : (
						<StyledText type="status">
							U maakt gebruik van een gratis Easyzeats account.
						</StyledText>
					)}
				</div>
				<div
					style={{
						marginTop:
							subscription && subscription?.cycle_ends_at
								? 20
								: 0,
					}}
					className="info-buttons"
				>
					<Button block onClick={handleCancel}>
						Opzeggen
					</Button>
					<ChangeSubscription />
				</div>
			</div>
		</div>
	);
};

export default SubscriptionBox;

import React from "react";
import Layout from "../components/Layout";
import { ContactForm } from "./Info/ContactView/index";

const HelpView = () => {
	return (
		<Layout>
			<ContactForm
				subject="Hulp"
				title="Hulp nodig"
				text="Werkt er iets niet zoals je het verwacht had? Via het onderstaand formulier helpen wij jouw graag verder."
			/>
		</Layout>
	);
};

export default HelpView;

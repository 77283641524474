import React from "react";

type DaysProps = {
	type?: string;
	activeIndexs: number[];
	onChange?: (n: any) => void;
};

const Days = ({ type, activeIndexs, onChange }: DaysProps) => {
	const classes = ["t-days"];
	const DAYS = [
		"Zondag",
		"Maandag",
		"Dinsdag",
		"Woensdag",
		"Donderdag",
		"Vrijdag",
		"Zaterdag",
	];

	if (type) classes.push(`t-days-${type}`);

	const handleClick = (index: number) => {
		let days = [];
		if (activeIndexs?.includes(index)) {
			days = activeIndexs.filter((d) => d !== index);
		} else {
			days = [...activeIndexs, index];
		}
		if (onChange) {
			onChange(days);
		}
	};

	return (
		<div className={classes.join(" ")}>
			{DAYS.map((day, index) => (
				<div
					key={day + "-" + index}
					onClick={() => handleClick(index)}
					className={`day ${
						activeIndexs?.includes(index) ? "day-active" : ""
					}`}
				>
					{day.substring(0, 2).toLowerCase()}
				</div>
			))}
		</div>
	);
};

export default Days;

import React, { FC } from "react";
import "./loading.scss";
import StyledText from "../StyledText";

const Loading: FC<{absolute?: boolean}> = ({ absolute }) => {
	return (
		<div className={`loading ${absolute ? 'loading-absolute' : ''}`}>
			<i className="fal fa-spin fa-spinner-third"></i>
			<StyledText>Een ogenblik geduld...</StyledText>
		</div>
	);
};

export default Loading;

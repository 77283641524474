import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import UsersView from '../../Admin/UsersView';
import FullView from './FullView';
import './settings-view.scss';
import DietaryNeeds from './views/DietaryNeeds';
import FourSquareListView from './views/FourSquareListView';
import FourSquareFilterListView from './views/FourSquareFilterListView';

const settingsTitles: any = {
	'dietary-needs': 'Dieëtwensen',
};

interface SettingsTabProps {
	icon: string,
	label: string,
	onClick?: (e: any) => void,
	to: string,
}

const SettingsTab: FC<SettingsTabProps> = ({ icon, label, onClick, to }): JSX.Element => {
	return (
		<Link
			to={`/admin/${to}`}
			className="settings-tab"
			onClick={onClick}
		>
			<div>
				<i className={icon} />
			</div>
			<span>
				{label}
			</span>
			<i className="far fa-chevron-right" />
		</Link>
	);
}

const SettingsView: FC = (): JSX.Element => {
	const [ showPage, setShowPage ] = useState<boolean>(false);
	const [ title, setTitle ] = useState<string>('Admin');
	const { page }: { page: string } = useParams();
	const windowWidth = useWindowWidth();

	useEffect(() => {
		setShowPage(page !== undefined);
		setTitle(page === undefined ? 'Admin' : settingsTitles[page]);
	}, [page]);

	if (windowWidth > 768) {
		return <FullView />
	}

	return (
		<Layout
			className="settings-view"
			type="admin"
			title={title}
			back={showPage ? '/admin' : undefined}
		>
			<div className="settings-header">
				Online reserveren
			</div>
			<SettingsTab
				icon="fal fa-leaf-heart"
				label="Dieëtwensen"
				to="dietary-needs"
			/>

			<div className="settings-header">
				Gebruikers
			</div>
			<SettingsTab
				icon="fal fa-user"
				label="Gegevens"
				to="users"
			/>
			<div className="settings-header">
				FSQ
			</div>
			<SettingsTab
				icon="fab fa-foursquare"
				label="Overzicht"
				to="fsq"
			/>
			<SettingsTab
				icon="far fa-filter"
				label="Filters"
				to="fsq-filters"
			/>

			<div className={`settings-page${showPage ? ' settings-page-show' : ''}`}>
				{page === 'dietary-needs' && <DietaryNeeds />}
				{page === 'users' && <UsersView />}
				{page === 'fsq' && <FourSquareListView />}
				{page === 'fsq-filters' && <FourSquareFilterListView />}
			</div>
		</Layout>
	);
}

export default SettingsView;

import findIndex from "lodash/findIndex";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Table from "../../../../kit/Table";
import axios from "../../../../lib/bootstrap";
import { debounce } from "./FourSquareListView";
import Button from "../../../../kit/Button";
import { Link } from "react-router-dom";

const FourSquareFilterListView = () => {
	const [ filters, setFilters ] = useState<any[]>([]);
	const [ meta, setMeta ] = useState<any>({});
	const [ show, setShow ] = useState<string>('');
    const queryRef = useRef<string>('');

    const fetch = useCallback((page: number = 1): void => {
        axios.get(`admin/fsq/filters?query=${queryRef.current}&page=${page}${show === '' ? '' : `&show=${show}`}`).then(({ data }) => {
            setFilters(data.data);
			setMeta(data.meta);
        });
    }, [show]);
    useEffect(() => {
        fetch();
    }, [fetch]);

	const search = debounce(() => {
		fetch(1);
	});

	const deleteFilter = (filterId: number): void => {
		if (window.confirm('Weet je zeker dat je deze filter wilt verwijderen? Restaurants wijzigen niet van status.')) {

		}
	}

	return (<>
		<Button
			color="green"
			href="/admin/fsq-filters/create-filter"
			style={{ marginBottom: 16 }}
			label="Filter toevoegen"
		/>
		<div className="fsq-search">
			<i className="fal fa-search" />
			<input
				onChange={(e: any) => {
					queryRef.current = e.target.value;
					search();
				}}
				style={{ flex: 1 }}
			/>
		</div>
		<Table>
			<thead>
				<Table.Row>
					<Table.HeaderCell align="left">Naam</Table.HeaderCell>
					<Table.HeaderCell align="left">Type</Table.HeaderCell>
					<Table.HeaderCell align="left" collapsing />
				</Table.Row>
			</thead>
			<tbody>
				{filters.map((f) => (
					<Table.Row key={f.id}>
						<Table.Cell primary name="Naam">
							<Link to={`/admin/fsq-filters/edit-filter/${f.id}`}>
								{f.name}
							</Link>
						</Table.Cell>
						<Table.Cell name="Type">
                            {f.type === 1 ? 'Altijd aan' : 'Altijd uit'}
                        </Table.Cell>
						<Table.Cell actions>
							<div style={{ display: 'flex' }}>
								<Button
									onClick={() => {}}
									tooltip="Wijzigen"
									href={`/admin/fsq-filters/edit-filter/${f.id}`}
								>
									<i className="fal fa-pencil" />
								</Button>
								<Button
									onClick={() => deleteFilter(f.id)}
									tooltip="Filter verwijderen"
								>
									<i className="fal fa-trash-alt" />
								</Button>
							</div>
						</Table.Cell>
					</Table.Row>
				))}
			</tbody>
		</Table>
	</>);
};


export default FourSquareFilterListView;

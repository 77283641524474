import React from "react";
import Layout from "../../components/Layout";
import Card from "../../components/Card";

const PrivacyView = () => {
	return (
		<Layout isSearch className="legal container-small">
			<Card type="no-shadow">
				<h1>Privacy verklaring</h1>
				<p>
					In deze privacy verklaring lichten wij u graag in over het
					feit dat wij uw gegevens gebruiken en waarvoor. Wij zullen
					er alles aan doen om voorzichtig met uw gegeven om te gaan
					en alleen te gebruiken indien noodzakelijk en om een juiste
					werking van onze online dienst Lees deze Privacy verklaring
					aandachtig door. Tevens wordt vermeldt hoelang uw gegevens
					worden bewaard en hoe u deze op kunt vragen.
				</p>
				<p>
					Easyzeats, gevestigd te Hardewijk, KvK nummer 68383614,
					biedt een online dienst aan die gebruikers in staat stelt om
					een aantal stoelen in een horeca onderneming te reserveren
					door een keuze te maken voor tijdvakken en aantal stoelen
					per horeca gelegenheid.
				</p>
				<p>
					Onze websites, mobiele websites en bijbehorende toepassingen
					(gezamenlijk, site of diensten) worden aangeboden door
					Easyzeats als eigenaar en gegevensbeheerder. Wanneer u via
					een mobiele telefoon, tablet, computer of soortgelijk
					apparaat onze websites bezoekt, gaat u akkoord met het feit
					dat wij uw gegevens gebruiken voor een juiste werking van
					ons dienst zoals eerder beschreven. Hieronder wordt hier
					dieper op in gegaan.
				</p>
				<p>
					<strong>Verzamelde gegevens</strong>
				</p>
				<p>
					Wanneer u onze diensten gebruikt of toegang daartoe heeft,
					verzamelen wij gegevens van en over u om u een
					persoonlijkere ervaring te bieden die beter op uw voorkeuren
					is afgestemd. Wij verzamelen deze gegevens uit verschillende
					bronnen De gegevens die worden verzameld, kunnen bestaan
					uit:
				</p>
				<ul>
					<li>
						Contactgegevens, waaronder naam, telefoonnummer,
						postadres en e-mailadres
					</li>
					<li>
						Facturatie- en of betaalgegevens (zoals uw
						creditcardnummer, naam van de kaarthouder, vervaldatum,
						veiligheidscode en factuuradres) via onze samenwerking
						met een vertrouwde betalingsprovider
					</li>
					<li>Gebruikersnaam en wachtwoord</li>
					<li>
						Foto's, beoordelingen, socialmediaberichten die u met
						ons heeft gedeeld
					</li>
					<li>Geolocatie gegevens</li>
					<li>
						Apparaat gegevens, zoals wanneer u toegang tot onze
						Diensten heeft gehad en informatie over het gebruikte
						Apparaat (bijvoorbeeld IP-adres, gebruikte software of
						internetbrowser, taalkeuzes, unieke
						identificatiekenmerken van uw Apparaat en advertenties)
					</li>
					<li>
						Online activiteit, zoals welke pagina's u heeft bezocht
						en welke inhoud en apps u heeft bekeken
					</li>
					<li>Restaurant reservering historie</li>
					<li>
						Informatie over uw restaurantreserveringen en voorkeuren
					</li>
				</ul>
				<p>
					Wij kunnen ook informatie over andere dineergasten
					verzamelen indien u deze heeft verstrekt, waaronder hun
					e-mailadres of andere reserveringsgegevens, zoals de voor-
					en achternaam. Als u informatie met ons deelt over andere
					personen, moet u daarvoor hun toestemming hebben.
				</p>
				<p>
					Naast de hierboven genoemde categorie&euml;n kunnen we ook
					gegevens over uw locatie verzamelen. Dit gebeurt alleen als
					u de privacy instellingen van uw Apparaat zo heeft ingesteld
					dat dergelijke gegevens worden verzonden of bijvoorbeeld als
					u foto's heeft ge&uuml;pload die met locatie-informatie
					getagd zijn. Wij verzamelen en delen locatiegegevens om
					relevante inhoud te tonen, om uw gebruik van onze Diensten
					te analyseren, om onze Diensten te verbeteren en om
					aanbevelingen te tonen. Wij kunnen bijvoorbeeld uw locatie
					gebruiken om u restaurants in de buurt te tonen.
				</p>
				<p>
					U kunt te allen tijde de privacy instellingen van uw
					Apparaat wijzigen om het verzamelen en delen van
					locatiegegevens en/of de mogelijkheid om locatiegegevens aan
					foto's toe te voegen uit te schakelen. Het uitschakelen van
					de optie 'locatie delen' kan echter van invloed zijn op
					andere functies die wij bieden. Als u vragen heeft over de
					privacy instellingen van uw Apparaat, dan raden we u aan om
					contact op te nemen met de fabrikant van uw Apparaat of met
					uw provider.
				</p>
				<p>
					<strong>Gebruik van gegevens</strong>
				</p>
				<p>
					Wij streven ernaar om u relevante inhoud op onze Diensten
					aan te bieden en de wetgeving inzake de bescherming van
					persoonsgegevens van de landen waarin wij actief zijn na te
					leven. Wij gebruiken uw gegevens zodat wij u kunnen helpen
					om optimaal gebruik te maken van onze Diensten. We gebruiken
					deze bijvoorbeeld om:
				</p>
				<ul>
					<li>
						Uw account aan te maken en te beheren, en ervoor te
						zorgen dat u toegang heeft tot en gebruik kunt maken van
						onze Diensten
					</li>
					<li>Uw restaurantreserveringen te vereenvoudigen</li>
					<li>
						Onze Diensten te verbeteren en de interesse daarin te
						meten
					</li>
					<li>Met u te communiceren</li>
					<li>
						Het &lsquo;Samen Sterk&rsquo; concept te kunnen
						gebruiken
					</li>
					<li>Uw interesses of voorkeuren vast te stellen</li>
					<li>U een optimale ervaring te bieden</li>
					<li>Op uw vragen en opmerkingen te reageren</li>
					<li>Geschillen of problemen op te lossen</li>
					<li>
						Mogelijke verboden of illegale activiteiten te
						voorkomen.
					</li>
					<li>
						Toe te zien op de naleving van onze gebruiksvoorwaarden
						en mededelingen zoals anders wordt vermeld op het moment
						dat de gegevens worden verzameld
					</li>
				</ul>
				<p>
					<strong>Delen van gegevens</strong>
				</p>
				<p>
					Uw persoonsgegevens worden nooit verkocht of doorgegeven aan
					derden met commerci&euml;le bedoelingen. Uitzonderingen
					hierop zijn hieronder te vinden:
				</p>
				<ul>
					<li>
						Restaurants: We delen uw gegevens met onze
						partnerrestaurants zodat ze uw reserveringen bij
						restaurants kunnen plaatsen en beheren en om eventuele
						klachten en geschillen ten aanzien van uw reservering te
						kunnen behandelen.
					</li>
					<li>
						Er is sprake van een wettelijke verplichting voor ons
						tot doorgifte van de persoonsgegevens zoals bv. in het
						kader van (het voorkomen van) fraude en misbruiken;
					</li>
					<li>
						Wij doen beroep op derden om een deel van de
						overeenkomst met u uit te kunnen voeren, in welk geval
						wij ervoor zorgen dat deze derden dit privacy beleid ook
						naleven., zie 5. Door wie/in welke systemen worden uw
						gegevens verwerkt?
					</li>
				</ul>
				<p>
					<strong>Bewaren van gegevens</strong>
				</p>
				<p>
					Wij zullen kopie&euml;n van uw gegevens bewaren zolang u uw
					account heeft en zolang dit in verband met de in deze
					Verklaring genoemde doeleinden nodig is, tenzij de gegevens
					uit hoofde van de wet langer moeten worden bewaard.
					Daarnaast kunnen we uw gegevens bewaren voor zolang dit
					nodig is ter vaststelling, uitoefening of verdediging van
					wettelijke rechten.
				</p>
				<p>
					<strong>Uw rechten met betrekking tot de AVG</strong>
				</p>
				<p>
					U heeft bepaalde rechten met betrekking tot uw
					persoonsgegevens.
				</p>
				<p>
					De rechten die u met betrekking tot uw eigen
					persoonsgegevens heeft, zijn als volgt:
				</p>
				<ul>
					<li>
						Het recht op toegang tot uw persoonsgegevens. Hierdoor
						heeft u het recht om een kopie te verkrijgen van de
						persoonsgegevens die wij van u bewaren.
					</li>
					<li>
						Het recht tot het corrigeren van uw persoonsgegevens
						indien deze onjuist zijn. U mag ook onvolledige
						persoonsgegevens aanvullen met inachtneming van de
						doeleinden van de verwerking.
					</li>
					<li>
						Het recht om verwijdering van uw persoonsgegevens aan te
						vragen indien:
					</li>
					<ul>
						<li>
							uw persoonsgegevens niet meer noodzakelijk zijn voor
							de doeleinden waarvoor we ze hebben verzameld of
							verwerkt; of
						</li>
						<li>
							u uw toestemming intrekt indien de verwerking van uw
							persoonsgegevens gebaseerd is op uw toestemming en
							er geen andere rechtsgronden voor zijn; of
						</li>
						<li>
							u bezwaar maakt tegen de verwerking van uw
							persoonsgegevens en we geen prevalerende rechtsgrond
							voor de verwerking hebben; of
						</li>
						<li>
							uw persoonsgegevens niet op rechtmatige wijze worden
							verwerkt; of
						</li>
						<li>
							uw persoonsgegevens moeten worden verwijderd om aan
							een wettelijke verplichting te voldoen.
						</li>
					</ul>
					<li>
						Het recht bezwaar te maken tegen het verwerken van uw
						persoonsgegevens. We zullen uw verzoek inwilligen,
						tenzij we een overtuigend hoger rechtmatig belang hebben
						bij de verwerking of we uw persoonsgegevens moeten
						blijven verwerken om een rechtsvordering te formuleren,
						uit te oefenen of ons hiertegen te verdedigen.
					</li>
					<li>
						Het recht om je persoonsgegevens af te schermen indien:
					</li>
					<ul>
						<li>
							de juistheid van uw persoonsgegevens door u wordt
							tegengesproken gedurende de periode waarin wij de
							juistheid van de persoonsgegevens moeten
							controleren; of
						</li>
						<li>
							het verwerken onrechtmatig is en u tegen de
							verwijdering van uw persoonsgegevens bent en
							verzoekt om afscherming; of
						</li>
						<li>
							wij uw persoonsgegevens niet langer nodig hebben
							voor verwerkingsdoeleinden, maar u uw
							persoonsgegevens nodig heeft voor wettelijke
							vorderingen; of
						</li>
						<li>
							u bezwaar heeft gemaakt tegen de verwerking
							gedurende de periode waarin we hogere rechtmatige
							belangen moeten controleren.
						</li>
					</ul>
					<li>
						Het recht op gegevensoverdraagbaarheid. U mag ook vragen
						of wij, indien mogelijk, deze persoonsgegevens kunnen
						versturen naar een derde partij. U heeft dit recht
						alleen indien het verzoek persoonsgegevens betreft die u
						aan ons heeft verstrekt, indien de verwerking ervan
						plaatsvindt op basis van uw toestemming of noodzakelijk
						is voor de uitvoering van een contract tussen u en ons
						en indien de verwerking op automatische basis
						plaatsvindt.
					</li>
				</ul>
				<p>
					U hoeft geen vergoeding te betalen om toegang te krijgen tot
					uw persoonsgegevens (of om andere rechten uit te oefenen die
					in deze Verklaring worden genoemd). Echter mogen we wel een
					redelijke vergoeding vragen indien uw verzoek ongegrond,
					herhaaldelijk of buitensporig plaatsvindt.
				</p>
				<p>
					Wij hebben specifieke gegevens van u nodig om uw identiteit
					vast te stellen en zeker te zijn dat u recht heeft op
					toegang tot uw persoonsgegevens (of voor het uitoefenen van
					uw andere rechten). Dit is een veiligheidsmaatregel om
					ervoor te zorgen dat persoonsgegevens niet worden
					bekendgemaakt aan personen die niet het recht hebben om deze
					te ontvangen. In een poging om onze antwoordtijd zo kort
					mogelijk te houden, kunnen wij ook contact met u opnemen
					voor meer informatie omtrent uw verzoek. U kunt zich op
					meerdere rechten beroepen via het gedeelte persoonsgegevens
					in uw account.&nbsp;
				</p>
				<p>
					Indien u contact met ons wenst op te nemen met betrekking
					tot bovenstaande adviseren wij u contact op te nemen met
					support@easyzeats.nl
				</p>
				<p>
					<strong>Gebruik van gegevens</strong>
				</p>
				<p>
					We gebruiken uw persoonsgegevens alleen wanneer dit
					wettelijk is toegestaan.
				</p>
				<p>
					Conform de AVG zullen wij uw persoonsgegevens in een of meer
					van de volgende gevallen gebruiken:
				</p>
				<ul>
					<li>
						Wanneer wij het contract uitvoeren dat wij met u zullen
						aangaan of reeds zijn aangegaan.
					</li>
					<li>
						Wanneer het nodig is voor onze rechtmatige belangen (of
						die van derden) en uw belangen en grondrechten niet
						zwaarder wegen dan deze belangen.
					</li>
					<li>
						Indien we aan een juridische of bestuursrechtelijke
						verplichting moeten voldoen.
					</li>
					<li>Met uw toestemming</li>
				</ul>
				<p>Easyzeats 2020 alle rechten voorbehouden.</p>
			</Card>
		</Layout>
	);
};

export default PrivacyView;

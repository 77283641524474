import React from "react";
import "./tab-nav.scss";
import { NavLink, useLocation } from "react-router-dom";
import StyledText from "../StyledText";
import { useSelector } from "react-redux";

type TabNavRoute = {
	to: string;
	label: string;
	className?: string,
	isActive?: (location: string) => boolean,
};

type TabNavProps = {
	routes: TabNavRoute[];
	extra?: any;
};

const TabNav = ({ routes, extra }: TabNavProps) => {
	const { action_needed } = useSelector((state: any) => state.auth);
	const location = useLocation();
	
	return (
		<div className="tab-nav">
			<div className="tab-nav-items">
				{routes.map((item: TabNavRoute) => (
					<NavLink
						key={item.to + item.label}
						activeClassName={item.isActive ? undefined : "tab-nav-item-active"}
						className={`tab-nav-item ${item.className} ${item.isActive && item.isActive(location.pathname) ? 'tab-nav-item-active' : ''}`}
						to={item.to}
					>
						<StyledText>{item.label}</StyledText>
						{item.label === 'Actie vereist' && action_needed > 0 && (
							<span className="badge" style={{ backgroundColor: '#d00', marginLeft: 10, fontSize: 11, padding: '1px 5px', borderRadius: 4 }}>{action_needed}</span>
						)}
					</NavLink>
				))}
			</div>
			{extra}
		</div>
	);
};

export default TabNav;

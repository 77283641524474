import React, { useState, useEffect, useRef, useCallback } from "react";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from "use-places-autocomplete";
import { setLocation as setReduxLocation } from "../../redux/location";
import "./search-input.scss";
import StyledText from "../StyledText";
import Button from "../Button";
import useBlur from "../../hooks/useBlur";
import { useDispatch, useSelector } from "react-redux";

// const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

type GeoProps = {
	lat: any;
	lng: any;
};

type SearchInputProps = {
	location: GeoProps;
	setLocation: (location: GeoProps) => void;
	onSubmit: (location: GeoProps) => void;
};

const getStreet = (location: GeoProps, setStreet: (street: string) => void) => {
	if (!google) {
		return "";
	}
	const geocoder = new google.maps.Geocoder();
	geocoder.geocode({ location: location }, function (results, status) {
		if (status === "OK" && results.length) {
			let result = results[0] as any;
			if (result && result.formatted_address) {
				setStreet(result.formatted_address);
			}
		}
	});
};

const SearchInputV2 = ({
	location,
	setLocation,
	onSubmit,
}: SearchInputProps) => {
	const _location = useSelector((state: any) => state.location);
	const [mylocation, setMylocation] = useState({
		lat: _location.lat,
		lng: _location.lng,
	} as any);
	const [visible, setVisible] = useState(false);
	const [myplace, setMyPlace] = useState("");
	const dispatch = useDispatch();
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {},
		debounce: 300,
	});
	const box = useRef(null);
	useBlur(box, () => setVisible(false));

	const getLocation = useCallback(async () => {
		await navigator.geolocation.getCurrentPosition((position) => {
			let geo = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			};
			setMylocation(geo);
			setLocation(geo);
			getStreet(geo, (s) => {
				setValue(s);
				setMyPlace(s);
				dispatch(setReduxLocation({ ...geo, street: s }));
			});
		});
	}, [dispatch, setLocation, setValue]);

	useEffect(() => {
		getLocation();
	}, [getLocation]);

	const handleSelect = ({ description }: any) => () => {
		setValue(description, false);
		clearSuggestions();
		getGeocode({ address: description })
			.then((results) => getLatLng(results[0]))
			.then(({ lat, lng }) => {
				setLocation({ lat, lng });
				onSubmit({ lat, lng });

				dispatch(setReduxLocation({ lat, lng, street: description }));
			});
	};

	const renderSuggestions = () => {
		return data.map((suggestion) => {
			const {
				id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			return (
				<div
					key={id}
					onClick={handleSelect(suggestion)}
					className="suggestion-item"
				>
					<div className="icon">
						<i className="fas fa-map-pin"></i>
					</div>
					<div className="info">
						<span>
							{main_text},{" "}
							<span className="light">{secondary_text}</span>
						</span>
					</div>
				</div>
			);
		});
	};

	const hasContent = myplace !== "" || status === "OK";

	return (
		<div ref={box} className="search-box-input-wrapper">
			<div className="google-input">
				<input
					className={!value ? "google-input-empty" : ""}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					disabled={!ready}
					placeholder="Waar gaan we eten?"
					onFocus={() => setVisible(true)}
				/>
				{value && (
					<div onClick={() => setValue("")} className="icon">
						<i className="fal fa-times"></i>
					</div>
				)}
			</div>
			<div
				className={`autocomplete ${
					visible && hasContent ? "autocomplete-visible" : ""
				}`}
			>
				<div className="autocomplete-wrapper">
					{myplace !== "" && (
						<div
							onClick={() => onSubmit(mylocation)}
							className="suggestion-item suggestion-item-me"
						>
							<div className="icon">
								<i className="fas fa-location-arrow"></i>
							</div>
							<div className="info">
								<StyledText type="bold">
									Mijn locatie
								</StyledText>
								<span>{myplace}</span>
							</div>
						</div>
					)}

					{status === "OK" && (
						<>
							<StyledText
								className="autocomplete-title"
								type="bold"
							>
								Suggesties
							</StyledText>
							<div className="google-suggestions">
								{renderSuggestions()}
							</div>
							<Button onClick={() => {}}>Zoeken</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchInputV2;

import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import Button from '../Button';
import Input from '../Input';
import Modal from '../Modal';
import StyledText from '../StyledText';
import Switch from '../Switch';
import '../TimeslotPickerV2/action-modal.scss';

export const deleteReservation = (reservations: any[]): void => {
    dispatchEvent(new CustomEvent('single-action-delete', { detail: { reservations, type: 'delete' }}));
}

const SingleActionModal: FC = (): JSX.Element => {
    const [ affected, setAffected ] = useState<any[]>([]);
    const [ step2, setStep2 ] = useState<string>('cancel');
    const [ subStep, setSubStep ] = useState<number>(1);
    const [ params, setParams ] = useState<any>({ alert: true });
    const [ visible, setVisible ] = useState<boolean>(false);
    const [ type, setType ] = useState<string>('delete');
    
    useEffect(() => {
        const handleReservations = ({ detail }: any): void => {
            setAffected(detail.reservations);
            setType(detail.type);
            setVisible(true);
        }

        window.addEventListener('single-action-delete', handleReservations, true);
        return () => window.removeEventListener('single-action-delete', handleReservations, true);
    }, []);

    useEffect(() => {
        setSubStep(1);
    }, [step2]);

    const handleOnClose = () => {
        setStep2('cancel');
        setSubStep(1);
        setParams({ alert: true });
        dispatchEvent(new CustomEvent('update-profile'));
        setVisible(false);
    }

    const handleConfirm = () => {
        if (type === 'delete') {
            axios.post('/reservation/delete-multi', {
                ...params,
                selection: affected.map(o => o.id)
            }).then(() => {
                dispatchEvent(new CustomEvent('reservation-refresh'));
                handleOnClose();
            });
        }
    }

    return (
        <Modal
            type={step2 === '' || step2 === 'view' ? 'large' : 'small'}
            title={type === 'delete' ? 'Reserveringen annuleren' : ''}
            visible={visible}
            onClose={handleOnClose}
        >
            {type === 'delete' && (
                <div className="action-modal-alert">
                    <i className="far fa-exclamation-triangle"></i>
                    LET OP! U gaat <b>{affected.length}</b> reservering{affected.length === 1 ? '' : 'en'} {step2 === 'cancel' ? 'annuleren' : 'verplaatsen'}.
                </div>
            )}

            {(step2 === 'cancel' || step2 === 'move') && (
                <div className="action-modal-step2">
                    <div className="acms-icon">
                        <i className={`fad ${step2 === 'move' ? 'fa-expand-arrows-alt' : 'fa-ban'}`}></i>
                    </div>
                    <div>
                        {step2 === 'move' ? (
                            <div className="acms-steps">
                                <div className={`${subStep >= 1 ? 'active' : ''} ${subStep > 1 ? 'done' : ''}`}>
                                    {subStep > 1 ? <i className="far fa-check"></i> : 1}
                                </div>
                                <div className={`${subStep >= 2 ? 'active' : ''} ${subStep > 2 ? 'done' : ''}`}>
                                    {subStep > 2 ? <i className="far fa-check"></i> : 2}
                                </div>
                                <div className={`${subStep >= 3 ? 'active' : ''}`}>
                                    3
                                </div>
                            </div>
                        ) : (
                            <div className="acms-steps">
                                <div className={`${subStep >= 1 ? 'active' : ''} ${subStep > 1 ? 'done' : ''}`}>
                                    {subStep > 1 ? <i className="far fa-check"></i> : 1}
                                </div>
                                <div className={`${subStep >= 2 ? 'active' : ''}`}>
                                    2
                                </div>
                            </div>
                        )}

                        {/* {step2 === 'move' && subStep === 1 && (
                            <div className="acms-dropdown">
                                <TimeslotDropdown
                                    filterId={timeslot.id}
                                    placeholder="Kies een tijdvak"
                                    value={params.timeslot}
                                    onChange={(value, item) => {
                                        checkForMore(value);

                                        setParams({
                                            ...params,
                                            timeslot: value,
                                            label: item.label,
                                        });
                                    }}
                                />
                            </div>
                        )} */}
                        {((step2 === 'move' && subStep === 2) || (step2 === 'cancel' && subStep === 1))  && (
                            <div className="acms-dropdown" style={{ maxWidth: 460 }}>
                                <Switch
                                    onChange={(value) =>
                                        setParams({
                                            ...params,
                                            alert: value,
                                        })
                                    }
                                    value={params.alert}
                                    label={`Gasten op de hoogte brengen van de reservering ${step2 === 'move' ? 'wijziging' : 'annulering'}?`}
                                    aan="Ja"
                                    uit="Nee"
                                />
                                {params.alert && <div style={{ marginTop: 20 }}>
                                    <Input
                                        multiline
                                        placeholder="Stuur een extra bericht mee in de e-mail."
                                        value={params.comment || ''}
                                        onChange={(comment) => setParams({
                                            ...params,
                                            comment,
                                        })}
                                    />
                                </div>}
                            </div>
                        )}
                        {((step2 === 'move' && subStep === 3) || (step2 === 'cancel' && subStep === 2)) && (
                            <div className="acms-dropdown" style={{ maxWidth: 460 }}>
                                <div className="acms-dropdown" style={{ maxWidth: '100%', textAlign: 'center' }}>
                                    <StyledText>Laatste controle</StyledText>
                                    <div style={{ marginTop: 15 }}>
                                        <StyledText>
                                            {step2 === 'move' && <span>
                                                <i className="far fa-clock"></i>
                                                {params.label}
                                            </span>}
                                            <span>
                                                <i className="fal fa-envelope-open-text"></i>
                                                {params.alert ? 'Ja' : 'Nee'}
                                            </span>
                                        </StyledText>
                                    </div>
                                </div>
                                {params.alert && <div style={{ marginTop: 20 }}>
                                    <Input
                                        readOnly
                                        multiline
                                        value={params.comment || ''}
                                        onChange={() => {}}
                                    />
                                </div>}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {step2 !== '' ? (
                <div className="action-modal-buttons">
                    <Button
                        onClick={() => {
                            if (subStep === 1) {
                                handleOnClose();
                            } else {
                                setSubStep(subStep - 1);
                            }
                        }}
                        type="grey-shadow"
                        light
                    >
                        <i className="fas fa-arrow-left"></i>
                        <span>{subStep === 1 ? 'Annuleren' : 'Terug'}</span>
                    </Button>
                    {step2 !== 'view' && (
                        ((step2 === 'move' && subStep === 3) || (step2 === 'cancel' && subStep === 2)) ? (
                            <Button onClick={() => handleConfirm()}>
                                <span>Reservering{affected.length === 1 ? '' : 'en'} {type === 'delete' ? 'annuleren' : 'verplaatsen'}</span>
                                <i className="fas fa-check"></i>
                            </Button>
                        ) : (
                            <Button onClick={() => setSubStep(subStep + 1)}>
                                <span>Volgende</span>
                                <i className="fas fa-arrow-right"></i>
                            </Button>
                        )
                    )}
                </div>
            ) : (
                <div className="action-modal-buttons">
                    <div />
                    <Button
                        className="button-transparent"
                        onClick={() => handleOnClose()}
                        light
                    >
                        Annuleren
                    </Button>
                </div>
            )}
        </Modal>
    );
}

export default SingleActionModal;

import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { openDoReservationModal } from '../../../components/DoReservationModal';
import Layout from '../../../components/Layout';

const RestaurantEmbedView: FC = (): JSX.Element => {
    const params = useParams() as any;
	
    useEffect(() => {
        setTimeout(() => {
            openDoReservationModal(params.id, undefined, true);
        }, 100);
    }, [params]);

	return (
		<Layout>

        </Layout>
    );
}

export default RestaurantEmbedView;

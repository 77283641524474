import React from "react";
import "./banner.scss";
import StyledText from "../StyledText";
import SlideShow from "../SlideShow/SlideShow";

type BannerProps = {
	title?: string;
	subtitle?: string;
	children?: any;
	src?: any;
	type?: string;
	center?: any;
	full_children?: boolean;
	className?: string;
	slides?: any[];
};

const Banner = ({
	title,
	subtitle,
	children,
	src,
	type,
	center,
	full_children,
	className,
	slides,
}: BannerProps) => {
	const classes = ["banner"];

	if (!children && !title && !subtitle) {
		classes.push("banner-empty");
	}
	if (className) classes.push(className);
	if (slides && slides.length) classes.push("banner-has-slides");

	if (type) classes.push(`banner-${type}`);

	let style = {};

	if (src) {
		style = {
			backgroundImage: `url(${src})`,
		};
	}

	const renderTop = () => {
		if (slides && slides.length) {
			return (
				<div className="banner-slides">
					<div className="banner-slides-placeholder"></div>
					<SlideShow autoplay media={slides} />
				</div>
			);
		}
		return (
			<div className="banner-top" style={style}>
				{center ? (
					<div className="container-small">{center}</div>
				) : (
					<div className="container-small">
						{title && <StyledText type="title">{title}</StyledText>}
						{subtitle && (
							<StyledText type="subtitle">{subtitle}</StyledText>
						)}
					</div>
				)}
			</div>
		);
	};

	return (
		<header className={classes.join(" ")}>
			{renderTop()}
			<div className="banner-content">
				<div className={`container${full_children ? "" : "-small"}`}>
					{children}
				</div>
			</div>
		</header>
	);
};

export default Banner;

import React from "react";
import Layout from "../../../components/Layout";
import { ContactForm } from "../../Info/ContactView/index";

const IdeaView = () => {
	return (
		<Layout title="Ideeënbus" type="admin">
			<ContactForm
				text="Een goed idee of wil je gewoon je mening geven? Laat het ons dan weten via het onderstaande formulier."
				subject="ideeën"
			/>
		</Layout>
	);
};

export default IdeaView;

import { parse } from 'qs';
import React, { useState, useEffect, useCallback } from "react";
import "./pause-timeslot-view.scss";
import Layout from "../../../../components/Layout";
import useGet from "../../../../hooks/rest/useGet";
import Button from "../../../../components/Button";
import TimeslotButton from "../../../../components/TimeslotPickerV2/TimeslotButton";
import Loading from "../../../../components/Loading";
import useDelete from "../../../../hooks/rest/useDelete";
import usePost from "../../../../hooks/rest/usePost";
import usePatch from "../../../../hooks/rest/usePatch";
import Input from "../../../../components/Input";
import useAlert from "../../../../hooks/useAlert";
import TimeslotDropdown from "../../../../components/Dropdown/TimeslotDropdown";
import { useSelector } from "react-redux";
import moment from "moment";
const PauseTimeslotView = () => {
	const [data, loading, , refetch] = useGet("/timeslot-pauses");
	const [visible, setVisible] = useState(false);
	const [selected, setSelected] = useState({ id: null });
	const items = data?.data || [];
	useEffect(() => {
		if (window.location.href.includes('create=1')) {
			setVisible(true);
		}
	}, []);

	return (
		<Layout type="admin" noLine title="Reserveringen">
			<div className="pause-timeslot-view-button">
				<Button
					onClick={() => {
						setVisible(true);
						setSelected({ id: null });
					}}
				>
					<span>Toevoegen</span>
					<i className="far fa-plus"></i>
				</Button>
			</div>
			<div
				className={`pause-timeslot-view ${
					selected.id || visible ? "pause-timeslot-view-is-open" : ""
				}`}
			>
				<div className={`pause-timeslot-view-list card`}>
					{items.length ? (
						items.map((item: any) => (
							<TimeslotButton
								text={item.date_text}
								active={selected.id === item.id}
								onClick={() => {
									setSelected({ ...item });
									setVisible(true);
								}}
								key={item.id + "slot"}
								{...{ ...item.timeslot }}
							/>
						))
					) : (
						<div className="tp2-loading">
							{loading ? (
								<Loading />
							) : (
								<>
									<div className="icon">
										<img
											src={require("../../../../assets/images/icons/clock.png")}
											alt=""
										/>
									</div>
								</>
							)}
						</div>
					)}
				</div>
				{(visible || selected.id) && (
					<div className="pause-timeslot-view-form card">
						<Form
							{...{ selected, refetch }}
							clear={() => {
								setSelected({ id: null });
								setVisible(false);
							}}
						/>
					</div>
				)}
			</div>
		</Layout>
	);
};

const Form = ({
	selected,
	refetch,
	clear,
}: {
	selected: any;
	refetch: () => any;
	clear: () => void;
}) => {
	const restaurant_id = useSelector((state: any) => state.auth.restaurant.id);
	const [value, setValue] = useState({ ...selected });
	const url = `/timeslot-pauses/${selected.id}`;
	const [deleteFunc, loadingDelete] = useDelete(url);
	const [saved, setSaved] = useState(false);
	const [sendAlert] = useAlert();
	const [patchFunc, loadingPatch] = usePatch(url);
	const [createTimeslot, loading] = usePost(`/timeslot-pauses`);

	useEffect(() => {
		setValue({
			...selected,
			date: selected.date
				? moment(selected.date).format("YYYY-MM-DD")
				: undefined,
		});
		setSaved(false);
	}, [selected]);

	const handleChange = useCallback((key: string, v: any) => {
		setValue({
			...value,
			[key]: v,
		});
	}, [value]);

	useEffect(() => {
		const q = parse(window.location.search.substring(1));
		if (q.date) {
			handleChange('date', q.date);
		}
	}, [handleChange]);

	const handleSubmit = (e?: any) => {
		if (e) e.preventDefault();
		setSaved(true);
		if (!value.date || !value.time_slot_id) {
			return;
		}

		if (selected.id) {
			patchFunc({ ...value, restaurant_id })
				.then(() => {
					refetch();
					clear();
					setSaved(false);
				})
				.catch(handleError);
		} else {
			createTimeslot({ ...value, restaurant_id })
				.then(() => {
					refetch();
					clear();

					setSaved(false);
				})
				.catch(handleError);
		}
	};

	const handleError = (e: any) => {
		const errors = e?.response?.data.errors;

		sendAlert({
			type: "error",
			text: errors?.already_filled
				? "Er is al een pause op dit tijdvak"
				: "Probeer het opnieuw",
			title: "Er is iets misgegaan",
		});
	};

	return (<>
		<div className="tp2-form-buttons">
			<Button
				type="white-shadow"
				onClick={clear}
			>
				<i className="fas fa-arrow-left"></i>
				<span>Annuleren</span>
			</Button>
			<div>
				{value.id && (
					<Button
						onClick={() => {
							deleteFunc().then(() => {
								refetch();
								setValue({ id: null });
								clear();
							});
						}}
						loading={loadingDelete}
						type="white-shadow"
						isDelete
					>
						<span>Verwijderen</span>
						<i className="fas fa-trash-alt"></i>
					</Button>
				)}
				<Button
					type="white-shadow"
					onClick={handleSubmit}
					loading={loading || loadingPatch}
				>
					<span>Opslaan</span>
					<i className="fas fa-save"></i>
				</Button>
			</div>
		</div>
		<form onSubmit={handleSubmit}>
			<div className="body">
				<div className="form-group">
					<h2>Datum</h2>
					<Input
						className="max"
						value={value.date}
						onChange={(date) => {
							handleChange("date", date);
						}}
						date
						isRequired={saved && !value.date}
					/>
				</div>
				<div className="form-row">
					<div className="form-group">
						<h2>Tijdvak</h2>
						<div className="max">
							<TimeslotDropdown
								value={value.time_slot_id}
								onChange={(value) =>
									handleChange("time_slot_id", value)
								}
								isRequired={saved && !value.time_slot_id}
							/>
						</div>
					</div>
					{selected?.timeslot && (
						<div
							style={{ margin: 0, width: "max-content" }}
							className="form-group"
						>
							<h2>Stoelen</h2>
							<div className="form-badge">
								{selected.timeslot.capacity}
							</div>
						</div>
					)}
				</div>
				<div className="form-group">
					<h2>Notitie</h2>
					<Input
						multiline
						placeholder="Notities zijn optioneel"
						value={value.comment}
						onChange={(comment) => handleChange("comment", comment)}
					/>
				</div>
			</div>
		</form>
	</>);
};

export default PauseTimeslotView;

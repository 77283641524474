import React from "react";
import "./badge.scss";
const Badge = ({ children, type }: { children: any; type?: string }) => {
	const className = ["badge"];
	if (type) {
		className.push(`badge-${type}`);
	}

	return <div className={className.join(" ")}>{children}</div>;
};

export const Badges = ({ children, className }: any) => (
	<div className={`badges ${className || ""}`}>{children}</div>
);

export default Badge;

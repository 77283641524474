import React from "react";
import Layout from "../../../components/Layout";
import { ContactForm } from "../../Info/ContactView/index";

const HelpView = () => {
	return (
		<Layout title="Hulp nodig" type="admin">
			<ContactForm
				subject="Hulp"
				text="Werkt er iets niet zoals je het verwacht had? Via het onderstaand formulier helpen wij jouw graag verder."
			/>
		</Layout>
	);
};

export default HelpView;

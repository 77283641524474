import React from "react";
import "./card.scss";
import StyledText from "../StyledText";
import SlideShow from "../SlideShow/SlideShow";

type CardProps = {
	children?: any;
	className?: string;
	loading?: boolean;
	type?: string;
	src?: any;
	onClick?: () => void;
	ref?: any;
	placeholder?: string;
	media?: any[];
};

const Card = ({
	children,
	className,
	loading,
	type,
	src,
	onClick,
	ref,
	placeholder,
	media,
}: CardProps) => {
	const classes = ["card"];

	if (type) classes.push(`card-${type}`);

	if (className) classes.push(className);

	if (src) classes.push(`card-has-thumbnail`);

	if (media && media.length) classes.push("card-has-slideshow");

	const renderHeader = () => {
		if (media && media.length) {
			return <SlideShow media={media} />;
		} else if (src) {
			return (
				<div
					className="card-thumbnail"
					style={{ backgroundImage: `url('${src}')` }}
				/>
			);
		} else if (src === null && placeholder) {
			return (
				<div className="card-thumbnail">
					<StyledText className="card-thumbnail-title" type="bold">
						{placeholder}
					</StyledText>
				</div>
			);
		}
	};

	return (
		<div ref={ref} onClick={onClick} className={classes.join(" ")}>
			{renderHeader()}
			{loading ? (
				<div className="card-loading">
					<i className="fal fa-spin fa-spinner-third"></i>
				</div>
			) : (
				<div className="card-info">{children}</div>
			)}
		</div>
	);
};

export default Card;

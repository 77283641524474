import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import moment from 'moment';
import store from "./redux";
import Routes from "./Routes";
import useVh from "./hooks/useVh";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-calendar/dist/Calendar.css';
import "./lib/bootstrap";
import "./styles/typography.scss";
import "./styles/template.scss";
import "./styles/calendar.scss";
require('moment/locale/nl.js');
moment.locale('nl');

const App = () => {
	useVh();
	return (
		<Routes />
	);
};

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);

import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SwitchV2 from '../../../../components/Switch/SwitchV2';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';

const AllowComment: FC = (): JSX.Element => {
    const [ state, setState ] = useState<boolean>(true);
    const [ patchProfile ] = usePatch('/auth/profile');
    const auth = useAuth();
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (!auth || !auth.restaurant) return;
        setState(auth.restaurant.settings
            ? auth.restaurant.settings.allow_comment
            : true
        );
    }, [auth]);

    const handleSwitch = (newState: boolean): void => {
        if (!auth || !auth.restaurant) return;
        const settings = {
			...(auth.restaurant.settings || {}),
			allow_comment: newState,
		};

        setState(newState);
        toast({
			header: 'Gelukt!',
			content: `De mogelijkheid om een opmerking te plaatsen is ${newState ? 'aan' : 'uit'} gezet.`,
		});

		patchProfile({ restaurant: { ...auth.restaurant, settings } }).then(({ data }) => {
            dispatch(updateProfile(data))
        }).catch(() => {});
    }

    return windowWidth <= 992 ? (<>
        <div className="sv-switch">
            <span className="switch-label" onClick={() => handleSwitch(!state)}>
                Opmerking toevoegen
            </span>
            <SwitchV2
                onChange={handleSwitch}
                state={state}
            />
        </div>
        <p className="sv-description">
            Gasten kunnen een opmerking plaatsen bij het reserveren.
        </p>
    </>) : (<>
        <div className="sf-row">
            <div className="sf-row-label">
                Opmerking toevoegen
            </div>
            <div className="sf-row-description">
                Gasten kunnen een opmerking plaatsen bij het reserveren.
            </div>
            <div className="sf-row-action">
                <SwitchV2
                    onChange={handleSwitch}
                    state={state}
                />
            </div>
        </div>
    </>);
}

export default AllowComment;

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "./for-partners-view-v2.scss";
import Layout from "../../../components/Layout";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import StyledText from "../../../components/StyledText";
import useAlert from "../../../hooks/useAlert";
import usePost from "../../../hooks/rest/usePost";

const ForPartnersView = () => {
    const [ showModal, setShowModal ] = useState<boolean | string>(false);
    const [ showDemo, setShowDemo ] = useState<boolean>(false);
    const [ formStep, setFormStep ] = useState<number>(1);
    const [ isIphone, setIsIphone] = useState<boolean>(false);
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ slideIndex, setSlideIndex ] = useState<number>(0);
    const [sendSignup] = usePost("/why/sign-up");
    const [postSendDemo] = usePost("/why/demo");
    const [ req, setReq ] = useState<any>({
        horeca: '', 
        contact: '', 
        info: '', 
        name: '', 
        email: '', 
        phone: '', 
    });
    const [ demo, setDemo ] = useState<any>({
        name: '', 
        email: '', 
        phone: '', 
    });
    const slider = useRef(null) as any;
    const [ sendAlert ] = useAlert();
    const history = useHistory();

    useEffect(() => {
        setIsIphone(window.navigator.userAgent.includes('iPhone'));

        const handleResize = (): void => {
            // if (window.navigator.userAgent.includes('iPhone')) return;
            const cont = document.getElementById('v2-slider-cont');
            const slider = document.getElementById('v2-slider');

            if (cont && slider) {
                if (window.navigator.userAgent.includes('iPhone')) {
                    slider.style.width = `${(window as any).constWinWidth - 32}px`;
                } else {
                    slider.style.width = `${cont.clientWidth}px`;
                }
            }
        }

        handleResize();

        window.addEventListener('resize', () => handleResize(), true);
        return () => window.removeEventListener('resize', () => handleResize(), true);
    }, []);

    const tryForFree = (): void => {
        if (
            emailAddress === '' ||
			!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,6})+$/.test(emailAddress)
		) {
			sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Dit is geen geldig e-mailadres",
			});
            return;
        }

        history.push(`/signup?plan=monthly&e=${emailAddress}`)
    }

    const handleReq = (key: string, value: any): void => {
        setReq({
            ...req,
            [key]: value,
        });
    }

    const updateStep = (step: number): void => {
        if (step === 2 && (
            req.horeca === '' ||
            req.contact === '' ||
            req.info === ''
        )) {
            sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Alle velden zijn verplicht",
			});
            return;
        }

        if (step === 3 && (
            req.name === '' ||
            req.email === '' ||
            req.phone === ''
        )) {
            sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Alle velden zijn verplicht",
			});
            return;
        }

        setFormStep(step);

        if (step === 3) {
            sendSignup(req);
        }
    }

    const handleDemo = (key: string, value: any): void => {
        setDemo({
            ...demo,
            [key]: value,
        });
    }

    const sendDemo = (): void => {
        if (demo.name === '' ||
            demo.email === '' ||
            demo.phone === ''
        ) {
            sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Alle velden zijn verplicht",
			});
            return;
        }

        postSendDemo(demo).then(() => {
            setShowDemo(false);
            sendAlert({
                type: "success",
                title: "Aanvraag verstuurd!",
            });
        });
    }

	return (
		<Layout
            button={<Button
                className="v2-hide-mob"
                to="/subscriptions"
                type="orange"
                light
                style={{
                    background: '#E3773B',
                    height: 'auto',
                    borderRadius: 6,
                    marginRight: 48,
                }}
            >
                Restaurant aanmelden
            </Button>}
        >
            <div className="for-partners">
                <div className="container">
                    <div className="row center">
                        <div className="col">
                            <h1>
                                Onvoorspelbare tijden vragen om nieuwe &amp; flexibele oplossingen
                            </h1>
                            <p>
                                Als horeca ondernemer zit je in een onvoorspelbare tijd. EasyZeats zet alles op alles om je daar zo goed mogelijk bij te ondersteunen. Met een nieuw en flexibel platform zorgen wij voor een optimale bezoekersstroom en gastvrije ervaring.
                            </p>
                            <Button
                                to="/subscriptions"
                                type="black"
                                light
                            >
                                Gratis proberen
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Lockdown, heropening, normale en beperkte openingstijden
                                </h2>
                                <p>
                                    Bespaar tijd en moeite door eenvoudig reserveringen te beheren bij lockdown, heropeningen, normale of beperkte openingstijden.
                                </p>
                                <p>
                                    Met één druk op de knop stuur jij automatisch een e-mail. naar je gasten over eventuele wijzigingen.
                                </p>
                            </div>
                        </div>
                        <div className="col col-image">
                            {!isIphone && <div className="bg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="732.496" height="559.6" viewBox="0 0 732.496 559.6">
                                <g id="Group_63" data-name="Group 63" transform="translate(1701.496 5381.6) rotate(-180)" opacity="0.3">
                                    <g id="Component_5_1" data-name="Component 5 – 1" transform="translate(969 4822)">
                                    <path id="Path_17" data-name="Path 17" d="M2078.122,901.058c-58.959-30.341-110.5-77.661-131.976-141.309-24.868-73.717-50.447-116.456-163.446-107.076-115.123,9.559-251.766,106.347-301.511,185.743-49.339,78.747-72.868,202.656.113,294.355s169.425,89.511,230.336,47.264,175.629-77.938,300.5-84.494,155.308-65.548,155.324-96.876C2167.483,954.173,2126.081,925.738,2078.122,901.058Z" transform="translate(-1434.962 -649.94)" fill="#979f73"/>
                                    <path id="Path_18" data-name="Path 18" d="M2057.9,903.994c-89.931,8.149-147.863,39.843-168.11,105.675-22.655,73.665-22.753,132.267-91.1,175.414-85.847,54.193-219.766,17.089-289.28-106.285-34.32-60.913-61.917-155.233-14.687-247.32,34.686-67.622,120.669-105.78,198.348-93.749,57.261,8.868,129.037,14.952,187.5-37.314s138.519-71.271,206.886-22.365C2163.116,732.171,2163.685,894.408,2057.9,903.994Z" transform="translate(-1439.109 -649.743)" fill="#979f73" opacity="0.26"/>
                                    <path id="Path_19" data-name="Path 19" d="M1978.475,748.441c-123.1,93.2-266.6,29.3-369.058,3.626S1397.11,801.286,1470.37,953.825s267.946,277.02,512.928,157.608c99.177-48.341,162.459-147.465,173.387-225.285C2171.478,780.78,2101.572,655.238,1978.475,748.441Z" transform="translate(-1436.352 -657.664)" fill="#979f73" opacity="0.44"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div className="image" style={{ padding: '0 0 40px 0' }}>
                                <img src="/images/for-partners/pic-1.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-image">
                            {!isIphone && <div className="bg bg-left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="749.782" height="634.978" viewBox="0 0 749.782 634.978">
                                <g id="Group_53" data-name="Group 53" transform="translate(-149 -818)" opacity="0.3">
                                    <g id="Component_6_2" data-name="Component 6 – 2" transform="translate(149 818)">
                                    <path id="Path_20" data-name="Path 20" d="M963.258,1899.056c-55.516-81.521-152.77-94.579-211.686-86.1s-124.263,4.6-174.665-61.317-141.291-72.1-191.127-19.024c-53.9,57.407-127.268,196.9-29.405,359.12,62.352,103.355,157.611,171.155,240.256,206.393s151.64,48.285,192.8,13.127,17.208-75.295,8.786-93.317-21.363-71.194,73.237-90.23C984.114,2105.032,1025.357,1990.241,963.258,1899.056Z" transform="translate(-242.395 -1697)" fill="#a8a8a8" opacity="0.31"/>
                                    <path id="Path_21" data-name="Path 21" d="M877.257,1896.434c-75.355-55.517-164.228-76.2-234.464-77.35s-124.1,9.535-143.724,46.478,9.449,58.577,20.812,68.968,36.242,44.491-26.854,85.432c-75.142,48.754-71.626,142.406,0,189.333,64.033,41.953,138.4,23.089,178.687,0s88.828-39.253,144.6-6.928,123.528,10.415,144.228-41.8C982.933,2104.1,995.527,1983.566,877.257,1896.434Z" transform="translate(-268.226 -1721.311)" fill="#a8a8a8" opacity="0.53"/>
                                    <path id="Path_22" data-name="Path 22" d="M660.331,1965.385c-86.6-24.9-141.623,46.335-308.931,34.458-192.046-13.635-145.607,181.087,18.357,266.38,176.361,91.743,349.716,95.006,378.617-107.028C748.211,2159.137,775.376,1998.467,660.331,1965.385Z" transform="translate(-225.98 -1749.421)" fill="#a8a8a8" opacity="0.29"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div className="image" style={{ top: -80 }}>
                                <img src="/images/for-partners/pic-2.png" alt="" />
                            </div>
                            <div className="buttons mob">
                                <Button
                                    onClick={() => setShowModal('bk8d3c_DqkM')}
                                    type="secondary"
                                >
                                    Hoe werkt dit?
                                </Button>
                                <Button
                                    to="/subscriptions"
                                    type="orange"
                                >
                                    Gratis proberen
                                </Button>
                            </div>
                        </div>
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Jouw gasten zien online hoeveel stoelen er nog beschikbaar zijn
                                </h2>
                                <p style={{ fontStyle: 'italic' }}>
                                    "Sorry, maar er is helaas geen plek meer."
                                </p>
                                <p>
                                    Geen teleurgestelde gezichten meer aan de deur! Jouw gasten kunnen zelf checken of je nog een plekje vrij hebt. Deze informatie voor jouw gasten is altijd up-to-date!
                                </p>
                                <div className="buttons">
                                    <Button
                                        onClick={() => setShowModal('bk8d3c_DqkM')}
                                        type="secondary"
                                    >
                                        Hoe werkt dit?
                                    </Button>
                                    <Button
                                        to="/subscriptions"
                                        type="orange"
                                    >
                                        Gratis proberen
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Tijdvakken in een handomdraai ingesteld
                                </h2>
                                <p>
                                    Reserveren in tijdvakken om het aantal gasten goed controleerbaar te houden, is in de huidige situatie een must! Met EasyZeats maak je gemakkelijk zelf tijdvakken aan en kun je deze ten alle tijden aanpassen.
                                </p>
                                <div className="buttons">
                                    <Button
                                        onClick={() => setShowModal('zKla2si38So')}
                                        type="secondary"
                                    >
                                        Hoe werkt dit?
                                    </Button>
                                    <Button
                                        to="/subscriptions"
                                        type="orange"
                                    >
                                        Gratis proberen
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col col-image">
                            {!isIphone && <div className="bg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="732.496" height="559.6" viewBox="0 0 732.496 559.6">
                                <g id="Group_63" data-name="Group 63" transform="translate(1701.496 5381.6) rotate(-180)" opacity="0.3">
                                    <g id="Component_5_1" data-name="Component 5 – 1" transform="translate(969 4822)">
                                    <path id="Path_17" data-name="Path 17" d="M2078.122,901.058c-58.959-30.341-110.5-77.661-131.976-141.309-24.868-73.717-50.447-116.456-163.446-107.076-115.123,9.559-251.766,106.347-301.511,185.743-49.339,78.747-72.868,202.656.113,294.355s169.425,89.511,230.336,47.264,175.629-77.938,300.5-84.494,155.308-65.548,155.324-96.876C2167.483,954.173,2126.081,925.738,2078.122,901.058Z" transform="translate(-1434.962 -649.94)" fill="#979f73"/>
                                    <path id="Path_18" data-name="Path 18" d="M2057.9,903.994c-89.931,8.149-147.863,39.843-168.11,105.675-22.655,73.665-22.753,132.267-91.1,175.414-85.847,54.193-219.766,17.089-289.28-106.285-34.32-60.913-61.917-155.233-14.687-247.32,34.686-67.622,120.669-105.78,198.348-93.749,57.261,8.868,129.037,14.952,187.5-37.314s138.519-71.271,206.886-22.365C2163.116,732.171,2163.685,894.408,2057.9,903.994Z" transform="translate(-1439.109 -649.743)" fill="#979f73" opacity="0.26"/>
                                    <path id="Path_19" data-name="Path 19" d="M1978.475,748.441c-123.1,93.2-266.6,29.3-369.058,3.626S1397.11,801.286,1470.37,953.825s267.946,277.02,512.928,157.608c99.177-48.341,162.459-147.465,173.387-225.285C2171.478,780.78,2101.572,655.238,1978.475,748.441Z" transform="translate(-1436.352 -657.664)" fill="#979f73" opacity="0.44"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div className="image" style={{ padding: '0 0 40px 0' }}>
                                <img src="/images/for-partners/pic-3.png" alt="" />
                            </div>
                            <div className="buttons mob">
                                <Button
                                    onClick={() => setShowModal('zKla2si38So')}
                                    type="secondary"
                                >
                                    Hoe werkt dit?
                                </Button>
                                <Button
                                    to="/subscriptions"
                                    type="orange"
                                >
                                    Gratis proberen
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-image">
                            {!isIphone && <div className="bg bg-left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="749.782" height="634.978" viewBox="0 0 749.782 634.978">
                                <g id="Group_53" data-name="Group 53" transform="translate(-149 -818)" opacity="0.3">
                                    <g id="Component_6_2" data-name="Component 6 – 2" transform="translate(149 818)">
                                    <path id="Path_20" data-name="Path 20" d="M963.258,1899.056c-55.516-81.521-152.77-94.579-211.686-86.1s-124.263,4.6-174.665-61.317-141.291-72.1-191.127-19.024c-53.9,57.407-127.268,196.9-29.405,359.12,62.352,103.355,157.611,171.155,240.256,206.393s151.64,48.285,192.8,13.127,17.208-75.295,8.786-93.317-21.363-71.194,73.237-90.23C984.114,2105.032,1025.357,1990.241,963.258,1899.056Z" transform="translate(-242.395 -1697)" fill="#a8a8a8" opacity="0.31"/>
                                    <path id="Path_21" data-name="Path 21" d="M877.257,1896.434c-75.355-55.517-164.228-76.2-234.464-77.35s-124.1,9.535-143.724,46.478,9.449,58.577,20.812,68.968,36.242,44.491-26.854,85.432c-75.142,48.754-71.626,142.406,0,189.333,64.033,41.953,138.4,23.089,178.687,0s88.828-39.253,144.6-6.928,123.528,10.415,144.228-41.8C982.933,2104.1,995.527,1983.566,877.257,1896.434Z" transform="translate(-268.226 -1721.311)" fill="#a8a8a8" opacity="0.53"/>
                                    <path id="Path_22" data-name="Path 22" d="M660.331,1965.385c-86.6-24.9-141.623,46.335-308.931,34.458-192.046-13.635-145.607,181.087,18.357,266.38,176.361,91.743,349.716,95.006,378.617-107.028C748.211,2159.137,775.376,1998.467,660.331,1965.385Z" transform="translate(-225.98 -1749.421)" fill="#a8a8a8" opacity="0.29"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div className="image" style={{ top: -80 }}>
                                <img src="/images/for-partners/pic-4.png" alt="" />
                            </div>
                            <div className="buttons mob">
                                <Button
                                    onClick={() => setShowModal('mMz7rP_DvjM')}
                                    type="secondary"
                                >
                                    Hoe werkt dit?
                                </Button>
                                <Button
                                    to="/subscriptions"
                                    type="orange"
                                >
                                    Gratis proberen
                                </Button>
                            </div>
                        </div>
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Laat gasten online een stoel reserveren en wijzigen
                                </h2>
                                <p>
                                    Geef je gasten de vrijheid om 24/7 zelf een reservering te maken en/of te wijzigen. Er gaat niets boven een stukje flexibiliteit voor je gasten.
                                </p>
                                <div className="buttons">
                                    <Button
                                        onClick={() => setShowModal('mMz7rP_DvjM')}
                                        type="secondary"
                                    >
                                        Hoe werkt dit?
                                    </Button>
                                    <Button
                                        to="/subscriptions"
                                        type="orange"
                                    >
                                        Gratis proberen
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-alt pic-5">
                    <div className="container">
                        <div className="row row-bg">
                            <div className="col col-text" style={{ flexBasis: '70%' }}>
                                <h2>Gewoon even zien hoe het werkt</h2>
                                <p>
                                    Ben jij benieuwd hoe het werkt en of het echt zo gemakkelijk is? Vraag nu een demo aan en wij nemen contact op voor een digitale of persoonlijke afspraak.
                                </p>
                                <div className="buttons">
                                    <Button
                                        type="orange"
                                        onClick={() => setShowDemo(true)}
                                    >
                                        Ik wil een demo
                                    </Button>
                                </div>
                            </div>
                            <div className="col col-image">
                                <div className="image">
                                    <img src="/images/for-partners/pic-5.png" alt="" />
                                </div>
                                <div className="buttons mob">
                                    <Button
                                        type="orange"
                                        onClick={() => setShowDemo(true)}
                                    >
                                        Ik wil een demo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ paddingTop: 150 }}>
                    <div className="row">
                        <div className="col col-image">
                            {!isIphone && <div className="bg bg-left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="749.782" height="634.978" viewBox="0 0 749.782 634.978">
                                <g id="Group_53" data-name="Group 53" transform="translate(-149 -818)" opacity="0.3">
                                    <g id="Component_6_2" data-name="Component 6 – 2" transform="translate(149 818)">
                                    <path id="Path_20" data-name="Path 20" d="M963.258,1899.056c-55.516-81.521-152.77-94.579-211.686-86.1s-124.263,4.6-174.665-61.317-141.291-72.1-191.127-19.024c-53.9,57.407-127.268,196.9-29.405,359.12,62.352,103.355,157.611,171.155,240.256,206.393s151.64,48.285,192.8,13.127,17.208-75.295,8.786-93.317-21.363-71.194,73.237-90.23C984.114,2105.032,1025.357,1990.241,963.258,1899.056Z" transform="translate(-242.395 -1697)" fill="#a8a8a8" opacity="0.31"/>
                                    <path id="Path_21" data-name="Path 21" d="M877.257,1896.434c-75.355-55.517-164.228-76.2-234.464-77.35s-124.1,9.535-143.724,46.478,9.449,58.577,20.812,68.968,36.242,44.491-26.854,85.432c-75.142,48.754-71.626,142.406,0,189.333,64.033,41.953,138.4,23.089,178.687,0s88.828-39.253,144.6-6.928,123.528,10.415,144.228-41.8C982.933,2104.1,995.527,1983.566,877.257,1896.434Z" transform="translate(-268.226 -1721.311)" fill="#a8a8a8" opacity="0.53"/>
                                    <path id="Path_22" data-name="Path 22" d="M660.331,1965.385c-86.6-24.9-141.623,46.335-308.931,34.458-192.046-13.635-145.607,181.087,18.357,266.38,176.361,91.743,349.716,95.006,378.617-107.028C748.211,2159.137,775.376,1998.467,660.331,1965.385Z" transform="translate(-225.98 -1749.421)" fill="#a8a8a8" opacity="0.29"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div className="image" style={{ top: -80 }}>
                                <img src="/images/for-partners/pic-6.png" alt="" />
                            </div>
                        </div>
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Creëer online gastvrijheid voor je gasten
                                </h2>
                                <p>
                                    Trek de aandacht en geef je gasten alvast een voorproefje door:
                                </p>
                                <div className="icon-list">
                                    <ul>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Restaurant informatie
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Openingstijden
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Locatie &amp; Routewijzer
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Impressie foto’s
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Menukaarten
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Dieet mogelijkheden
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Faciliteiten
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Contact binnen handbereik
                                        </li>
                                    </ul>
                                </div>
                                <p>
                                    Alles wat je gasten moeten weten verzameld op je eigen pagina.
                                </p>
                                <div className="buttons" style={{ justifyContent: 'center' }}>
                                    <Button
                                        type="orange"
                                        to="/subscriptions"
                                    >
                                        Eigen pagina maken
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Gebruik EasyZeats overal en op elk moment op je mobiel, tablet of computer. 
                                </h2>
                                <p>
                                    Handig! Manager, chef of bedieningsmedewerker, ieder teamlid heeft snel en eenvoudig toegang tot EasyZeats. 
                                </p>
                                <div className="buttons buttons-group slider-buttons">
                                    <Button
                                        className={slideIndex === 0 ? 'slide-active' : ''}
                                        onClick={() => {
                                            if (slider) {
                                                slider.current.slickGoTo(0);
                                                setSlideIndex(0);
                                            }
                                        }}
                                    >
                                        Mobiel
                                    </Button>
                                    <Button
                                        className={slideIndex === 1 ? 'slide-active' : ''}
                                        onClick={() => {
                                            if (slider) {
                                                slider.current.slickGoTo(1);
                                                setSlideIndex(1);
                                            }
                                        }}
                                    >
                                        Tablet
                                    </Button>
                                    <Button
                                        className={slideIndex === 2 ? 'slide-active' : ''}
                                        onClick={() => {
                                            if (slider) {
                                                slider.current.slickGoTo(2);
                                                setSlideIndex(2);
                                            }
                                        }}
                                    >
                                        Computer
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="col col-image">
                            {!isIphone && <div className="bg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="732.496" height="559.6" viewBox="0 0 732.496 559.6">
                                <g id="Group_63" data-name="Group 63" transform="translate(1701.496 5381.6) rotate(-180)" opacity="0.3">
                                    <g id="Component_5_1" data-name="Component 5 – 1" transform="translate(969 4822)">
                                    <path id="Path_17" data-name="Path 17" d="M2078.122,901.058c-58.959-30.341-110.5-77.661-131.976-141.309-24.868-73.717-50.447-116.456-163.446-107.076-115.123,9.559-251.766,106.347-301.511,185.743-49.339,78.747-72.868,202.656.113,294.355s169.425,89.511,230.336,47.264,175.629-77.938,300.5-84.494,155.308-65.548,155.324-96.876C2167.483,954.173,2126.081,925.738,2078.122,901.058Z" transform="translate(-1434.962 -649.94)" fill="#979f73"/>
                                    <path id="Path_18" data-name="Path 18" d="M2057.9,903.994c-89.931,8.149-147.863,39.843-168.11,105.675-22.655,73.665-22.753,132.267-91.1,175.414-85.847,54.193-219.766,17.089-289.28-106.285-34.32-60.913-61.917-155.233-14.687-247.32,34.686-67.622,120.669-105.78,198.348-93.749,57.261,8.868,129.037,14.952,187.5-37.314s138.519-71.271,206.886-22.365C2163.116,732.171,2163.685,894.408,2057.9,903.994Z" transform="translate(-1439.109 -649.743)" fill="#979f73" opacity="0.26"/>
                                    <path id="Path_19" data-name="Path 19" d="M1978.475,748.441c-123.1,93.2-266.6,29.3-369.058,3.626S1397.11,801.286,1470.37,953.825s267.946,277.02,512.928,157.608c99.177-48.341,162.459-147.465,173.387-225.285C2171.478,780.78,2101.572,655.238,1978.475,748.441Z" transform="translate(-1436.352 -657.664)" fill="#979f73" opacity="0.44"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div
                                className="image ir" style={{ padding: '0 0 40px 0' }}
                                id="v2-slider-cont"
                            >
                                <div
                                    className="slider"
                                    id="v2-slider"
                                >
                                    <Slider
                                        adaptiveHeight
                                        ref={slider}
                                        slidesToShow={1}
                                        arrows={false}
                                        dots={true}
                                        infinite={true}
                                        autoplay={false}
                                    >
                                        <div className="slide">
                                            <div className="image image-shadow">
                                                <img src="/images/for-partners/mobiel.jpeg" alt="" />
                                            </div>
                                        </div>
                                        <div className="slide">
                                            <div className="image image-shadow">
                                                <img src="/images/for-partners/tablet.jpeg" alt="" />
                                            </div>
                                        </div>
                                        <div className="slide">
                                            <div className="image image-shadow">
                                                <img src="/images/for-partners/computer.png" alt="" />
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-image">
                            {!isIphone && <div className="bg bg-left">
                                <svg xmlns="http://www.w3.org/2000/svg" width="749.782" height="634.978" viewBox="0 0 749.782 634.978">
                                <g id="Group_53" data-name="Group 53" transform="translate(-149 -818)" opacity="0.3">
                                    <g id="Component_6_2" data-name="Component 6 – 2" transform="translate(149 818)">
                                    <path id="Path_20" data-name="Path 20" d="M963.258,1899.056c-55.516-81.521-152.77-94.579-211.686-86.1s-124.263,4.6-174.665-61.317-141.291-72.1-191.127-19.024c-53.9,57.407-127.268,196.9-29.405,359.12,62.352,103.355,157.611,171.155,240.256,206.393s151.64,48.285,192.8,13.127,17.208-75.295,8.786-93.317-21.363-71.194,73.237-90.23C984.114,2105.032,1025.357,1990.241,963.258,1899.056Z" transform="translate(-242.395 -1697)" fill="#a8a8a8" opacity="0.31"/>
                                    <path id="Path_21" data-name="Path 21" d="M877.257,1896.434c-75.355-55.517-164.228-76.2-234.464-77.35s-124.1,9.535-143.724,46.478,9.449,58.577,20.812,68.968,36.242,44.491-26.854,85.432c-75.142,48.754-71.626,142.406,0,189.333,64.033,41.953,138.4,23.089,178.687,0s88.828-39.253,144.6-6.928,123.528,10.415,144.228-41.8C982.933,2104.1,995.527,1983.566,877.257,1896.434Z" transform="translate(-268.226 -1721.311)" fill="#a8a8a8" opacity="0.53"/>
                                    <path id="Path_22" data-name="Path 22" d="M660.331,1965.385c-86.6-24.9-141.623,46.335-308.931,34.458-192.046-13.635-145.607,181.087,18.357,266.38,176.361,91.743,349.716,95.006,378.617-107.028C748.211,2159.137,775.376,1998.467,660.331,1965.385Z" transform="translate(-225.98 -1749.421)" fill="#a8a8a8" opacity="0.29"/>
                                    </g>
                                </g>
                                </svg>
                            </div>}
                            <div className="image" style={{ top: -10 }}>
                                <img src="/images/for-partners/pic-8.png" alt="" />
                            </div>
                        </div>
                        <div className="col col-text">
                            <div className="text">
                                <h2>
                                    Easy dashboard voor real-time inzicht
                                </h2>
                                <p>
                                    Altijd een real-time inzicht van jouw restaurant dankzij ons overzichtelijke dashboard en reserveringsoverzichten.
                                </p>
                                <div className="icon-list">
                                    <ul>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Reservering details per dag / week / maand
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Aantal reserveringen
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Te verwachten personen
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Annuleringen
                                        </li>
                                        <li>
                                            <i className="fas fa-check-circle"></i>
                                            Beschikbare stoelen
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="half-page">
                    <div className="half-page-bg">
                        <div />
                        <div />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col col-text">
                                <div className="text">
                                    <h2 style={{ color: '#e3773b' }}>
                                        Wij dagen je uit!
                                    </h2>
                                    <p>
                                        <i className="fas fa-gift" style={{ marginRight: 16 }}></i>
                                        Maak jouw abonnement gratis
                                    </p>
                                    <p>
                                        Wij dagen je uit! Zorg ervoor dat jouw collega restauranthouders zich aanmelden bij EasyZeats en profiteer van € 2,50 korting op jouw abonnement. Net zo lang tot dat jouw EasyZeats gratis is!
                                    </p>
                                </div>
                            </div>
                            <div className="col col-text">
                                <div className="text">
                                    <div className="reg-form">
                                        {formStep === 3 ? (
                                            <h2 style={{ color: '#E3773B' }}>
                                                Bedankt! Voor het aanmelden
                                            </h2>
                                        ) : (
                                            <h2>
                                                Wie wil jij aanmelden?
                                            </h2>
                                        )}
                                        <div className="reg-form-steps">
                                            <div className={`step ${formStep === 1 ? 'isActive' : ''}`}>1</div>
                                            <div className={`step ${formStep === 2 ? 'isActive' : ''}`}>2</div>
                                            <div className={`step ${formStep === 3 ? 'isActive' : ''}`}>3</div>
                                        </div>
                                        {formStep === 1 && (
                                            <div className="reg-form-input">
                                                <Input
                                                    type="text"
                                                    onChange={(value: any) => handleReq('horeca', value)}
                                                    placeholder="Horeca/Restaurant"
                                                    value={req.horeca || ''}
                                                />
                                                <Input
                                                    type="text"
                                                    onChange={(value: any) => handleReq('contact', value)}
                                                    placeholder="Contactpersoon"
                                                    value={req.contact || ''}
                                                />
                                                <Input
                                                    type="text"
                                                    onChange={(value: any) => handleReq('info', value)}
                                                    placeholder="E-mailadres/telefoon"
                                                    value={req.info || ''}
                                                />
                                                <Button
                                                    onClick={() => updateStep(2)}
                                                    type="orange"
                                                >
                                                    Volgende
                                                </Button>
                                            </div>
                                        )}
                                        {formStep === 2 && (
                                            <div className="reg-form-input">
                                                <Input
                                                    type="text"
                                                    onChange={(value: any) => handleReq('name', value)}
                                                    placeholder="Jouw naam"
                                                    value={req.name || ''}
                                                />
                                                <Input
                                                    type="text"
                                                    onChange={(value: any) => handleReq('email', value)}
                                                    placeholder="Jouw e-mailadres"
                                                    value={req.email || ''}
                                                />
                                                <Input
                                                    type="text"
                                                    onChange={(value: any) => handleReq('phone', value)}
                                                    placeholder="Jouw telefoonnummer"
                                                    value={req.phone || ''}
                                                />
                                                <div className="buttons">
                                                    <Button
                                                        onClick={() => setFormStep(1)}
                                                        type="transparent"
                                                    >
                                                        Terug
                                                    </Button>
                                                    <Button
                                                        onClick={() => updateStep(3)}
                                                        type="orange"
                                                    >
                                                        Aanmelden
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row center">
                        <div className="col" style={{ maxWidth: 745 }}>
                            <h1>
                                Ben jij er klaar voor en kies jij voor Easy?
                            </h1>
                            <p style={{ fontSize: 26 }}>
                                EasyZeats is gratis te proberen, eenvoudig in te stellen en werkt naast jouw huidige systeem.
                            </p>
                            <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                    <i className="fas fa-check-circle" style={{ marginRight: 16 }}></i>
                                    Gratis proberen
                                </span>
                                <span>
                                    <i className="fas fa-euro-sign" style={{ marginRight: 16 }}></i>
                                    Maandelijks opzegbaar
                                </span>
                                <span>
                                    <i className="fas fa-crown" style={{ marginRight: 16 }}></i>
                                    Klant is koning
                                </span>
                            </p>
                            <form>
                                <Input
                                    type="text"
                                    onChange={(value: any) => setEmailAddress(value)}
                                    placeholder="Jouw e-mailadres"
                                    value={emailAddress}
                                />
                                <Button
                                    type="orange"
                                    onClick={() => tryForFree()}
                                >
                                    Gratis proberen
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title="Hoe werkt dit?"
                onClose={() => setShowModal(false)}
                visible={showModal !== false}
                type="large"
            >
                <div className="embed">
                    <iframe src={`https://www.youtube-nocookie.com/embed/${showModal}`} title="YouTube video player" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </Modal>

            <Modal
                title="Ik wil een demo"
                onClose={() => setShowDemo(false)}
                visible={showDemo}
                className="modal-v2-small"
            >
                <StyledText>
                    Ben jij benieuwd hoe het werkt en of het echt zo
                    gemakkelijk is? Geef ons door hoe wij jou kunnen
                    bereiken en wij nemen contact met jou op voor
                    een digitale of persoonlike afspraak.
                </StyledText>

                <div className="reg-form-input" style={{ margin: '24px 0 0 0' }}>
                    <Input
                        type="text"
                        onChange={(value: any) => handleDemo('name', value)}
                        placeholder="Jouw naam"
                    />
                    <Input
                        type="text"
                        onChange={(value: any) => handleDemo('email', value)}
                        placeholder="Jouw e-mailadres"
                    />
                    <Input
                        type="text"
                        onChange={(value: any) => handleDemo('phone', value)}
                        placeholder="Jouw telefoonnummer"
                    />
                    <div className="reg-modal-button">
                        <Button onClick={() => sendDemo()}>
                            Demo aanvragen
                        </Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    );
};


export default ForPartnersView;

import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MediaUpload from '../../../../components/MediaUpload';
import useGet from '../../../../hooks/rest/useGet';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import Form from '../../../../kit/Form';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import Grid from '../../../../kit/Grid';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';
import { setChangeNeeded } from '../../../../redux/change';

const AccountInfo: FC = (): JSX.Element => {
    const auth = useAuth();
	const dispatch = useDispatch();
    const [ categories ] = useGet('/categories');
	const [ errors, setErrors ] = useState<any>({});
	const [ patchProfile ] = usePatch('/auth/profile?validate=1');
	const [ restaurant, setRestaurant ] = useState<any>({});
    const [ logo, setLogo ] = useState<File>();
    const [ media, setMedia ] = useState<any[]>([undefined, undefined, undefined]);
    const [ deleteMedia, setDeleteMedia ] = useState<number[]>([]);
    const [ forceRender, setRender ] = useState<number>(0);

	useChangeCallback('restaurant-info', async (onSuccess) => {
        toggleFullscreenLoader(true);
		setErrors({});

        const promises = media.filter((o) => o).map(async (file) => {
            const fd = new FormData();
            fd.append('file', file);
            return await axios.post('/media', fd);
        });

        const results = await Promise.all(promises);
        restaurant.media = [
            ...restaurant.media.map((o: any) => o.id).filter((o: number) => !deleteMedia.includes(o)),
            ...results.map((o) => o.data.data.id),
        ];

        if (logo) {
            const fd = new FormData();
            fd.append('file', logo);
            const m = await axios.post('/media', fd);
            restaurant.media_id = m.data.data.id;
        }

		patchProfile({ ...auth, restaurant })
			.then(async({ data }) => {
                toggleFullscreenLoader(false);
				dispatch(updateProfile(data));
                setRender((r) => ++r);
				toast({
					header: 'Gelukt!',
					content: 'De gegevens van uw restaurant zijn aangepast.',
				});
                if (onSuccess) {
					onSuccess();
				}
			})
			.catch((err) => {
                toggleFullscreenLoader(false);
				dispatch(setChangeNeeded('restaurant-info'));
				setErrors(err.response.data.errors);
			});
	}, [auth, restaurant]);

	useChangesCanceled('restaurant-info', () => {
		setErrors({});
		setRestaurant({
            name: auth?.restaurant?.name || '',
            street: auth?.restaurant?.street || '',
            house_number: auth?.restaurant?.house_number || '',
            zipcode: auth?.restaurant?.zipcode || '',
            city: auth?.restaurant?.city || '',
            description: auth?.restaurant?.description || '',
            category_id: auth?.restaurant?.category_id || undefined,
            telephone: auth?.restaurant?.telephone || '',
            media: auth?.restaurant?.media || [],
            media_id: auth?.restaurant?.media_id || undefined,
            logo: auth?.restaurant?.logo || undefined,
        });
        setMedia([]);
        setDeleteMedia([]);
        setRender((r) => ++r);
	}, [auth]);

    useEffect(() => {
        setRestaurant({
            name: auth?.restaurant?.name || '',
            street: auth?.restaurant?.street || '',
            house_number: auth?.restaurant?.house_number || '',
            zipcode: auth?.restaurant?.zipcode || '',
            city: auth?.restaurant?.city || '',
            description: auth?.restaurant?.description || '',
            category_id: auth?.restaurant?.category_id || undefined,
            telephone: auth?.restaurant?.telephone || '',
            media: auth?.restaurant?.media || [],
            media_id: auth?.restaurant?.media_id || undefined,
            logo: auth?.restaurant?.logo || undefined,
        });
        setMedia([]);
        setDeleteMedia([]);
    }, [auth]);

	const handleInput = ({ name, value }: any) => {
		dispatch(setChangeNeeded('restaurant-info'));
		setRestaurant({
			...restaurant,
			[name]: value,
		});
	};

    const handleLogo = (file: File): void => {
        dispatch(setChangeNeeded('restaurant-info'));
        setLogo(file);
    }

    const handleDeleteLogo = (): void => {
        dispatch(setChangeNeeded('restaurant-info'));
        setLogo(undefined);
        setRestaurant({
            ...restaurant,
            logo: undefined,
            media_id: undefined,
        });
    }

    const handleMedia = (file: File, index: number): void => {
        dispatch(setChangeNeeded('restaurant-info'));
        const newMedia = [ ...media ];
        newMedia[index] = file;
        setMedia(newMedia);

        if (restaurant.media[index]) {
            const newDeleteMedia = [ ...deleteMedia ];
            newDeleteMedia[index] = restaurant.media[index].id;
            setDeleteMedia(newDeleteMedia);
        }
    }

    const handleDeleteMedia = (index: number): void => {
        dispatch(setChangeNeeded('restaurant-info'));
        const newMedia = [ ...media ];
        newMedia[index] = undefined;
        setMedia(newMedia);

        const newDeleteMedia = [ ...deleteMedia ];
        newDeleteMedia[index] = restaurant.media[index].id;
        setDeleteMedia(newDeleteMedia);
    }

    return (<>
        <p className="sv-description">
            Hier staan de gegevens en instellingen van jouw restaurant en zijn zichtbaar op jouw persoonlijke pagina. Klik op de afbeelding om een logo of omslagfoto te uploaden of te wijzigen.
        </p>
        <Form.Input
            error={errors.name}
            name="name"
            onChange={handleInput}
            placeholder="Restaurant name"
            value={restaurant.name}
        />
        <Form.Dropdown
            error={errors.category_id}
            name="category_id"
            onChange={handleInput}
            options={categories.data && categories.data.map((c: any) => ({
                text: c.name,
                value: c.id,
            }))}
            placeholder="Selecteer een categorie"
            value={restaurant.category_id}
        />
        <Grid.Row>
            <Grid.Column xs={8}>
                <Form.Input
                    error={errors.street}
                    name="street"
                    onChange={handleInput}
                    placeholder="Straat"
                    value={restaurant.street}
                />
            </Grid.Column>
            <Grid.Column xs={4}>
                <Form.Input
                    error={errors.house_number}
                    name="house_number"
                    onChange={handleInput}
                    placeholder="Huisnr."
                    value={restaurant.house_number}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column xs={4}>
                <Form.Input
                    error={errors.zipcode}
                    name="zipcode"
                    onChange={handleInput}
                    placeholder="Postcode"
                    value={restaurant.zipcode}
                />
            </Grid.Column>
            <Grid.Column xs={8}>
                <Form.Input
                    error={errors.city}
                    name="city"
                    onChange={handleInput}
                    placeholder="Plaats"
                    value={restaurant.city}
                />
            </Grid.Column>
        </Grid.Row>
        <Form.Input
            error={errors.telephone}
            name="telephone"
            onChange={handleInput}
            placeholder="Telefoonnummer"
            value={restaurant.telephone}
        />
        <Form.Input
            error={errors.website}
            name="website"
            onChange={handleInput}
            placeholder="Website"
            value={restaurant.website}
        />
        <Form.Textarea
            error={errors.description}
            name="description"
            onChange={handleInput}
            placeholder="Omschrijving"
            autoGrow
            value={restaurant.description}
        />
    
        <p className="sv-subtitle">
            Logo
        </p>
        <MediaUpload
            onChange={(file) => handleLogo(file)}
            media={restaurant?.logo?.url}
            onDelete={() => handleDeleteLogo()}
            key={`force-logo-${forceRender}`}
        />

        <p className="sv-subtitle" style={{ marginTop: 32 }}>
            Omslagfoto's
        </p>
        <div className="sv-flex-media">
            <div>
                <MediaUpload
                    onChange={(file) => handleMedia(file, 0)}
                    media={(restaurant?.media || [])[0] ? restaurant.media[0].url : undefined}
                    onDelete={() => handleDeleteMedia(0)}
                    square
                    key={`force-1-${forceRender}`}
                />
            </div>
            <div>
                <MediaUpload
                    onChange={(file) => handleMedia(file, 1)}
                    media={(restaurant?.media || [])[1] ? restaurant.media[1].url : undefined}
                    onDelete={() => handleDeleteMedia(1)}
                    square
                    key={`force-2-${forceRender}`}
                />
            </div>
            <div>
                <MediaUpload
                    onChange={(file) => handleMedia(file, 2)}
                    media={(restaurant?.media || [])[2] ? restaurant.media[2].url : undefined}
                    onDelete={() => handleDeleteMedia(2)}
                    square
                    key={`force-3-${forceRender}`}
                />
            </div>
        </div>
    </>);
}

export default AccountInfo;

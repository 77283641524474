import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import CheckBox from '../../../../components/CheckBox';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import Modal, { ModalProps } from '../../../../kit/Modal';
import axios from '../../../../lib/bootstrap';
import { setChangeNeeded } from '../../../../redux/change';
import DebouncedInput from '../DebounceInput';
import styles from './styles.module.scss';

interface FilterModalProps extends ModalProps {
    start?: string,
    end?: string,
    query?: string,
    onUpdate: (data: any) => void,
    view: 'list' | 'calendar',
}

const FilterModal: FC<FilterModalProps> = ({ query, start, end, onUpdate, view, ...modalProps }): JSX.Element => {
    const [ startQuery, setStartQuery ] = useState<string>(start || moment().format('YYYY-MM-DD'));
    const [ endQuery, setEndQuery ] = useState<string>(end || moment().format('YYYY-MM-DD'));
    const [ squery, setQuery ] = useState<string>(query || '');
    const [ selection, setSelection ] = useState<string[]>(['gewijzigd', 'geannuleerd', 'verlopen']);
    const [ selectedTimeslots, setSelectedTimeslots ] = useState<any[]>([]);
    const [ timeslots, setTimeslots ] = useState<any[]>();
    const [ showRange, setShowRange ] = useState<boolean>(false);
    const dispatch = useDispatch();
    const auth = useAuth();

    useEffect(() => {
        if (!auth) return;
        if (auth.restaurant?.modus === 'freq') {
            setSelectedTimeslots(['Ontbijt', 'Brunch', 'Lunch', 'Diner']);
        } else {
            setSelectedTimeslots([]);
        }
    }, [auth]);

    useEffect(() => {
        setStartQuery(start || moment().format('YYYY-MM-DD'));
        setEndQuery(end || moment().format('YYYY-MM-DD'));
        setQuery(query || '');
    }, [start, end, query]);

    useEffect(() => {
        if (auth?.restaurant?.modus === 'freq') return;
        axios.get(`/restaurants/${auth?.restaurant?.id}/timeslots/for-date`).then(({ data }) => {
            setTimeslots(data);
            setSelectedTimeslots(data.map((o: any) => o.id));
        });
    }, [startQuery, endQuery, auth]);
    
    useChangeCallback('filter-modal', () => {
        onUpdate({
            searchQuery: squery,
            startQuery,
            endQuery,
            statuses: selection,
            timeslots: selectedTimeslots,
        });
    }, [squery, startQuery, endQuery, selection, selectedTimeslots]);

    useChangesCanceled('filter-modal', () => {
        setStartQuery(start || moment().format('YYYY-MM-DD'));
        setEndQuery(end || moment().format('YYYY-MM-DD'));
        setQuery(query || '');
        if (auth?.restaurant?.modus === 'freq') {
            setSelectedTimeslots(['Ontbijt', 'Brunch', 'Lunch', 'Diner']);
        } else {
            setSelectedTimeslots([]);
        }
    }, [start, end, query, auth]);

    const handleStatus = (status: string, state: boolean): void => {
        let newSelection = [ ...selection ];
        if (state) {
            newSelection.push(status);
        } else {
            newSelection = newSelection.filter((o) => o !== status);
        }
        setSelection(newSelection);
        dispatch(setChangeNeeded('filter-modal'));
    }

    const handleTimeslots = (slotId: number | string, state: boolean): void => {
        let newTimeslots = [ ...selectedTimeslots ];
        if (state) {
            newTimeslots.push(slotId);
        } else {
            newTimeslots = newTimeslots.filter((o) => o !== slotId);
        }
        setSelectedTimeslots(newTimeslots);
        dispatch(setChangeNeeded('filter-modal'));
    }

    const handleRange = (dates: Date[]): void => {
        const s = moment(dates[0]).format('YYYY-MM-DD');
        const e = moment(dates[1]).format('YYYY-MM-DD');
        
        setStartQuery(s);
        setEndQuery(s > e ? s : e);
        setShowRange(false);
        dispatch(setChangeNeeded('filter-modal'));
    } 

    return (
        <Modal {...modalProps}>
            <div style={{ position: 'relative' }}>
                <div className={styles.formSection}>
                <div className={styles.search}>
                    <i className="fal fa-search"></i>
                    <DebouncedInput
                        value={squery}
                        onUpdate={(value) => {
                            setQuery(value);
                            dispatch(setChangeNeeded('filter-modal'));
                        }}
                    />
                </div>
                </div>
                {view !== 'calendar' && (<>
                    <div className={styles.formHeader}>
                        Specifieke dag of periode
                    </div>
                    <div className={styles.formSection}>
                        <div
                            className={`${styles.periodRange} ${squery === '' ? '' : styles.periodRangeDisabled}`}
                            onClick={() => setShowRange(!showRange)}
                        >
                            <div>
                                <div>
                                    {startQuery || 'Begindatum'}
                                    <i className="far fa-calendar-day" />
                                </div>
                            </div>
                            <div>
                                <div>
                                    {endQuery || 'Einddatum'}
                                    <i className="far fa-calendar-day" />
                                </div>
                            </div>
                        </div>
                        {showRange && squery === '' && (
                            <Calendar
                                className="mw-calendar"
                                locale="nl-NL"
                                nextLabel={<i className="far fa-chevron-right" />}
                                next2Label={null}
                                onChange={handleRange}
                                prevLabel={<i className="far fa-chevron-left" />}
                                prev2Label={null}
                                selectRange
                                value={[moment(startQuery).toDate(), moment(endQuery || startQuery).toDate()]}
                            />
                        )}
                    </div>
                </>)}
                <div className={styles.formHeader}>
                    Status
                </div>
                <div className={styles.formSection}>
                    <div style={{ marginBottom: 16 }}>
                        <CheckBox
                            label="Gewijzigd"
                            onChange={(v) => handleStatus('gewijzigd', v)}
                            value={selection.includes('gewijzigd')}
                        />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <CheckBox
                            label="Geannuleerd"
                            onChange={(v) => handleStatus('geannuleerd', v)}
                            value={selection.includes('geannuleerd')}
                        />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <CheckBox
                            label="Verlopen"
                            onChange={(v) => handleStatus('verlopen', v)}
                            value={selection.includes('verlopen')}
                        />
                    </div>
                </div>
                <div className={styles.formHeader}>
                    Tijdvak
                </div>
                <div className={styles.formSection}>
                    {auth?.restaurant && auth.restaurant.modus === 'freq' ? ['Ontbijt', 'Brunch', 'Lunch', 'Diner'].map((category) => (
                        <div style={{ marginBottom: 16 }} key={category}>
                            <CheckBox
                                label={category}
                                onChange={(v) => handleTimeslots(category, v)}
                                value={selectedTimeslots.includes(category)}
                            />
                        </div>
                    )) : (timeslots || []).map((slot) => (
                        <div style={{ marginBottom: 16 }} key={`slot-${slot.id}`}>
                            <CheckBox
                                label={`${slot.time.start} - ${slot.time.end}`}
                                onChange={(v) => handleTimeslots(slot.id, v)}
                                value={selectedTimeslots.includes(slot.id)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
}

export default FilterModal;

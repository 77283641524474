import React from "react";
import { NavLink } from "react-router-dom";
import "./restaurant-card.scss";
import Card from "../Card";
import StyledText from "../StyledText";
import Badge from "../Badge";
import default_header from "../../assets/images/default-profile-header.png";
import { Count } from "./RestaurantCardMinimal";

const ReastaurantCard = ({
	restaurant,
	noDescription,
}: {
	restaurant: any;
	noDescription?: boolean;
}) => {
	const formatAddress = () => {
		return `${restaurant.zipcode}, ${restaurant.city}`;
	};

	return (
		<NavLink
			style={{ textDecoration: "none" }}
			to={`/restaurants/profile/${restaurant.id}/show`}
			className={`
			restaurant-card
			${restaurant.media?.length ? "restaurant-card-has-slideshow" : ""}
			`}
		>
			<Card
				src={
					restaurant.thumbnail
						? restaurant.thumbnail.url
						: default_header
				}
				media={restaurant.media}
				placeholder={restaurant.name}
				className="restaurant-card-wrapper"
			>
				<div className="restaurant-card-info">
					<div className="restaurant-card-info-category">
						<StyledText>{restaurant.category.name}</StyledText>
					</div>
					<StyledText className="name" type="bold">
						{restaurant.name}
					</StyledText>
					<div className="row">
						<StyledText className="address">
							{formatAddress()}
						</StyledText>
						{restaurant.distance && (
							<Badge type="gray">
								{restaurant?.distance?.kilometers || ""} KM
							</Badge>
						)}
					</div>
					{!noDescription && (
						<StyledText className="description">
							{restaurant.description || ""}
						</StyledText>
					)}
				</div>
				<div className="restaurant-card-places">
					<StyledText type="bold">Vandaag beschikbaar</StyledText>
					<Count
						isPaused={restaurant.is_paused || restaurant?.is_empty}
						total={restaurant.spots_available}
					/>
				</div>
			</Card>
		</NavLink>
	);
};

export default ReastaurantCard;

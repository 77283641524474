import axios from "axios";
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useStripe, IdealBankElement} from '@stripe/react-stripe-js';
import Button from '../../kit/Button';
import Modal from '../../components/Modal';
import StyledText from '../../components/StyledText';
import StripeWrapper from '../../lib/StripeWrapper';
import { IDEAL_OPTIONS } from '../CheckoutView/CheckoutIban';
import { toast } from "../../kit/NotificationCenter";

interface ChangePaymentProps {
    sepa?: string,
}

const ChangePayment: FC<ChangePaymentProps> = ({ sepa }) => {
    const [ visible, setVisible ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);
    const stripe = useStripe() as any;
    const [ bank, setBank ] = useState('');
    const auth = useSelector((state: any) => state.auth);
    const [name] = useState(auth.full_name || "");
    const subscription = useSelector(
		(state: any) => state.auth.current_subscription
	);

    useEffect(() => {
        if (window.location.search.includes('redirect_status=succeeded')) {
            axios({
                url: '/subscriptions/set-default-payment',
                method: 'POST',
            }).then(() => {
                window.location.href = '?alert';
            });
        }

        if (window.location.search.includes('alert')) {
            toast({
                header: "Gelukt!",
                content: "Betaalmethode succesvol gewijzigd",
            });
        }
    }, []);

    const handleSubmit = async () => {
        setLoading(true);

        if (!stripe) {
            return;
        }

        axios({
			url: `/subscriptions/get-intent`,
			method: "POST",
		}).then(({ data }) => {
            stripe.confirmIdealSetup(data, {
                payment_method: {
                    ideal: { bank },
                    billing_details: {
                        name: name,
                        email: auth.email,
                    },
                },
                return_url: `${window.location.origin}/account/invoices`,
            });
        });
    };

    return (
        <div className="invoices-view-change">
            <StyledText>
                <b>Betalingsmethode:</b><br />
                Automatisch incasso{sepa && ` eindigend op ${sepa}`}
            </StyledText>
            <Modal
                title="Betalingsmethode wijzigen"
                {...{ visible, loading }}
                onClose={() => setVisible(false)}
            >
                <StripeWrapper>
                    <div className="change-modal-body">
                        <div className="change-modal-info">
                            <StyledText>
                                Werk je automatische overschrijving via iDEAL bij.
                            </StyledText>
                            <div style={{ margin: '32px 0' }} className="hide-xs">
                                <p className="change-modal-body-step styled-text-paragraph">
                                    <span className="badge">1</span>
                                    Selecteer de bank
                                </p>
                                <p className="change-modal-body-step styled-text-paragraph">
                                    <span className="badge">2</span>
                                    Klik op Bijwerken
                                </p>
                                <p className="change-modal-body-step styled-text-paragraph" style={{ marginTop: -6, paddingBottom: 0 }}>
                                    <span className="badge">3</span>
                                    Rond de verificatietransactie af<br />van € 0,01 euro.
                                </p>
                            </div>
                            <p className="styled-text-paragraph hide-xs" style={{ fontSize: 10 }}>
                                Als je via iDEAL een eerste verificatietransactie van 0,01 euro uitvoert, wordt je bankrekeningnummer doorgegeven aan EasyZeats. Je geeft EasyZeats dan toestemming om {subscription && subscription.stripe_plan === 'price_1HdejFJW377LUmoXH6n20ud5' ? 'maandelijks € 39,-' : 'jaarlijks € 365,-'} af te schrijven van je bankrekening vanaf je volgende betaalcyclus.
                            </p>
                        </div>
                        <div className="change-modal-actions" style={{ marginTop: 32 }}>
                            <IdealBankElement
                                options={IDEAL_OPTIONS as any}
                                className="ideal-element"
                                onChange={(e) => setBank(e.value)}
                            />
                            <div className="buttons">
                                <Button
                                    {...{ loading }}
                                    onClick={handleSubmit}
                                    type={bank ? '' : "disabled"}
                                >
                                    Bijwerken
                                </Button>
                            </div>
                            <div style={{ marginBottom: 64 }} className="show-xs">
                                <p className="change-modal-body-step styled-text-paragraph">
                                    <span className="badge">1</span>
                                    Selecteer de bank
                                </p>
                                <p className="change-modal-body-step styled-text-paragraph">
                                    <span className="badge">2</span>
                                    Klik op Bijwerken
                                </p>
                                <p className="change-modal-body-step styled-text-paragraph" style={{ marginTop: -6, paddingBottom: 0 }}>
                                    <span className="badge">3</span>
                                    Rond de verificatietransactie af<br />van € 0,01 euro.
                                </p>
                            </div>
                            <p className="styled-text-paragraph show-xs" style={{ fontSize: 10 }}>
                                Als je via iDEAL een eerste verificatietransactie van 0,01 euro uitvoert, wordt je bankrekeningnummer doorgegeven aan EasyZeats. Je geeft EasyZeats dan toestemming om maandelijks € 39,- af te schrijven van je bankrekening vanaf je volgende betaalcyclus.
                            </p>
                        </div>
                    </div>
                </StripeWrapper>
            </Modal>
            {/* <Button className="change-modal-btn" type="secondary" loading={loading} onClick={() => setVisible(true)}> */}
            <Button
                color="green"
                label="Betalingsmethode wijzigen"
                onClick={() => setVisible(true)}
            />
        </div>
    );
}

export default (props: ChangePaymentProps) => (
	<StripeWrapper>
		<ChangePayment {...props} />
	</StripeWrapper>
);

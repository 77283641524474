import React, { createRef, FC, RefObject, useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface AccordionItemProps {
    content: string | JSX.Element,
    title: string | JSX.Element,
}

const AccordionItem: FC<AccordionItemProps> = ({ content, title }): JSX.Element => {
    const containerRef: RefObject<HTMLDivElement> = createRef();
    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(true);

    useEffect(() => {
        if (!containerRef.current) return;
        containerRef.current.style.setProperty('--max-height', (containerRef.current.scrollHeight + 60) + 'px');
    }, [containerRef]);

    const handleToggle = (force?: boolean): void => {
        if (!containerRef.current) return;
        containerRef.current.style.setProperty('--max-height', (containerRef.current.scrollHeight + 60) + 'px');
        setIsCollapsed(force !== undefined ? force : !isCollapsed);
    }

    return (
        <div className={`${styles.item}  ${isCollapsed ? styles.collapsed : ''}`}>
            <div className={styles.title} onClick={() => handleToggle()}>
                {title}
                <i className={`far fa-${isCollapsed ? 'chevron-right' : 'chevron-up'}`} />
            </div>
            <div className={styles.content} ref={containerRef}>
                {content}
            </div>
        </div>
    );
}

export default AccordionItem;

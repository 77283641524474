import { createAction, handleActions } from "redux-actions";

export const updateProfile = createAction("UPDATE_PROFILE");
export const logout = createAction("REMOVE_PROFILE");

export const emptyAuthState = {
	id: null,
	full_name: "",
	first_name: "",
	affix: "",
	last_name: "",
	email: "",
	notification_email: undefined,
	telephone: "",
	restaurant: undefined,
	current_subscription: {},
	is_whitelisted: false,
	is_subscribed: false,
	is_admin: false,
	allow_email: true,
};

export interface AuthState {
	id: number | null;
	full_name: string;
	first_name: string;
	affix?: string;
	last_name: string;
	telephone?: string;
	email: string;
	notification_email?: string;
	restaurant?: Restaurant;
	current_subscription: any;
	is_whitelisted: boolean;
	is_subscribed: boolean;
	is_admin: boolean;
	action_needed?: number;
	allow_email: boolean,
};

export type Restaurant = {
	id: number;
	key: string;
	name: string;
	street: string;
	house_number: string;
	city: string;
	zipcode: string;
	website?: string;
	telephone?: string,
	description?: string;
	category_id?: number;
	category?: {
		id: number;
		name: string;
		slug: string;
	};
	distance?: {
		kilometers: any;
		meters: any;
	};
	lat: string;
	lng: string;
	logo?: any,
	media_id?: number;
	media?: any[],
	thumbnail?: any;
	is_subscribed: boolean;
	is_paused: boolean,
	dietary_needs: {
		id: number,
		name: string,
	}[],
	settings?: {
		[key: string]: any,
	},
	modus: 'slot' | 'freq',
};

const authReducer = handleActions(
	{
		UPDATE_PROFILE: (state: AuthState, action: any) => {
			if (action.payload && action.payload.token) {
				localStorage.setItem("token", action.payload.token);
			}
			return { ...state, ...action.payload };
		},
		REMOVE_PROFILE: () => {
			localStorage.setItem("token", "");
			return {
				...emptyAuthState,
			};
		},
	},
	{
		...emptyAuthState,
	}
);
export default authReducer;

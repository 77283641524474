import { useState } from "react";
import axios from "axios";

const useDelete = (route?: string) => {
	const [loading, setLoading] = useState(false);

	const deleteFunction = async (params?: any) => {
		if (!route) return;
		setLoading(true);
		return axios
			.delete(route, {
				data: params,
			})
			.then(({ data }) => data)
			.finally(() => setLoading(false));
	};

	return [deleteFunction, loading] as [
		(params?: any) => Promise<any>,
		boolean
	];
};

export default useDelete;

import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import "./dashboard-view.scss";
import StyledText from "../../../components/StyledText";
import useGet from "../../../hooks/rest/useGet";
import Loading from "../../../components/Loading";
import moment from "moment";
import Button from "../../../components/Button";

export const RefetchButton = ({
	onClick,
	className,
}: {
	onClick: any;
	className?: string;
}) => {
	return (
		<Button
			isRefresh
			type="gray"
			onClick={onClick}
			className={`icon refetch-icon ${className || ""}`}
		></Button>
	);
};

const DashboardView = () => {
	const [result, loading, , refetch] = useGet("/stats");
	const subscription = useSelector(
		(state: any) => state.auth.current_subscription
	);
	const data = result?.data || {};
	const year = data.year || [];

	return (
		<Layout
			className="dashboard-view"
			type="admin"
			button={
				<RefetchButton className="refetch-tablet" onClick={refetch} />
			}
			title="Dashboard"
		>
			<div className="row" style={{ marginTop: 20 }}>
				<Greeting loading={loading} onRefetch={refetch} />
				{subscription ? (
					<div className="sub-box">
						<StyledText type="bold">
							Volgende factuur datum
						</StyledText>
						<StyledText>
							{moment(subscription.cycle_ends_at).format(
								"DD MMMM YYYY"
							)}
						</StyledText>
					</div>
				) : (
					<div style={{ maxWidth: 300 }} className="sub-box">
						<StyledText>
							U maakt gebruik van een Easyzeats gratis
							abbonnement.
						</StyledText>
					</div>
				)}
			</div>
			{loading ? (
				<Loading />
			) : (
				<>
					<StyledText className="heads" type="bold">
						Vandaag
					</StyledText>
					<div className="grid">
						<StatBlock
							text="Reserveringen"
							count={data.today?.reservations}
						/>
						<StatBlock
							text="Geannuleerd"
							count={data.today?.canceled}
						/>
						<StatBlock
							text="Verwachte aantal personen"
							count={data.today?.persons}
						/>
					</div>

					<StatSlider {...{ year }} />
				</>
			)}
		</Layout>
	);
};

const StatSlider = ({ year }: any) => {
	const slider = useRef(null) as any;
	useEffect(() => {
		if (slider) {
			let scrollTo = 0;
			year.forEach((i: any, index: number) => {
				if (i.is_current) {
					scrollTo = index;
				}
			});

			scrollTo = scrollTo !== 1 ? scrollTo - 1 : scrollTo;
			if (slider?.current) {
				slider.current.slickGoTo(scrollTo);
			}
		}
	}, [year, slider]);
	return (
		<div className="month">
			<div className="month-head">
				<StyledText className="heads" type="bold">
					Maand overzicht
				</StyledText>
				<div className="arrows">
					<div
						onClick={() => {
							if (slider) {
								slider.current.slickPrev();
							}
						}}
						className="arrow"
					>
						<i className="fal fa-angle-left"></i>
					</div>
					<div
						onClick={() => {
							if (slider) {
								slider.current.slickNext();
							}
						}}
						className="arrow"
					>
						<i className="fal fa-angle-right"></i>
					</div>
				</div>
			</div>
			<div className="month-body">
				<Slider
					ref={slider}
					slidesToShow={3}
					arrows={false}
					dots={false}
					infinite={false}
					responsive={[
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 2,
							},
						},
						{
							breakpoint: 480,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
					]}
				>
					{year.map((i: any) => (
						<MonthStatBlock key={i.name} {...{ ...i }} />
					))}
				</Slider>
			</div>
		</div>
	);
};

const MonthStatBlock = ({ name, reservations, canceled, persons }: any) => (
	<div className="month-stat-block">
		<div className="month-stat-block-head">
			<StyledText type="bold">{name}</StyledText>
		</div>
		<ul>
			<li>
				<StyledText>Reserveringen</StyledText>
				<div className="month-stat-block-count">{reservations}</div>
			</li>
			<li>
				<StyledText>Geannuleerd</StyledText>
				<div className="month-stat-block-count">{canceled}</div>
			</li>
			<li>
				<StyledText>Aantal bezoekers</StyledText>
				<div className="month-stat-block-count">{persons}</div>
			</li>
		</ul>
	</div>
);

const StatBlock = ({ text, count }: { text: string; count: number }) => (
	<div className="stat-block">
		<StyledText type="bold">{text}</StyledText>
		<div className="month-stat-block-count">{count}</div>
	</div>
);

const Greeting = ({
	onRefetch,
	loading,
}: {
	onRefetch?: any;
	loading?: boolean;
}) => {
	const auth = useSelector((state: any) => state.auth);
	return (
		<div className="greeting">
			<div className="greeting-info">
				<StyledText type="bold" className="greeting-title">
					Hallo, {auth?.restaurant?.name || ""}
				</StyledText>
				<StyledText className="greeting-subtitle">
					Werkse vandaag!
				</StyledText>
			</div>
			{onRefetch && <RefetchButton onClick={onRefetch} />}
		</div>
	);
};

export default DashboardView;

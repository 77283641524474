import React, { useRef } from "react";
import Slider from "react-slick";

import "./slide-show.scss";

const SlideShow = ({
	media,
	autoplay,
}: {
	media: any[];
	autoplay?: boolean;
}) => {
	const slider: any = useRef(null);

	return (
		<div className="slide-show">
			{!autoplay && media.length !== 1 && (
				<div
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();

						if (slider && slider.current) {
							slider.current.slickPrev();
						}
					}}
					className="arrow arrow-prev"
				>
					<div className="icon">
						<i className="fal fa-angle-left"></i>
					</div>
				</div>
			)}

			{!autoplay && media.length !== 1 && (
				<div className="arrow arrow-next">
					<div
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							if (slider && slider.current) {
								slider.current.slickNext();
							}
						}}
						className="icon"
					>
						<i className="fal fa-angle-right"></i>
					</div>
				</div>
			)}

			<Slider
				autoplay={autoplay}
				autoplaySpeed={4000}
				ref={slider}
				slidesToShow={1}
				arrows={false}
				dots={false}
			>
				{media.map((item) => (
					<img key={item.id} src={item.url} alt="" />
				))}
			</Slider>
		</div>
	);
};

export default SlideShow;

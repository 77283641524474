import React from "react";
import "./footer.scss";
import Logo from "../Logo";
import StyledText from "../StyledText";
import { NavLink } from "react-router-dom";

const Footer = ({ className }: { className?: string }) => {
	return (
		<footer className={`footer ${className || ""}`}>
			<div className="container">
				<div className="footer-grid">
					<div className="col">
						<div className="footer-logo">
							<Logo type="gradient" to="/" />
						</div>
					</div>
					<div className="col">
						<div className="footer-socials">
							<Socials />
						</div>
					</div>
					<div className="col">
						<div className="footer-list">
							<StyledText type="label">EasyZeats</StyledText>
							<NavLink to="/contact">Contact</NavLink>
						</div>
					</div>
					<div className="col">
						<div className="footer-list">
							<StyledText type="label">Partners</StyledText>
							<NavLink to="/for-partners">Partner worden</NavLink>
							<NavLink to="/subscriptions">Abonnementen</NavLink>
							<NavLink to="/help">Hulp voor partners</NavLink>
						</div>
					</div>
					<div className="col">
						<div className="footer-list">
							<StyledText type="label">
								Voorwaarden & Privacy
							</StyledText>
							<NavLink to="/terms-and-conditions">
								Algemene voorwaarden
							</NavLink>
							<NavLink to="/privacy-policy">
								Privacy verklaring
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export const Socials = () => (
	<>
		<SocialButton
			href="https://www.linkedin.com/company/easyzeats/"
			icon="fa-linkedin-in"
		/>
		<SocialButton
			href="https://www.facebook.com/easyzeats"
			icon="fa-facebook-f"
		/>
		<SocialButton
			href="https://www.instagram.com/easyzeats/"
			icon="fa-instagram"
		/>
	</>
);

const SocialButton = ({ href, icon }: any) => (
	<a
		href={href}
		target="_blank"
		rel="noopener noreferrer"
		className="social-button"
	>
		<i className={`fab ${icon}`}></i>
	</a>
);

export default Footer;

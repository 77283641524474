import { debounce, DebouncedFunc } from 'lodash';
import React, { HTMLAttributes } from 'react';
import Input from '../../../components/Input';

interface DebouncedInputProps extends HTMLAttributes<HTMLInputElement> {
    defaultValue?: string,
    onUpdate: (query: string) => void,
    value?: string,
}

interface DebouncedInputState {
    query: string,
}

class DebouncedInput extends React.Component<DebouncedInputProps, DebouncedInputState> {
    private doSearch: DebouncedFunc<(query: string) => void>;

    constructor (props: DebouncedInputProps) {
        super(props);

        this.state = {
            query: props.defaultValue || '',
        };

        this.doSearch = debounce((query: string) => {
            const { onUpdate } = this.props;
            onUpdate(query);
        }, 500);
    }

    handleChange = (value: string): void => {
        this.setState({ query: value });
        this.doSearch(value.trim());
    }

    render = (): JSX.Element => {
        const { onChange, onUpdate, value, ...rest } = this.props;
        const { query } = this.state;

        return (
            <Input
                onChange={this.handleChange}
                value={query === '' ? (value || '') : query}
                {...rest}
            />
        );
    }
}

export default DebouncedInput;

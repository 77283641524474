import React, { useRef } from "react";
import "./suggestions.scss";
import StyledText from "../StyledText";
import useGet from "../../hooks/rest/useGet";
import { motion } from "framer-motion";
import ReastaurantCard from "../RestaurantCard";
import Slider from "react-slick";

const variants = {
	container: {
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: 0.2,
			},
		},
		exit: { y: -20, opacity: 0 },
	},
	card: {
		enter: {
			y: 0,
			opacity: 1,
		},
		exit: {
			y: -20,
			opacity: 0,
		},
	},
};

const Suggestions = () => {
	const [result] = useGet(`/restaurants?limit=10&random=1`);
	const slider = useRef(null) as any;

	const renderItems = () => {
		let items = result?.data || [];
		return items && items.length
			? items.map((item: any) => {
					return (
						<motion.div
							variants={variants.card}
							key={item.name + item.id}
						>
							<ReastaurantCard
								noDescription
								{...{ restaurant: item }}
							/>
						</motion.div>
					);
			  })
			: false;
	};

	return (
		<section className="suggestions">
			<div className="container">
				<div className="suggestions-title">
					<StyledText type="subtitle">Restaurants</StyledText>
					<div className="arrows">
						<div
							onClick={() => {
								if (slider) {
									slider.current.slickPrev();
								}
							}}
							className="arrow"
						>
							<i className="fal fa-angle-left"></i>
						</div>
						<div
							onClick={() => {
								if (slider) {
									slider.current.slickNext();
								}
							}}
							className="arrow"
						>
							<i className="fal fa-angle-right"></i>
						</div>
					</div>
				</div>

				<div className="suggestions-items">
					<Slider
						ref={slider}
						slidesToShow={3}
						arrows={false}
						dots={false}
						infinite={false}
						// autoplay={true}
						autoplaySpeed={7000}
						responsive={[
							{
								breakpoint: 600,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
								},
							},
							{
								breakpoint: 480,
								settings: {
									arrows: false,
									slidesToShow: 1,
									slidesToScroll: 1,
									dots: true,
								},
							},
						]}
					>
						{renderItems()}
					</Slider>
				</div>
			</div>
		</section>
	);
};

export default Suggestions;

import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./layout.scss";
import Nav from "./Nav/Nav";
import AdminNav from "./AdminNav";
import StyledText from "../StyledText";
import Footer from "../Footer";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { useChangeNeeded, useChangeBtnLabel } from "../../hooks/useChange";
import Button from '../../kit/Button';
import { setChangeNeeded } from "../../redux/change";
import { toast } from "../../kit/NotificationCenter";
import { openDoReservationModal } from "../DoReservationModal";
import { useAuth } from "../../hooks/useAuth";

type LayoutProps = {
	children?: any;
	type?: string;
	loading?: boolean;
	title?: string;
	error?: any;
	isSearch?: boolean;
	className?: string;
	isWhite?: boolean;
	noLine?: boolean;
	button?: any;
	footerName?: string;
	showButtonMobile?: boolean;
	back?: string,
};

const Layout = ({
	children,
	type,
	title,
	loading,
	error,
	className,
	isSearch,
	isWhite,
	showButtonMobile,
	button,
	footerName,
	noLine,
	back,
}: LayoutProps) => {
	const [ selectLeft, setSelectLeft ] = useState<number>(0);
	const [ doReservation, setDoReservation ] = useState<boolean>(false);
	const [ actualTitle, setActualTitle ] = useState<string>(title || '');
	const windowWidth = useWindowWidth();
	const location = useLocation();
	const changeNeeded = useChangeNeeded();
	const changeBtnLabel = useChangeBtnLabel();
	const dispatch = useDispatch();
	const history = useHistory();
	const auth = useAuth();

	useEffect(() => {
		let left = 0;

		if (doReservation) {
			left = 40;
		} else {
			if (location.pathname === '/dashboard') {
				left = 0;
			} else if (/^\/dashboard\/settings/.test(location.pathname)) {
				left = 80;
			} else if (location.pathname === '/reservations/attention' || location.search === '?view=list') {
				left = 20;
			} else if (location.pathname === '/reservations' || location.search === '?view=calendar') {
				left = 60;
			}
		
			setDoReservation(false);
		}
		setSelectLeft(left);
	}, [location, doReservation]);

	useEffect(() => {
		if (doReservation) {
			setActualTitle('Nieuwe reservering');
		} else {
			setActualTitle(title || '');
		}
		setTimeout(() => setDoReservation(false), 500);
	}, [doReservation, title]);

	const handleLeave = (e: any): void => {
		if (changeNeeded && !['contact', 'add-block-period', 'delete-timeslots'].includes(changeNeeded)) {
			toast({
				header: 'Let op',
				content: 'Weet je zeker dat je de pagina wilt verlaten? Nog niet alle gegevens zijn opslagen.',
				type: 'error',
				approveBtn: 'Ja, ik weet het zeker',
				cancelBtn: 'Annuleren',
				onApprove: () => {
					dispatch(setChangeNeeded(undefined));
					history.push(back || '/');
				},
			});

			e.preventDefault();
		} else {
			dispatch(setChangeNeeded(undefined));
		}
	}

	if (type === "admin") {
		if (windowWidth <= 1024) {
			return (<>
				<div className="layout-admin-mobile">
					<div className={`top-bar${back ? '' : ' top-bar-title'}`}>
						{back && (
							<Link
								className="top-bar-icon"
								onClick={handleLeave}
								to={back}
							>
								<i className="fal fa-arrow-left" />
							</Link>
						)}
						<span>{actualTitle || ''}</span>
						<AdminNav />
					</div>
					
					<div className={className || ""}>
						{children}
					</div>
					<div className="tab-bar">
						{!changeNeeded && (
							<div className="tab-select" style={{ transform: `translateX(${selectLeft}%)` }}>
								<div />
							</div>
						)}
						<div className={`tab-bar-changes${changeNeeded ? ' tab-bar-changes-in' : ''}`}>
							<Button
								block
								label={changeNeeded === 'contact' ? "Bellen" : "Annuleren"}
								onClick={() => {
									if (changeNeeded === 'contact') {
										window.location.href = 'tel:+31621461145';
									} else {
										dispatchEvent(new CustomEvent('changes-canceled'));
									}
								}}
							/>
							<Button
								block
								color={changeNeeded === 'delete-timeslots' ? 'red' : 'green'}
								label={changeNeeded === 'contact'
									? "Versturen"
									: (changeNeeded === 'group-action' ? 'Groep actie' : changeBtnLabel)
								}
								onClick={() => dispatchEvent(new CustomEvent('changes-saved'))}
							/>
						</div>
						<Link
							className={`tab-bar-item${selectLeft === 0 ? ' is-active' : ''}`}
							to="/dashboard"
						>
							<i className="fas fa-chart-line" />
						</Link>
						<Link
							className={`tab-bar-item${selectLeft === 20 ? ' is-active' : ''}`}
							to={(auth?.action_needed || 0) > 0 ? '/reservations/attention' : '/reservations?view=list'}
						>
							{(auth?.action_needed || 0) > 0 && <span>{auth?.action_needed}</span>}
							<i className="fas fa-list" />
						</Link>
						<div
							className="tab-bar-item"
							onClick={() => {
								setDoReservation(!doReservation);
								openDoReservationModal(auth?.restaurant?.id || 0);
							}}
						>
							<div className={doReservation ? 'rotate' : ''}>
								<i className="far fa-plus" />
							</div>
						</div>
						<Link
							className={`tab-bar-item${selectLeft === 60 ? ' is-active' : ''}`}
							to="/reservations?view=calendar"
						>
							<i className="fas fa-calendar-day" />
						</Link>
						<Link
							className={`tab-bar-item${selectLeft === 80 ? ' is-active' : ''}`}
							to="/dashboard/settings"
						>
							<i className="fas fa-cog" />
						</Link>
					</div>
				</div>
			</>);
		}

		return (
			<div className={`layout-admin ${noLine ? "layout-no-line" : ""}`}>
				<AdminNav title={title} />
				<main className={className || ""}>
					<div className="layout-head">
						{title && (
							<StyledText className="layout-title" type="title">
								{title}
							</StyledText>
						)}
						{button || false}
					</div>
					{button && showButtonMobile && (
						<div className="layout-mobile-button">{button}</div>
					)}

					{loading ? (
						<div className="layout-admin-loading">
							<i className="fal fa-spin fa-spinner-third"></i>
						</div>
					) : (
						children
					)}
				</main>
			</div>
		);
	}

	if (type !== "admin" && loading) {
		return (
			<>
				<Nav />
				<main className={`layout ${className || ""}`}>
					<div className="layout-loading">
						<i className="fal fa-spin fa-spinner-third"></i>
					</div>
				</main>
			</>
		);
	}

	return (
		<>
			<Nav {...{ isSearch, isWhite, button }} />
			<main className={`layout ${className || ""}`}>
				{error ? (
					<div className="layout-error">
						<StyledText type="title">{error}</StyledText>
					</div>
				) : (
					children
				)}
			</main>
			<Footer className={footerName} />
		</>
	);
};

export default Layout;

import React, { FC, HTMLAttributes, CSSProperties } from 'react';
import styles from './styles.module.scss';

export interface TableCellProps extends HTMLAttributes<HTMLTableDataCellElement & HTMLTableHeaderCellElement> {
    actions?: boolean,
    align?: 'center' | 'left' | 'right',
    as?: 'td' | 'th',
    collapsing?: boolean,
    name?: string,
    onExpand?: () => void,
    primary?: boolean,
    responsive?: boolean,
}

const TableCell: FC<TableCellProps> = ({
    actions,
    align,
    as,
    children,
    className,
    collapsing,
    name,
    onExpand,
    primary,
    responsive,
    ...props
}): JSX.Element => {
    const Elem = as ? as : 'td';

    const style: CSSProperties = {};
    if (props.onClick) {
        style.cursor = 'pointer';
    }
    
    return (
        <Elem
            className={[
                collapsing && styles.collapsing,
                primary && styles.primary,
                actions && styles.actions,
                responsive === false && styles.notResponsive,
                align && styles[`${align}Align`],
                className,
            ].filter(o => o).join(' ')}
            style={style}
            {...props}
        >
            <div data-name={name} style={{ width: '100%' }}>
                {children}
            </div>
            {primary && (
                <div className={styles.collapseIcon} onClick={onExpand}>
                    <i className="fas fa-caret-right" />
                </div>
            )}
        </Elem>
    );
}

export default TableCell;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import "./checkout-view.scss";
import "../Auth/RegisterView/register-view.scss";
import usePaymentRedirect from "../../hooks/usePaymentRedirect";
import { useDispatch, useSelector } from "react-redux";
import { IdealBankElement, useStripe } from "@stripe/react-stripe-js";
import StripeWrapper from "../../lib/StripeWrapper";
import usePatch from "../../hooks/rest/usePatch";
import { updateProfile } from "../../redux/auth";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import StyledText from "../../components/StyledText";
import Logo from "../../components/Logo";
import axios from "../../lib/bootstrap";

const CheckoutView = () => {
	const auth = useSelector((state: any) => state.auth);

	const params = useParams() as any;

	useEffect(() => {
		if (auth && params.plan) {
			axios.post(`payment/create/${params.plan}`, { email: auth.email })
				.then(({ data }: any) => {
					window.location.href = data.url;
				});
		}
	}, [auth, params]);

	return <div className="register-view">
	 			<div style={{ padding: '0 20px' }}>
	 				<div className="register-view-loading">
	 					<Loading />
	 				</div>
	 			</div>
	 		</div>;

	// const stripe = useStripe() as any;
	// const params = useParams() as any;
	// const history = useHistory();
	// const auth = useSelector((state: any) => state.auth);
	// const price = 0.5;
	// const [ isLoading, setIsLoading ] = useState<boolean>(false);
	// const dispatch = useDispatch();
	// const [setProfilePlan] = usePatch("/auth/profile");
	// const [loading, status, data] = usePaymentRedirect({
	// 	price,
	// 	stripe,
	// 	plan: params.plan,
	// });

	// let whitelist_wait = process.env.REACT_APP_USE_WHITELIST;
	// useEffect(() => {
	// 	if (whitelist_wait === "true") history.push("/signup");
	// }, [history, whitelist_wait]);

	// const setFreePlan = useCallback(() => {
	// 	if (params.plan === "free") {
	// 		setProfilePlan({ is_free: true }).then(({ data }) => {
	// 			dispatch(updateProfile(data));
	// 			history.push("/dashboard");
	// 		});
	// 	}
	// }, [dispatch, history, params.plan, setProfilePlan]);

	// useEffect(() => setFreePlan(), [setFreePlan]);

	// useEffect(() => {
	// 	if (data && data.source && status !== '') {
	// 		history.push(`/signup?status=${status === 'geslaagd' ? 'success' : 'error'}&plan=${params.plan}`);
	// 	}
	// }, [data, status, history]); // eslint-disable-line

	// const handleCheckout = (): void => {
	// 	setIsLoading(true);
	// 	stripe
	// 		.createSource({
	// 			type: "ideal",
	// 			amount: price * 100,
	// 			currency: "eur",
	// 			statement_descriptor: `EasyZeats Subscribtion: ${params.plan}`,
	// 			owner: {
	// 				name: auth.full_name,
	// 				email: auth.email,
	// 			},
	// 			redirect: {
	// 				return_url: `${window.location.origin}/checkout/${params.plan}`,
	// 			},
	// 		})
	// 		.then(({ source }: any) => {
	// 			window.location = source.redirect.url;
	// 		});
	// }

	// if (params.plan === "free") {
	// 	return <Layout loading={true}></Layout>;
	// }

	// if (data?.source || isLoading) {
	// 	return (
	// 		<div className="register-view">
	// 			<div style={{ padding: '0 20px' }}>
	// 				<div className="register-view-loading">
	// 					<Loading />
	// 				</div>
	// 			</div>
	// 		</div>
	// 	)
	// }

	// return (
	// 	<div className="register-view">
	// 		<div style={{ padding: '0 20px' }}>
	// 			<div className="register-grid">
	// 				<div className="login-view-logo">
	// 					<Logo to="/" type="circle-white-stacked" />
	// 				</div>
	// 				<StyledText className="register-grid-title">
	// 					Afrekenen
	// 				</StyledText>

	// 				<div className="form">
	// 					<div className="form-fields">
	// 						<div className="register-info">
	// 							<div>
	// 								<i className="fal fa-info-circle"></i>
	// 							</div>
	// 							<StyledText>Met een iDEAL verificatietransactie van 0,01 euro, wordt je bankrekeningnummer & betaalmethode doorgegeven aan EasyZeats. Je geeft EasyZeats toestemming om na de gratis periode maandelijks € 39,- af te schrijven van je bankrekening.
	// 							</StyledText>
	// 						</div>
	// 						<div style={{ marginBottom: 24 }}>
	// 							<IdealBankElement
	// 								options={{
	// 									style: {
	// 										base: {
	// 											backgroundColor: '#ffffff',
	// 											padding: '15px 30px',
	// 											borderRadius: '5px',
	// 										},
	// 									},
	// 								} as any}
	// 								className="ideal-element"
	// 							/>
	// 						</div>
	// 					</div>
	// 					<div className="buttons">
	// 						<Button
	// 							icon="fas fa-arrow-left"
	// 							type="transparent"
	// 							to="/"
	// 						>
	// 							Annuleren
	// 						</Button>
	// 						<Button
	// 							loading={loading}
	// 							onClick={handleCheckout}
	// 							iconPosition="right"
	// 							icon="fas fa-arrow-right"
	// 						>
	// 							Afronden
	// 						</Button>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
};

export default () => (
	<CheckoutView />
);

import React from "react";
import "./home-view.scss";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import SearchBox from "../../components/SearchBox";
import header from "../../assets/images/header-default.jpg";
import Instructions from "../../components/Instructions";
import Suggestions from "../../components/Suggestions";

const HomeView = () => {
	return (
		<Layout>
			<Banner
				className="search-banner"
				src={header}
				type="large center"
				full_children
				center={
					<SearchBox />
				}
			>
				<Instructions />
			</Banner>
			<Suggestions />
			{/* <Parallax /> */}
		</Layout>
	);
};

export default HomeView;

import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CheckBox from '../../../../components/CheckBox';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';
import { setChangeNeeded } from '../../../../redux/change';

const DietaryNeeds: FC = (): JSX.Element => {
    const [ dietaryNeeds, setDietaryNeeds ] = useState<any[]>([]);
    const [ selected, setSelected ] = useState<any[]>([]);
    const [ patchProfile ] = usePatch('/auth/profile');
    const auth = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get('/dietary-needs').then(({ data }) => {
            setDietaryNeeds(data.data);
        });
    }, []);

    useEffect(() => {
		if (!auth || !auth.restaurant) return;
        setSelected(auth.restaurant.dietary_needs
            ? auth.restaurant.dietary_needs
            : []
        );
	}, [auth]);

    useChangeCallback('dietary-needs', (onSuccess) => {
        if (!auth) return;
		toggleFullscreenLoader(true);
		patchProfile({
            restaurant: {
                ...auth.restaurant,
                dietary_needs: selected.map((o) => o.id),
            }
        }).then(({ data }) => {
            toggleFullscreenLoader(false);
            dispatch(updateProfile(data));
            toast({
                header: 'Gelukt!',
                content: 'De beschikbare dieëtwensen zijn ingesteld.',
            });
            if (onSuccess) {
                onSuccess();
            }
        })
        .catch(() => {
            toggleFullscreenLoader(false);
            dispatch(setChangeNeeded('dietary-needs'));
        });
	}, [auth]);

	useChangesCanceled('dietary-needs', () => {
		if (!auth || !auth.restaurant) return;
        setSelected(auth.restaurant.dietary_needs
            ? auth.restaurant.dietary_needs
            : []
        );
	}, [auth]);

    const handleInput = (need: any, state: boolean): void => {
        let newSelected = [ ...selected ];
        
        if (state) {
            newSelected.push(need);
        } else {
            newSelected = newSelected.filter((o) => o.id !== need.id);
        }

        setSelected(newSelected);
        dispatch(setChangeNeeded('dietary-needs'));
    };

    return (<>
		<p className="sv-description">
            Selecteer hieronder welke dieëtwensen en/of allergieën jouw gasten mogen opgeven bij een reservering.
        </p>
        <div style={{ marginTop: 32 }}>
            {dietaryNeeds.map((need: any) => (
                <div style={{ marginBottom: 16 }} key={`need-${need.id}`}>
                    <CheckBox
                        label={need.name}
                        onChange={(v) => handleInput(need, v)}
                        value={selected.map((o) => o.id).includes(need.id)}
                    />
                </div>
            ))}
        </div>
    </>);
}

export default DietaryNeeds;

import React, { useState } from "react";
import "./reset-password-view.scss";
import Card from "../../../components/Card";
import { useParams, useHistory } from "react-router-dom";
import StyledText from "../../../components/StyledText";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import usePost from "../../../hooks/rest/usePost";
import useAlert from "../../../hooks/useAlert";
import useGet from "../../../hooks/rest/useGet";
import { useSelector } from "react-redux";
import { AuthPage } from "../LoginView";

const ResetPasswordView = () => {
	const { token } = useParams() as any;
	return (
		<AuthPage>
			{token ? <ResetForm token={token} /> : <RequestForm />}
		</AuthPage>
	);
};

const ResetForm = ({ token }: { token: string }) => {
	const [sendAlert] = useAlert();
	const history = useHistory();
	const [result, loading, error] = useGet(`/auth/password/find/${token}`);
	const [resetPassword, resetting] = usePost("/auth/password/reset");
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const handleSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (password !== repeatPassword || !password || !repeatPassword) {
			sendAlert({
				type: "error",
				title: "Vul alle velden in",
				text: "Zorg dat de wachtwoorden overeenkomen",
			});
			return;
		}

		if (
			password.length < 8 ||
			!/^(?=.*[0-9])(?=.*[!@#$%^&*.;])[a-zA-Z0-9!@#$%^&*.;]{8,}$/.test(password)
		) {
			sendAlert({
				type: "error",
				title: "Wachtwoord onvoldoende",
				text: "Uw wachtwoord moet minimaal 8 karakters hebben, 1 letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken",
			});
			return;
		}

		resetPassword({
			password,
			token: result.data.token,
			email: result.data.email,
		})
			.then(() => {
				sendAlert({
					type: "success",
					title: "Uw wachtwoord is aangepast",
					text: "Login met uw nieuwe wachtwoord",
				});
				history.push("/login");
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
			});
	};

	if (!loading && error) {
		return (
			<Card type="no-shadow" className="reset-password-view-form">
				<StyledText>Er is iets misgegaan</StyledText>
			</Card>
		);
	}

	return (
		<form onSubmit={handleSubmit}>
			<Input
				type="password"
				value={password}
				onChange={setPassword}
				label="Wachtwoord"
				placeholder="Wachtwoord"
			/>
			<Input
				type="password"
				value={repeatPassword}
				onChange={setRepeatPassword}
				label="Wachtwoord herhalen"
				placeholder="Wachtwoord herhalen"
			/>
			<div className="button-full">
				<span></span>
				<Button noUppercase loading={resetting} onClick={handleSubmit}>
					Wijzigen
				</Button>
			</div>
			<div className="no-account-cta">
				<StyledText>Nog geen partner account?</StyledText>
				<Button to="/subscriptions" type="transparent">
					Word nu partner
				</Button>
			</div>
		</form>
	);
};

export const RequestForm = ({
	noText,
	onlyButton,
}: {
	noText?: boolean;
	onlyButton?: boolean;
}) => {
	const auth = useSelector((state: any) => state.auth);
	const [sendAlert] = useAlert();
	const [email, setEmail] = useState(auth.email || "");
	const [requestPassword, loading] = usePost(`/auth/password/create`);

	const handleSubmit = (e?: any) => {
		if (e) e.preventDefault();
		requestPassword({
			email,
		})
			.then(() => {
				sendAlert({
					type: "success",
					title: "Gelukt!",
					text: "U ontvangt een e-mail met een wijzig link",
				});
				setEmail("");
			})
			.catch(() => {
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
					text: "Probeer een ander emailadres",
				});
			});
	};

	return (
		<form onSubmit={handleSubmit}>
			{!onlyButton && (
				<Input
					value={email}
					type="email"
					placeholder="E-mail"
					label="E-mail"
					onChange={setEmail}
				/>
			)}
			<div className="button-full">
				<Button noUppercase loading={loading} onClick={handleSubmit}>
					Wachtwoord wijzigen
				</Button>
			</div>
			{!noText && !onlyButton && (
				<div className="no-account-cta">
					<StyledText>Nog geen partner account?</StyledText>
					<Button to="/subscriptions" type="transparent">
						Word nu partner
					</Button>
				</div>
			)}
		</form>
	);
};

export default ResetPasswordView;

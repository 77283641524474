import React, { FC, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import Button from '../../../../kit/Button';
import Form from '../../../../kit/Form';
import { toast } from '../../../../kit/NotificationCenter';

const ShareLink: FC<{ onCopy?: () => void }> = ({ onCopy }): JSX.Element => {
    const auth = useAuth();
    const [ url, setUrl ] = useState<string>('');

    useEffect(() => {
        if (!auth || !auth.restaurant) return;
        setUrl(`${window.location.origin}/restaurants/profile/${auth.restaurant.id}/timeslots/now`);
    }, [auth]);

    const handleCopy = (txt: string): void => {
        navigator.clipboard.writeText(txt);
        toast({ content: 'De deel link is gekopieerd naar het klembord.' });
        if (onCopy) {
            onCopy();
        }
    }

	return (<>
		<p className="sv-description">
            Kopieer de onderstaande link en gebruikt deze bijvoorbeeld op social media's. Gasten kunnen hierdoor direct reserven voor jouw restaurant.
        </p>
        <Form.Input
            icon="fad fa-link"
            readOnly
			value={url}
        />
		<Button
			block
            color="green"
            label="Kopieer"
            onClick={() => handleCopy(url)}
			style={{ marginTop: 32 }}
        />
        <Button
			block
            color="grey"
            label="Hulp nodig?"
            href="/dashboard/settings/contact"
        />
    </>);
}

export default ShareLink;

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./nav.scss";
import Logo from "../../Logo";
import NavItem from "./NavItem";
import StyledText from "../../StyledText";
import useBlur from "../../../hooks/useBlur";
import { useSelector } from "react-redux";
import SearchBox from "../../SearchBox";
import Modal from "../../Modal";
import { Socials } from "../../Footer";

const Nav = ({
	isSearch,
	isWhite,
	button,
}: {
	isSearch?: boolean;
	isWhite?: boolean;
	button?: any,
}) => {
	const auth = useSelector((state: any) => state.auth);
	const location = useLocation();
	const [visible, setVisible] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const classes = ["navigation"];
	if (visible) classes.push("navigation-open");
	if (!isSearch) classes.push("navigation-no-search");
	if (isWhite) classes.push("navigation-is-white");
	const subRef = useRef(null);
	useBlur(subRef, () => setVisible(false));
	useEffect(() => {
		setVisible(false);
	}, [location]);

	useEffect(() => {
		setIsScrolled(window.pageYOffset >= 100);
		const handleScroll = (e?: any) => {
			setIsScrolled(window.pageYOffset >= 100);
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const renderBurgerItems = (submenu: boolean) => {
		const items = auth.id
		? [
			{ to: "/for-partners", label: "Waarom EasyZeats" },
			{ to: "/dashboard", label: "Dashboard" },
			{ to: "/subscriptions", label: "Abonnementen" },
			{ to: "/help", label: "Hulp voor partners" },
		]
		: [
			{ to: "/for-partners", label: "Waarom EasyZeats" },
			{ to: "/login", label: "Partner login" },
			{ to: "/subscriptions", label: "Abonnementen" },
			{ to: "/help", label: "Hulp voor partners" },
		];

		if (submenu) {
			return items.map((item: any, index) => (
				<NavLink
					to={item.to}
					className={`${!submenu ? "hide-on-desktop" : ""} sub-item`}
					key={item.label + index + "Submenu"}
				>
					{item.label}
				</NavLink>
			));
		} else {
			return items.map((item: any, index) => (
				<NavItem
					{...{ ...item }}
					className={`${!submenu ? "hide-on-desktop" : ""} sub-item`}
					key={item.label + index}
				/>
			));
		}
	};

	let logo_type = "circle-white";
	if (!isScrolled && isWhite) {
		logo_type = "green-normal";
	}

	return (
		<nav
			className={`${classes.join(" ")} ${
				isScrolled ? "navigation-scrolled" : ""
			}`}
		>
			{isSearch && <NavLocationIcon />}
			<NavLink to="/" className="navigation-logo">
				<Logo type={logo_type} isPrimary={visible} />
			</NavLink>
			{isSearch ? (
				<NavLocationInput />
			) : (
				<div className="navigation-items"></div>
			)}

			<div ref={subRef} className="navigation-bars">
				{button}
				<div
					onClick={(e) => {
						e.stopPropagation();
						setVisible(!visible);
					}}
					className="icon"
				>
					<i className={`fal fa-${visible ? "times" : "bars"}`}></i>
				</div>
			</div>
			<div className="navigation-submenu">
				<div className="navigation-submenu-items">
					<NavLink className="bold sub-item" to={"/contact"}>
						<StyledText type="bold">Contact</StyledText>
					</NavLink>
					<StyledText type="bold">Partners</StyledText>
					{renderBurgerItems(true)}
					<div className="socials">
						<Socials />
					</div>
				</div>
			</div>
		</nav>
	);
};

const NavLocationInput = () => {
	const [visible, setVisible] = useState(false);
	const location = useSelector((state: any) => state.location);

	return (
		<>
			<div
				onClick={() => setVisible(true)}
				className="nav-location-input"
			>
				<StyledText>{location.street}</StyledText>
			</div>
			<Modal
				className="nav-location-modal"
				title="Voer het adres in"
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<SearchBox onClose={() => setVisible(false)} />
			</Modal>
		</>
	);
};

const NavLocationIcon = () => {
	const [visible, setVisible] = useState(false);
	return (
		<>
			<div className="mobile-location-icon">
				<div onClick={() => setVisible(true)} className="icon">
					<i className="fas fa-map-marker-alt"></i>
				</div>
			</div>
			<Modal
				className="nav-location-modal"
				title="Voer het adres in"
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<SearchBox onClose={() => setVisible(false)} />
			</Modal>
		</>
	);
};

export default Nav;

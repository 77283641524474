import React from "react";
import "./restaurant-card-minimal.scss";
import { NavLink } from "react-router-dom";
import StyledText from "../StyledText";
import SlideShow from "../SlideShow/SlideShow";
import Slider from "react-slick";
import default_header from "../../assets/images/default-profile-header.png";

const RestaurantCardMinimal = ({ restaurant }: { restaurant: any }) => {
	const renderMedia = () => {
		if (restaurant?.media?.length) {
			return <SlideShow media={restaurant.media} />;
		} else if (restaurant?.thumbnail?.url) {
			return <img src={restaurant.thumbnail.url} alt="" />;
		}
		return <img src={default_header} alt="" />;
	};

	return (
		<NavLink
			to={`/restaurants/profile/${restaurant.id}/show`}
			className="rcm"
		>
			<div className="rcm-media">{renderMedia()}</div>
			<div className="rcm-info">
				<div className="row">
					<div className="rcm-name">
						<div className="rcm-name-row">
							<StyledText type="title">
								{restaurant.name}
							</StyledText>
							{restaurant.distance && (
								<StyledText className="address-distance">
									{restaurant?.distance || ""} km
								</StyledText>
							)}
						</div>

						<div className="address">
							<StyledText className="address-city">
								<i className="fas fa-map-marker-alt"></i>
								{restaurant?.street || ""} {restaurant?.house_number || ""}, {restaurant?.city || ""}
							</StyledText>
							{restaurant.distance && (
								<StyledText className="address-mobile-distance">
									{restaurant?.distance || ""} km
								</StyledText>
							)}
						</div>
					</div>
					<div className="actions">
						<Count
							isPaused={
								restaurant.is_paused || restaurant?.is_empty
							}
							total={restaurant.spots_available}
						/>
					</div>
				</div>
				{restaurant.timeslots ? (
					<TimeSlider data={restaurant?.timeslots || []} />
				) : (
					false
				)}
			</div>
		</NavLink>
	);
};

const TimeSlider = ({ data }: any) => {
	return (
		<div className="time-slider">
			<Slider
				slidesToShow={3}
				responsive={[
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
						},
					},
				]}
				dots={false}
				infinite={data.length >= 3}
				className={`time-slider-items  ${
					data.length <= 3 ? "time-slider-items_hide_arrow" : ""
				}`}
			>
				{data.length
					? data.map((item: any) => (
							<div key={item.id} className="time-slider-item">
								<span className="start">{item.time.start}</span>
								<span className="end"> - {item.time.end}</span>
							</div>
					  ))
					: false}
			</Slider>
		</div>
	);
};

export const Count = ({
	total,
	isPaused,
}: {
	total?: number;
	isPaused?: boolean;
}) => (
	<div className={`count ${total === 0 && !isPaused ? "count-disable" : ""}`}>
		{isPaused ? (
			<img src={require("../../assets/images/icons/call-white.svg")} alt="" />
		) : (
			<StyledText type="bold">{total}</StyledText>
		)}
	</div>
);

export default RestaurantCardMinimal;

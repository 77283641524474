import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import "./timeslot-picker-v2.scss";
import TimeslotListV2 from "./TimeslotListV2";
import Button from "../Button";
import Form from "./Form";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import ChangeSubscription from '../../views/Account/ChangeSubscription';

import weeks from "../../assets/images/weeks.jpg";
import calendar from "../../assets/images/calendar.jpg";
import StyledText from "../StyledText";
import axios from "axios";
import { setChangeBtnLabel, setChangeNeeded } from "../../redux/change";
import { useChangeCallback, useChangesCanceled } from "../../hooks/useChange";
import { useWindowWidth } from "../../hooks/useWindowWidth";

const TimeslotPickerV2 = () => {
	const auth = useSelector((state: any) => state.auth);
	const { restaurant } = auth;
	const [visible, setVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const history = useHistory();
	const [ slots, setSlots ] = useState<any[]>([]);
	const hide_button = auth.is_free && !auth.is_whitelisted && slots.length === 3;
	const location = useLocation();
	const dispatch = useDispatch();
	const windowWidth = useWindowWidth();
	useEffect(() => {
		setVisible(false);
	}, [location]);

	useEffect(() => {
		if (windowWidth > 992 || location.pathname !== '/dashboard/settings/online/timeslots') return;
		dispatch(setChangeNeeded('add-timeslots'));
		dispatch(setChangeBtnLabel('Toevoegen'));
	}, [dispatch, location, windowWidth]);

	useChangeCallback('add-timeslots', () => {
		if (restaurant.modus === 'freq') {
			history.push('/dashboard/settings/online/timeslots/create/days');
		} else {
			setVisible(true);
		}
	}, [restaurant]);

	useChangesCanceled('add-timeslots', () => {
		history.push('/dashboard/settings/modus');
	}, []);

	const fetch = useCallback(() => {
		if (!restaurant) return;
		setLoading(true);
		axios.get(`/restaurants/${restaurant.id}/timeslots?all=1`).then(({ data }) => {
			setLoading(false);
			setSlots(data.data);
		});
	}, [restaurant]);

	useEffect(() => {
		fetch();

		window.addEventListener('update-profile', fetch, true);
		return () => window.removeEventListener('update-profile', fetch, true);
	}, [fetch]);

	useEffect(() => {
		if (window.location.href.includes('create=1')) {
			setVisible(true);
		}
	}, []);

	return (
		<>
			<Modal
				title="Nieuw tijdvak maken"
				className="tp2-choice-modal"
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<div className="choice-row">
					<div className="col">
						<img src={weeks} alt="" />
						<StyledText>
							Voeg tijdvakken toe en stel het aantal stoelen in op
							basis van weekdagen.
						</StyledText>
						<Button to="/dashboard/settings/online/timeslots/create/days">
							Weekdagen
						</Button>
					</div>
					<div className="col">
						<img src={calendar} alt="" />
						<StyledText>
							Voeg tijdvakken toe en stel het aantal stoelen in
							voor een specifieke datum of datum reeks.
						</StyledText>
						<Button to="/dashboard/settings/online/timeslots/create/date">
							Datum
						</Button>
					</div>
				</div>
			</Modal>
			{windowWidth > 992 && (
				<div className="tp2-button">
					{!loading && (
						hide_button ? (
							<ChangeSubscription buttonLabel="Toevoegen" />
						) : (
							<Button onClick={() => {
								if (restaurant.modus === 'freq') {
									history.push('/dashboard/settings/online/timeslots/create/days');
								} else {
									setVisible(true);
								}
							}}>
								<span>Toevoegen</span>
								<i className="far fa-plus"></i>
							</Button>
						)
					)}
				</div>
			)}
			<div className="tp2" style={ windowWidth <= 992 ? { marginTop: -32 } : {}}>
				<TimeslotListV2 result={{ data: slots }} loading={loading} />
				<div className="tp2-body">
					<Form refetch={fetch} />
				</div>
			</div>
		</>
	);
};

export default TimeslotPickerV2;

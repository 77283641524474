import { parse } from 'qs';
import React, { useEffect, useState } from "react";
import "./restaurants-profile-view.scss";
import Layout from "../../../components/Layout";
import { useParams } from "react-router-dom";
import useGet from "../../../hooks/rest/useGet";
import Banner from "../../../components/Banner";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Map from "../../../components/Map";

import default_header from "../../../assets/images/default-profile-header.png";
import { Count } from "../../../components/RestaurantCard/RestaurantCardMinimal";
import { useSelector } from "react-redux";
import { openDoReservationModal } from "../../../components/DoReservationModal";
import axios from 'axios';

const linkify = (str: string) => {
	var prefix = "http://";
	if (str.substr(0, prefix.length) !== prefix) {
		str = prefix + str;
	}
	return str;
};

const RestaurantsProfileView = () => {
	const params = useParams() as any;
	const restaurantId = params.id;
	const location = useSelector((state: any) => state.location);
	let query = "";
	if (location.lat && location.lng) {
		query = `?lat=${location.lat}&lng=${location.lng}`;
	}
	const [result, loading] = useGet(
		`/restaurants/${restaurantId}${query}`
	);
	const restaurant: any = result?.data || {};
	const url = `https://www.google.com/maps/search/?api=1&query=${restaurant.lat},${restaurant.lng}`;

	useEffect(() => {
		if (!restaurant) return;
		const q = parse(window.location.search.substring(1));
		if (q && q.rid) {
			axios.get(`/reservation/${q.rid}`).then(({ data }) => {
				openDoReservationModal(restaurant.id, data.data, true, undefined, q.action ? q.action === 'cancel' : false);
			});
		}
	}, [restaurant]);

	if (!restaurant) {
		return <div />;
	}

	return (
		<Layout
			footerName="profile-footer"
			className="profile-view-wrapper"
			isSearch
			loading={loading}
		>
			<Banner
				full_children
				src={default_header}
				slides={restaurant?.media}
			>
				<Card className={`profile-view`}>
					<div className="desktop-category">
						<Count
							isPaused={
								restaurant.is_paused || restaurant?.is_empty
							}
							total={restaurant.spots_available}
						/>
					</div>
					<div className="restaurant-logo">
						<div className="profile-view-logo">
							<img
								className="image"
								src={
									restaurant?.thumbnail?.url ||
									require("../../../assets/images/default-logo-restaurant.png")
								}
								alt=""
							/>
							<div className="button-wrapper">
								{!restaurant?.is_empty && (
									<Button
										className="for-desktop"
										type="secondary"
										onClick={() => openDoReservationModal(restaurant.id, undefined, true)}
									>
										Reserveren
									</Button>
								)}

								<div className="for-phone">
									{!restaurant?.is_empty && (
										<Count
											isPaused={
												restaurant.is_paused ||
												restaurant?.is_empty
											}
											total={restaurant.spots_available}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="restaurant-info">
						<div className="col bordered">
							<div className="info-item">
								<div className="icon">
									<i className="fas fa-map-marker-alt"></i>
								</div>
								<p>
									{`${restaurant.street} ${restaurant.house_number}`}
									<br />
									{` ${restaurant.zipcode}, ${restaurant.city}`}
								</p>
							</div>
							{restaurant.telephone ? (
								<div className="info-item for-desktop">
									<div className="icon icon-flip">
										<i className="fas fa-phone"></i>
									</div>
									<a
										rel="noopener noreferrer"
										target="_blank"
										href={`tel: ${restaurant.telephone}`}
									>
										{restaurant.telephone}
									</a>
								</div>
							) : (
								false
							)}
							{restaurant.website ? (
								<div className="info-item">
									<div className="icon">
										<i className="fal fa-globe"></i>
									</div>
									<a
										rel="noopener noreferrer"
										target="_blank"
										href={linkify(restaurant.website)}
									>
										Website
									</a>
								</div>
							) : (
								false
							)}
							<div className="category-phone">
								<StyledText>
									{/* <span className="dot"></span> */}
									{restaurant?.category?.name || ""}
								</StyledText>
							</div>
						</div>

						<div className="col-full">
							<div className="title-row">
								<StyledText type="title">
									{restaurant.name}
								</StyledText>
								{restaurant.distance && (
									<StyledText className="address-distance">
										{restaurant?.distance?.kilometers || ""}{" "}
										km
									</StyledText>
								)}
							</div>
							<ReadMore text={restaurant.description || ""} />
						</div>
						<div className="mobile-cta">
							{restaurant.telephone && (
								<Button
									icon="fas fa-phone"
									isBlank
									type="secondary"
									to={`tel: ${restaurant.telephone}`}
								>
									Bellen
								</Button>
							)}
							{!restaurant?.is_empty && (
								<Button 
									onClick={() => openDoReservationModal(restaurant.id, undefined, true)}
									type="secondary"
								>
									Reserveren
								</Button>
							)}
						</div>
						<div className="route-button">
							<Button isBlank to={url}>
								Bekijk route
							</Button>
						</div>
					</div>
				</Card>
				{restaurant.lat && restaurant.lng && (
					<Map lat={restaurant.lat} lng={restaurant.lng} />
				)}
			</Banner>
		</Layout>
	);
};

const ReadMore = ({ text }: any) => {
	const [visible, setVisible] = useState(text?.length > 100 ? false : true);
	return (
		<div className={`read-more ${visible ? "read-more_visible" : ""}`}>
			<div className="read-more-wrapper">
				<StyledText className="description">{text}</StyledText>
			</div>
			{text?.length > 100 && (
				<div
					onClick={() => setVisible(!visible)}
					className="read-more-button"
				>
					Lees {visible ? "minder" : "meer"}
				</div>
			)}
		</div>
	);
};

export default RestaurantsProfileView;

import qs from 'qs';
import axios from '../../../lib/bootstrap';
import React, { useCallback, useEffect, useState } from "react";
import "./register-view.scss";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import StyledText from "../../../components/StyledText";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import usePost from "../../../hooks/rest/usePost";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateProfile } from "../../../redux/auth";
import CategoryDropdown from "../../../components/Dropdown/CategoryDropdown";
import useAlert from "../../../hooks/useAlert";
import Loading from "../../../components/Loading";
import Logo from "../../../components/Logo";
import StripeWrapper from '../../../lib/StripeWrapper';
import { IdealBankElement, useStripe } from '@stripe/react-stripe-js';

export type PlanType = 'free' | 'monthly' | 'yearly';

const RegisterView = () => {
	const [sendAlert] = useAlert();
	const [isStripeloading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>({});
	const [saved, setSaved] = useState(false);
	const [ isStatus, setIsStatus ] = useState<string|boolean>(false);
	const [ plan, setPlan ] = useState<PlanType>('monthly');
	const dispatch = useDispatch();
	const history = useHistory();
	const stripe = useStripe() as any;
	const [registerRestaurant, loading] = usePost("/auth/signup");
	// const [agreed, setAgreed] = useState(false);
	const [step, setStep] = useState(1);
	const [cancelSubscription] = usePost("/subscriptions/cancel");
	const auth = useSelector((state: any) => state.auth);
	const [restaurant, setRestaurant] = useState({
		name: "",
		street: "",
		house_number: "",
		zipcode: "",
		city: "",
		telephone: "",
		description: "",
		category_id: undefined,
	} as any);

	const [user, setUser] = useState({
		first_name: "",
		affix: "",
		last_name: "",
		email: "",
		telephone: "",
		password: "",
		password_repeat: "",
	});
	const location = useLocation();

	useEffect(() => {
		const query = qs.parse(location.search.substring(1));
		if (query && query.plan) {
			setPlan(query.plan as PlanType);
		}
		if (query && query.status) {
			setIsStatus(query.status as string);
		}
		if (query && query.e) {
			handleUserChange('email', query.e);
		}
	}, [location]); // eslint-disable-line

	const handleRestaurantChange = (key: string, value: any) => {
		setRestaurant({
			...restaurant,
			[key]: value,
		});
	};

	const handleUserChange = (key: string, value: any) => {
		setUser({
			...user,
			[key]: value,
		});
	};

	const runStripe = useCallback((): void => {
		setLoading(true);

		axios.post(`payment/create/${plan}`, { email: user.email })
		.then(({ data }: any) => {
			window.location.href = data.url;
		});
	}, [user, plan]); // eslint-disable-line

	const handleSave = () => {
		if (auth && auth.id) {
			runStripe();
			return
		}

		setSaved(true);
		if (
			!user.first_name ||
			!user.last_name ||
			!user.email ||
			!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,6})+$/.test(user.email) ||
			!user.password ||
			!user.password_repeat
		) {
			sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Vul alle verplichte account gegevens in",
			});
			setStep(1);
			return;
		}
		if (user.password !== user.password_repeat) {
			sendAlert({
				type: "error",
				title: "Wachtwoorden komen niet overeen",
				text: "Uw wachtwoord moet minimaal 8 karakters hebben, 1 letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken",
			});
			setStep(1);
			return;
		}
		if (
			!restaurant.name ||
			!restaurant.category_id ||
			!restaurant.street ||
			!restaurant.house_number ||
			!restaurant.zipcode ||
			!restaurant.street ||
			!restaurant.telephone
		) {
			sendAlert({
				type: "error",
				title: "Er is wat misgegaan",
				text: "Vul alle verplichte restaurant velden in",
			});
			setStep(2);

			return;
		}

		if (
			user.password.length < 8 ||
			!/^(?=.*[0-9])(?=.*[!@#$%^&*.;])[a-zA-Z0-9!@#$%^&*.;]{8,}$/.test(user.password)
		) {
			sendAlert({
				type: "error",
				title: "Wachtwoord onvoldoende",
				text: "Uw wachtwoord moet minimaal 8 karakters hebben, 1 letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken",
			});
			return;
		}

		delete user.password_repeat;
		registerRestaurant({
			...user,
			restaurant,
			plan,
		})
			.then(({ data }: any) => {
				setSaved(false);
				localStorage.setItem('token', data.token);

				if (plan === 'free') {
					dispatch(updateProfile(data));
					history.push("/dashboard");
				} else {
					runStripe();
				}
			})
			.catch((e) => {
				let errors = e?.response?.data?.errors;
				if (errors?.email) {
					sendAlert({
						type: "error",
						title: "Er is al een account met uw email",
					});
				} else {
					sendAlert({
						type: "error",
						title: "Er is iets misgegaan",
					});
				}
			});
	};

	const validate = (newStep: number): void => {
		axios.post('/auth/signup', { ...user, validate: true }).then(() => {
			setStep(newStep);
		}).catch((e) => {
			if (e?.response?.data?.errors) {
				sendAlert({
					type: "error",
					title: "Er is al een account met uw email",
				});
			}
		});
	}

	const cancelAccount = (): void => {
		sendAlert({
			type: "subscription-cancel",
			title: "weet u het zeker?",
			text:
				"Wanneer u annuleert kunnen uw klanten geen reserveringen plaatsen en zullen al uw gegevens worden verwijderd",

			onConfirm: (params: any) => {
				if (params.type === 'free') {
					cancelSubscription({
						toFree: true,
					})
						.then(({ data }) => {
							dispatch(updateProfile(data));
							sendAlert({
								type: "error",
								title: "Uw account is omgezet naar een gratis account",
							});
						})
						.catch((e) => {
							sendAlert({
								type: "error",
								title: "Er is iets misgegaan",
							});
						});
				} else {
					cancelSubscription({})
						.then(() => {
							dispatch(logout());
							history.push("/");
						})
						.catch((e) => {
							sendAlert({
								type: "error",
								title: "Er is iets misgegaan",
							});
						});
				}
			},
		});
	}

	return (
		<div className="register-view">
			<div style={{ padding: '0 20px' }}>
				{isStatus ? (
					<div className="register-view-loading" style={{ justifyContent: 'flex-start' }}>
					<div className="register-grid ws">
						<div className="login-view-logo">
							<Logo to="/" type="circle-white-stacked" />
						</div>
						<StyledText className="register-grid-title">
							{isStatus === 'success' ? 'Gelukt! Laten we snel beginnen' : 'Oeps! er is iets misgegaan met betalen'}
						</StyledText>

						<div className="form">
							<div className="buttons">
								{isStatus === 'success' ? (<>
									<Button
										icon="fas fa-arrow-left"
										type="white"
										to="/"
									>
										Terug naar de website
									</Button>
									<Button
										icon="fas fa-arrow-right"
										type="primary"
										to="/dashboard"
										iconPosition="right"
									>
										Mijn restaurant
									</Button>
								</>) : (<>
									<Button
										icon="fas fa-arrow-left"
										type="white"
										onClick={cancelAccount}
									>
										Annuleren
									</Button>
									<Button
										icon="fas fa-arrow-right"
										type="primary"
										iconPosition="right"
										onClick={() => {
											setIsStatus(false);
											handleSave();
											setUser({
												first_name: auth.first_name,
												affix: auth.affix,
												last_name: auth.last_name,
												email: auth.email,
												telephone: auth.telephone,
												password: "",
												password_repeat: "",
											});
											setRestaurant({
												name: auth.restaurant.name,
												street: auth.restaurant.street,
												house_number: auth.restaurant.house_number,
												zipcode: auth.restaurant.zipcode,
												city: auth.restaurant.city,
												description: auth.restaurant.description,
												category_id: auth.restaurant.category_id,
												telephone: auth.restaurant.telephone,
												website: auth.restaurant.website,
											});
										}}
									>
										Probeer nog een keer
									</Button>
								</>)}
							</div>
						</div>
					</div>
					</div>
				) : (loading || isStripeloading ? (
					<div className="register-view-loading">
						<Loading />
					</div>
				) : (
					<div className="register-grid">
						<div className="login-view-logo">
							<Logo to="/" type="circle-white-stacked" />
						</div>
						<StyledText className="register-grid-title">
							Maak een gratis account aan
						</StyledText>

						<div className="form">
							<div className="acms-steps" style={plan === 'free' ? { maxWidth: 184 } : {}}>
								<div className={`${step >= 1 ? 'active' : ''} ${step > 1 ? 'done' : ''}`}>
									{step > 1 ? <i className="far fa-check"></i> : 1}
								</div>
								<div className={`${step >= 2 ? 'active' : ''} ${step > 2 ? 'done' : ''}`}>
									{step > 2 ? <i className="far fa-check"></i> : 2}
								</div>
								{plan !== 'free' && (
									<div className={`${step >= 3 ? 'active' : ''}`}>
										3
									</div>
								)}
							</div>
							<div className="form-fields">
								{step === 1 && (
									<StepOne
										{...{ restaurant, user, error }}
										saved={saved}
										onChange={handleUserChange}
										plan={plan}
									/>
								)}
								{step === 2 && (
									<StepTwo
										{...{ restaurant, user, error }}
										saved={saved}
										onChange={handleRestaurantChange}
										plan={plan}
									/>
								)}
								{step === 3 && (<>
									<div className="register-info">
										<div>
											<i className="fal fa-info-circle"></i>
										</div>
										<StyledText>Met een iDEAL verificatietransactie van 0,01 euro, wordt je bankrekeningnummer & betaalmethode doorgegeven aan EasyZeats. Je geeft EasyZeats toestemming om na de gratis periode maandelijks € 39,- af te schrijven van je bankrekening.
										</StyledText>
									</div>
									<div style={{ marginBottom: 24 }}>
										<IdealBankElement
											options={{
												style: {
													base: {
														backgroundColor: '#ffffff',
														padding: '15px 30px',
														borderRadius: '5px',
													},
												},
											} as any}
											className="ideal-element"
										/>
									</div>
								</>)}
							</div>
							<div className="buttons">
								{step > 1 ? (
									<Button
										onClick={() => setStep(step - 1)}
										icon="fas fa-arrow-left"
										type="transparent"
									>
										Terug
									</Button>
								) : (
									<span />
								)}
								{step === 1 && (
									<Button
										onClick={() => {
											setError({});

											if (
												!user.first_name ||
												!user.last_name ||
												!user.password ||
												!user.email ||
												!user.password_repeat
											) {
												sendAlert({
													type: "error",
													title:
														"Vul alle verplichte velden in",
												});
												setError({
													first_name: !user.first_name,
													last_name: !user.last_name,
													password: !user.password,
													email: !user.email,
													password_repeat: !user.password_repeat,
												});
												return;
											} else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,6})+$/.test(user.email)) {
												sendAlert({
													type: "error",
													title:
														"Ongeldig e-mailadres",
												});
												setError({email: true});
											} else if (user.password !== user.password_repeat) {
												sendAlert({
													type: "error",
													title: "Wachtwoorden komen niet overeen",
													text: "Uw wachtwoord moet minimaal 8 karakters hebben, 1 letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken",
												});
												setError({password: true});
												return;
											} else if (
												user.password.length < 8 ||
												!/^(?=.*[0-9])(?=.*[!@#$%^&*.;])[a-zA-Z0-9!@#$%^&*.;]{8,}$/.test(user.password)
											) {
												sendAlert({
													type: "error",
													title: "Wachtwoord onvoldoende",
													text: "Uw wachtwoord moet minimaal 8 karakters hebben, 1 letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken",
													delay: 5000,
												});
												setError({password: true});
												return;
											} else {
												validate(2);
											}
										}}
										type="transparent"
										iconPosition="right"
										icon="fas fa-arrow-right"
									>
										Volgende stap
									</Button>
								)}
								{step === 2 && (
									plan === 'free' ? (
										<Button
											loading={loading}
											onClick={handleSave}
											iconPosition="right"
											icon="fas fa-arrow-right"
										>
											Afronden
										</Button>
									) : (
										<Button
											onClick={() => {
												setError({});
												if (
													!restaurant.name ||
													!restaurant.category_id ||
													!restaurant.house_number ||
													!restaurant.zipcode ||
													!restaurant.street ||
													!restaurant.telephone
												) {
													sendAlert({
														type: "error",
														title:
															"Vul alle verplichte velden in",
													});
													setError({
														name: !restaurant.name,
														category_id: !restaurant.category_id,
														house_number: !restaurant.house_number,
														zipcode: !restaurant.zipcode,
														street: !restaurant.street,
														telephone: !restaurant.telephone,
													});
													return;
												} else {
													handleSave();
												}
											}}
											iconPosition="right"
											type="transparent"
											icon="fas fa-arrow-right"
										>
											Afronden
										</Button>
									)
								)}
								{step === 3 && (
									<Button
										loading={loading}
										onClick={handleSave}
										iconPosition="right"
										icon="fas fa-arrow-right"
									>
										Afronden
									</Button>
								)}
							</div>
							{step === 2 && (
								<div className="agree-cta">
									<StyledText>
										Door een account aan te maken gaat u
										automatisch akkoord met de{" "}
										<NavLink to="/terms-and-conditions">
											algemene voorwaarden.
										</NavLink>
									</StyledText>
								</div>
							)}
							<div className="login-cta">
								<StyledText>
									Ik heb al een partner account
								</StyledText>
								<Button type="transparent" to="/login">
									Inloggen
								</Button>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

type StepProps = {
	onChange: (key: string, value: any) => void;
	restaurant?: any;
	user?: any;
	saved?: any;
	error?: any,
	plan: PlanType;
};

const StepOne = ({ onChange, restaurant, user, saved, plan, error }: StepProps) => (
	<>
		<Input
			value={user.first_name}
			onChange={(text) => onChange("first_name", text)}
			type="text"
			placeholder="Voornaam"
			label="Voornaam"
			name="first_name"
			className="full"
			isRequired={saved && !user.first_name}
			error={error.first_name}
		/>
		<Input
			value={user.last_name}
			onChange={(text) => onChange("last_name", text)}
			type="text"
			placeholder="Achternaam"
			label="Achternaam"
			name="last_name"
			className="full"
			isRequired={saved && !user.last_name}
			error={error.last_name}
		/>
		<Input
			value={user.email}
			onChange={(text) => onChange("email", text)}
			type="email"
			placeholder="Emailadres"
			label="Emailadres"
			name="email"
			isRequired={saved && !user.email}
			error={error.email}
		/>
		<Input
			value={user.password}
			onChange={(text) => onChange("password", text)}
			type="password"
			placeholder="Wachtwoord"
			label="Wachtwoord"
			isRequired={saved && !user.password}
			error={error.password}
		/>
		<Input
			value={user.password_repeat}
			onChange={(text) => onChange("password_repeat", text)}
			type="password"
			placeholder="Wachtwoord herhalen"
			label="Wachtwoord herhalen"
			isRequired={saved && !user.password_repeat}
			error={error.password}
		/>
	</>
);

const StepTwo = ({ onChange, restaurant, user, saved, plan, error }: StepProps) => (
	<>
		<Input
			value={restaurant.name}
			onChange={(text) => onChange("name", text)}
			type="text"
			placeholder="Naam restaurant"
			className="full"
			label="Naam restaurant"
			isRequired={saved && !restaurant.name}
			error={error.name}
		/>
		<CategoryDropdown
			isRequired={saved && !restaurant.category_id}
			value={restaurant.category_id}
			onChange={(value) => onChange("category_id", value)}
		/>
		<div className="row">
			<Input
				error={error.street}
				value={restaurant.street}
				onChange={(text) => onChange("street", text)}
				type="text"
				name="street"
				placeholder="Straat"
				className="full"
				isRequired={saved && !restaurant.street}
			/>
			<Input
				error={error.house_number}
				value={restaurant.house_number}
				onChange={(text) => onChange("house_number", text)}
				isRequired={saved && !restaurant.house_number}
				type="text"
				name="house_number"
				placeholder="Nr."
			/>
		</div>
		<div className="row">
			<Input
				error={error.zipcode}
				value={restaurant.zipcode}
				onChange={(text) => onChange("zipcode", text)}
				type="text"
				placeholder="1234 AB"
				isRequired={saved && !restaurant.zipcode}
				name="zipcode"
			/>
			<Input
				error={error.city}
				value={restaurant.city}
				onChange={(text) => onChange("city", text)}
				type="text"
				placeholder="Plaats"
				name="city"
				className="full"
				isRequired={saved && !restaurant.city}
			/>
		</div>
		<Input
			error={error.telephone}
			value={restaurant.telephone}
			onChange={(text) => onChange("telephone", text)}
			type="text"
			placeholder="Telefoonnummer"
			name="telephone"
		/>
		<Input
			error={error.website}
			value={restaurant.website}
			onChange={(text) => onChange("website", text)}
			type="text"
			placeholder="Website"
			name="website"
		/>
	</>
);

const Striped = () => (
	<StripeWrapper>
		<RegisterView />
	</StripeWrapper>
)

export default Striped;

import React, { useState, useEffect, RefObject } from "react";
import Table from "@material-ui/core/Table";
import { find } from 'lodash';
import moment from "moment";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StyledText from "../../../components/StyledText";
import STATUSES from "../../../lib/statuses";
import CheckBox from "../../../components/CheckBox";
import { deleteReservation } from "../../../components/SingleActionModal";
import { openDoReservationModal } from "../../../components/DoReservationModal";
import { useAuth } from "../../../hooks/useAuth";
import { openReservationDetailModal } from "../../../components/ReservationDetailModal";
import './reservations-view.scss';

const ReservationTable = ({
	data,
	loading,
	query,
	type,
	showStatus,
	onSelect,
	onNextPage,
	currentPage,
}: {
	data: any[];
	loading: boolean;
	query?: string;
	type: string,
	showStatus?: any[],
	onSelect?: (selection: number[]) => void,
	onNextPage?: (page: number) => void,
	currentPage?: number,
}) => {
	const [ selection, setSelection ] = useState<number[]>([]);
	const [ filtered, setFiltered ] = useState<any[]>(data);
	const scrollViewRef: RefObject<HTMLDivElement> = React.createRef();
	const auth = useAuth();

	const toggleSelection = (checked: boolean, id?: number): void => {
		if (!id) {
			if (checked) {
				setSelection(filtered.filter(o => o.status !== 'DELETED' || type === 'attention').map(o => o.id));
			} else {
				setSelection([]);
			}
		} else {
			if (checked) {
				setSelection([...selection, id]);
			} else {
				setSelection([...selection.filter(o => o !== id)]);
			}
		}
	}

	useEffect(() => {
		const handleEdit = ({ detail }: any): void => {
			if (detail.multi !== true ) {
                setSelection([]);
            }
		}
		
		window.addEventListener('edit-reservation', handleEdit, true);
		return () => {
			window.removeEventListener('edit-reservation', handleEdit, true);
		}
	}, []);

	useEffect(() => {
		if (onSelect) {
			onSelect(selection);
		}
	}, [selection]); // eslint-disable-line

	useEffect(() => {
		if (!query || query === '') {
			setFiltered(data);
		} else {
			setFiltered(data
				.filter((i) => {
					let q = query?.toLowerCase();
					let email = i?.email || "";
					let name = i?.name || "";

					if (name.toLowerCase().includes(q)) {
						return true;
					}
					if (email.toLowerCase().includes(q)) {
						return true;
					}
					if (
						i.telephone &&
						i.telephone.toLowerCase().includes(q)
					) {
						return true;
					}
					return true;
				}));
			}
	}, [query, data]);

	useEffect(() => {
		const handleScroll = (): void => {
			if (!scrollViewRef.current || loading || !onNextPage) return;
			const rect = scrollViewRef.current.getBoundingClientRect();
			if (rect.bottom - 250 < window.innerHeight) {
				onNextPage((currentPage || 0) + 1);
			}
		}

		window.addEventListener('scroll', handleScroll, true);
		return () => window.removeEventListener('scroll', handleScroll, true);
	}, [scrollViewRef, currentPage, loading, onNextPage]);

	if (!data.length && !loading) {
		return (
			<div className="reservation-table-empty">
				<StyledText>U heeft nog geen reservering</StyledText>
			</div>
		);
	}

	return (<>
		<div className="rt-scrollview" ref={scrollViewRef}>
			<Table className="reservation-table" aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell className="">
							<CheckBox
								onChange={(checked) => toggleSelection(checked)}
								value={filtered.length > 0 && selection.length === filtered.length}
							/>
						</TableCell>
						{type === 'attention' && (
							<TableCell className="">Type</TableCell>
						)}
						<TableCell className="for-mobile ">{showStatus ? 'Type' : 'Tijd'}</TableCell>
						<TableCell className="for-mobile" align="center">Personen</TableCell>
						<TableCell className="for-mobile">Naam</TableCell>
						<TableCell className="collapsing">Contact</TableCell>
						<TableCell className="collapsing" align="center">Zelfde<br />huishouden</TableCell>
						<TableCell className="collapsing">Status</TableCell>
						<TableCell className="for-mobile collapsing">Actie</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filtered.map((row) => {
						const ss: any = showStatus && find(showStatus, { id: row.id });

						return <TableRow key={row.key}>
							<TableCell className="">
								{(type === 'attention' || row.status !== 'DELETED') && <CheckBox
									onChange={(checked) => toggleSelection(checked, row.id)}
									value={selection.includes(row.id)}
								/>}
							</TableCell>
							{type === 'attention' && (
								<TableCell className="">
									{row.statuses?.map((status: any, i: number) => (
										<span className={`row-status`} key={`rs-${i}${row.id}`}>
											{status.status}
										</span>
									))}
								</TableCell>
							)}
							{showStatus ? (
								<TableCell className="">
									{ss && ss.status?.map((status: any, i: number) => (
										<span className={`row-status`} key={`rs-${i}${row.id}`}>
											{status}
										</span>
									))}
								</TableCell>
							): (
								<TableCell
									className="for-mobile"
									// component="th"
									scope="row"
								>
									{auth?.restaurant?.modus === 'freq' || !row.timeslot
										? row.set_timeslot
										: `${row.timeslot.time.start} - ${row.timeslot.time.end}`
									}
									<small className="small-date">{moment(row.date).format('DD-MM-YYYY')}</small>
								</TableCell>
							)}
							<TableCell className="for-mobile" align="center">
								{row?.persons || ""}
							</TableCell>
							<TableCell className="for-mobile">
								{row?.name || "Niet opgegeven"}
							</TableCell>
							<TableCell className="">
								{row?.email && row?.telephone ? (<>
									<span>{row?.email}</span><br />
									<span>{row?.telephone}</span>
								</>) : (row?.email || row?.telephone) ? (<>
									<span>{row?.email || row?.telephone}</span>
								</>) : 'Niet opgegeven'}
							</TableCell>
							<TableCell align="center" className="">
								{row?.is_same_household ? "Ja" : "Nee"}
							</TableCell>
							<TableCell className="">
								<span className={`row-status row-status-${STATUSES[row.status].type}`}>
									{STATUSES[row.status].title}
								</span>
							</TableCell>
							<TableCell className="for-mobile collapsing">
								<div className="actions">
									<div
										onClick={() => openReservationDetailModal(row)}
										className="icon blue"
									>
										<span className="fa-stack">
											<i className="fal fa-circle fa-stack-2x"></i>
											<i className="fas fa-eye fa-stack-1x"></i>
										</span>
									</div>
									{(type === 'attention' || row.status !== 'DELETED') && <>
										<div onClick={() => openDoReservationModal(auth?.restaurant?.id || 0, row)} className="icon yellow">
											<span className="fa-stack">
												<i className="fal fa-circle fa-stack-2x"></i>
												<i className="fas fa-pencil fa-stack-1x"></i>
											</span>
										</div>
										<div onClick={() => deleteReservation([row])} className="icon red">
											<span className="fa-stack">
												<i className="fal fa-circle fa-stack-2x"></i>
												<i className="far fa-times fa-stack-1x asd"></i>
											</span>
										</div>
									</>}
								</div>
							</TableCell>
						</TableRow>
					})}
				</TableBody>
			</Table>
		</div>
	</>);
};

export default ReservationTable;

import React, { FC } from 'react';
import './switch.scss';

interface SwitchV2Props {
    labelOff?: string,
    labelOn?: string,
    onChange: (state: boolean) => void,
    state: boolean,
    width?: number,
}

const SwitchV2: FC<SwitchV2Props> = ({
    labelOff,
    labelOn,
    onChange,
    state,
    width,
}): JSX.Element => {
    return (
        <div onClick={() => onChange(!state)} className="switch-v2">
            <span className={state ? 'on' : 'off'} style={width ? { width, justifyContent: 'center', textAlign: 'center', marginLeft: 0, backgroundColor: '#5a603e' } : {}}>
                {state ? (labelOn || 'Aan') : (labelOff || 'Uit')}
            </span>
            <div className={state ? ' isSelected' : ''} style={width ? { width, justifyContent: 'center' } : {}}>
                {labelOn || 'Aan'}
            </div>
            <div className={!state ? ' isSelected' : ''} style={width ? { width, justifyContent: 'center' } : {}}>
                {labelOff || 'Uit'}
            </div>
        </div>
    );
}

export default SwitchV2;

import React from "react";
import axios from "axios";
import moment from "moment";
import useGet from "../../hooks/rest/useGet";
import useAlert from "../../hooks/useAlert";
import useDelete from "../../hooks/rest/useDelete";
import usePost from "../../hooks/rest/usePost";
import Button from "../../kit/Button";
import Table from "../../kit/Table";
import { toast } from "../../kit/NotificationCenter";

const UsersView = () => {
	const [result, , , refetch] = useGet("/admin/users");
	const data = result.data || [];

	const superLogin = (userId: number): void => {
		axios.post(`/admin/users/${userId}/super-login`).then(({ data }: any) => {
			localStorage.setItem('superToken', data.data.token);
			window.location.href = '/dashboard';
		});
	}

	const deleteUser = (userId: number): void => {
		toast({
			header: 'Weet je het zeker?',
			content: 'Weet je zeker dat je deze gebruiker én bijbehorend restaurant wilt verwijderen? Deze actie is definitief en kan niet ongedaan gemaakt worden.',
			cancelBtn: true,
			approveBtn: 'Ja, ik weet het zeker',
			onApprove: () => {
				axios.delete(`/admin/users/${userId}`).then(() => {
					refetch();
					toast({
						header: 'Gelukt!',
						content: 'Gebruiker en restaurant verwijderd',
					});
				});
			},
		});
	}

	// const typeLabel = (user: any) => {
	// 	if (user.is_whitelisted) {
	// 		return "Gratis toegang";
	// 	} else if (user.is_subscribed) {
	// 		return "Betalende klant";
	// 	} else if (user.is_admin) {
	// 		return "Admin account";
	// 	}
	// 	return "Nog niet verder dan checkout";
	// };

	return (
		<Table>
			<thead>
				<Table.Row>
					<Table.Cell>Naam</Table.Cell>
					<Table.Cell>E-mail</Table.Cell>
					<Table.Cell>Restaurant</Table.Cell>
					<Table.Cell>Aanmaakdatum</Table.Cell>
					<Table.Cell collapsing>Acties</Table.Cell>
				</Table.Row>
			</thead>
			<tbody>
				{data.map((row: any) => (
					<Table.Row key={row.id}>
						<Table.Cell primary name="Naam">
							{row.full_name}
						</Table.Cell>
						<Table.Cell name="E-mail">{row.email}</Table.Cell>
						<Table.Cell name="Restaurant">
							{row?.restaurant?.name || "Geen restaurant"}
						</Table.Cell>
						<Table.Cell name="Aanmaakdatum">
							{moment(row.created_at).format("DD MMMM YYYY")}
						</Table.Cell>
						<Table.Cell actions>
							<div style={{ display: 'flex' }}>
								<WhitelistButton
									{...{ refetch, user: row }}
								/>
								<Button
									onClick={() => superLogin(row.id)}
									tooltip="Inloggen als deze gebruiker"
								>
									<i className="fal fa-lock-alt" />
								</Button>
								<Button
									onClick={() => deleteUser(row.id)}
									tooltip="Gebruiker verwijderen"
								>
									<i className="fal fa-trash-alt" />
								</Button>
							</div>
						</Table.Cell>
					</Table.Row>
				))}
			</tbody>
		</Table>
	);
};

const WhitelistButton = ({ refetch, user }: any) => {
	const [sendAlert] = useAlert();
	const [allowUser] = usePost(`/admin/users/${user.id}/allow`);
	const [denyUser] = useDelete(`/admin/users/${user.id}/deny`);
	let { is_whitelisted } = user;

	let button_type = !is_whitelisted ? "primary" : "white";

	const handleSubmit = () => {
		let text = is_whitelisted
			? "Bij bevestigen heeft de gebruiker geen gratis toegang meer"
			: "Bij bevestigen geef je de gebruiker gratis toegang";
		sendAlert({
			type: "confirm",
			title: "Weet u het zeker",
			text,
			onConfirm: () =>
				is_whitelisted
					? denyUser()
							.then(() => refetch())
							.catch(error)
					: allowUser({})
							.then(() => refetch())
							.catch(error),
		});

		const error = () =>
			sendAlert({
				type: "error",
				title: "Er is iets misgegaan",
			});
	};

	return (
		<Button
			type={button_type}
			onClick={handleSubmit}
			color={is_whitelisted ? 'green' : 'white'}
			tooltip={is_whitelisted ? 'Gratis toegang ontnemen' : 'Gratis toegang geven'}
		>
			{is_whitelisted
				? <i className="far fa-face-disappointed"></i>
				: <i className="fal fa-face-smile"></i>}
		</Button>
	);
};

export default UsersView;

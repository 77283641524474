import React from "react";
import Button from "../../../components/Button";

type PackageProps = {
	isRecommended?: boolean;
	children?: any;
	title: string;
	description: string;
	isMonthly?: boolean;
	button: {
		to?: string;
		disabled?: boolean;
		label: string;
		onClick?: () => void;
	};
	prices: {
		monthly: number;
		yearly: number;
	};
	points?: any[];
};

const Package = ({
	title,
	isRecommended,
	prices,
	isMonthly,
	description,
	button,
	points,
}: PackageProps) => {
	const price = isMonthly ? prices.monthly : prices.yearly;

	return (
		<div className="package">
			{isRecommended && (
				<div className="package-recommended">
					<Button>Aanbevolen</Button>
				</div>
			)}
			<div className="package-head">
				<h3 className="package-title">{title}</h3>
				<p className="price">
					&euro; {price}
					{price !== 0 && (
						<span className="light">
							/ {isMonthly ? "maand" : "jaar"}
						</span>
					)}
				</p>
			</div>
			<div className="package-description">
				<p>{description}</p>
			</div>
			<ul className="package-points">
				{points &&
					points.map((point: any) => (
						<li key={point.title}>
							<div className="icon">
								<i className="fas fa-check"></i>
							</div>
							<div className="info">
								<p
									dangerouslySetInnerHTML={{
										__html: point.title,
									}}
								></p>
								{point.text && <span>{point.text}</span>}
							</div>
						</li>
					))}
			</ul>

			<div className="package-button">
				<Button
					type="secondary"
					// {...{ ...button, to: auth.id ? button.to : `/signup?plan=${isMonthly ? 'monthly' : 'yearly'}` }}
					{ ...{ ...button }}
				>
					{button.label}
				</Button>
			</div>
		</div>
	);
};

export default Package;

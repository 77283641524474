import React from "react";
import "./not-found-view.scss";
import Layout from "../../components/Layout";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";

const NotFoundView = () => {
	return (
		<Layout isSearch>
			<div className="not-found-view">
				<StyledText type="title">Pagina niet gevonden</StyledText>
				<StyledText>
					Het kan zijn dat deze pagina is verwijdert of een login
					vereist is
				</StyledText>
				<Button to="/">Terug naar home</Button>
			</div>
		</Layout>
	);
};

export default NotFoundView;

import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../kit/Button";
import Modal from "../../components/Modal";
import StyledText from "../../components/StyledText";
import usePost from "../../hooks/rest/usePost";
import useAlert from "../../hooks/useAlert";
import { updateProfile } from "../../redux/auth";
import { POINTS } from "../Info/SubscriptionsView";
import Package from "../Info/SubscriptionsView/Package";

interface ChangeSubscriptionProps {
	buttonLabel?: string,
}

const ChangeSubscription: FC<ChangeSubscriptionProps> = ({ buttonLabel }) => {
	const auth = useSelector((state: any) => state.auth);
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();
	const history = useHistory();
	const [changeSub, loading] = usePost("/subscriptions/swap");
	const [visible, setVisible] = useState(false);
	let current_type = "";

	if (auth.is_free) current_type = "free";

	if (auth.current_subscription)
		current_type = auth.current_subscription.type;

	const handleChange = (type: string) => {
		if (type === 'free' && auth.timeslotcount > 3) {
			sendAlert({
				type: "error",
				title: "Eerst nog even wat doen",
				text: `Voor het wijzigen naar een GRATIS versie van EasyZeats, ${auth.timeslotcount - 3 === 1 ? 'moet eerst 1' : `moeten eerst ${auth.timeslotcount - 3}`} van de ${auth.timeslotcount} tijdvakken opgeruimd / verwijderd worden.`,
			});
			return;
		}

		if (type !== "free") {
			history.push(`/checkout/${type}`);
			return;
		}
		changeSub({ plan: type }).then(({ data }) => {
			dispatch(updateProfile(data));
			setVisible(false);
		});
	};

	return (
		<>
			<Modal
				title="Abonnement wijzigen"
				{...{ visible, loading }}
				onClose={() => setVisible(false)}
				type="large"
			>
				<div style={{ marginBottom: 16 }}>
					<StyledText>
						Let op, wil je gebruik maken van de volledige functionaliteit van EasyZeats? Wijzig dan nu je abonnement.
					</StyledText>
				</div>
				<div className="packages">
					<Package
						title="Van het huis"
						description="Eenvoudig bereikbaar en een unieke online ervaring van jouw restaurant"
						prices={{
							monthly: 0,
							yearly: 0,
						}}
						points={POINTS.free}
						button={{
							label:
								current_type === "free"
									? "geselecteerd"
									: "Kiezen",
							// disabled: current_type === "free",
							onClick: () => handleChange("free"),
						}}
					/>
					<Package
						title="Specialiteit"
						description="Geautomatiseerd en eenvoudig reserveringsplatform"
						prices={{
							monthly: 39,
							yearly: 365,
						}}
						points={POINTS.paid}
						button={{
							label:
								current_type === "monthly"
									? "geselecteerd"
									: "Kiezen",
							disabled: current_type === "monthly",
							onClick: () => handleChange("monthly"),
						}}
						isMonthly={true}
					/>
					<Package
						title="Specialiteit"
						description="Geautomatiseerd en eenvoudig reserveringsplatform"
						prices={{
							monthly: 39,
							yearly: 365,
						}}
						points={POINTS.paid}
						button={{
							label:
								current_type === "yearly"
									? "geselecteerd"
									: "Kiezen",
							disabled: current_type === "yearly",
							onClick: () => handleChange("yearly"),
						}}
						isMonthly={false}
					/>
				</div>
			</Modal>
			<Button
				color="green"
				block 
				onClick={() => setVisible(true)}
			>
				{buttonLabel || 'Wijzigen'}
			</Button>
		</>
	);
};

export default ChangeSubscription;

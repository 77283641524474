import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Message from '../../../../kit/Message';
import SwitchV2 from '../../../../components/Switch/SwitchV2';
import useDelete from '../../../../hooks/rest/useDelete';
import usePost from '../../../../hooks/rest/usePost';
import { useAuth } from '../../../../hooks/useAuth';
import { updateProfile } from '../../../../redux/auth';
import { toast } from '../../../../kit/NotificationCenter';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

const PauseReservations: FC = (): JSX.Element => {
    const [ state, setState] = useState<boolean>(true);
    const [ postPause ] = usePost('/pause-restaurant');
	const [ removePause ] = useDelete('/pause-restaurant');
    const auth = useAuth();
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (!auth) return;
        setState(auth.restaurant
            ? auth.restaurant.is_paused === false
            : true
        );
    }, [auth]);

    const success = (newState: boolean): void => {
        if (!auth || !auth.restaurant) return;
        dispatch(updateProfile({
            ...auth,
            restaurant: {
                ...auth.restaurant,
                is_paused: !newState,
            }
        }))
    };

    const handleSwitch = (newState: boolean): void => {
        if (!auth || !auth.restaurant) return;
        setState(newState);

        toast({
            header: 'Gelukt!',
            content: newState
                ? 'U kunt weer reserveringen ontvangen.'
                : 'Uw reserveringen zijn tot morgen gepauzeerd.',
        });

        if (newState) {
            removePause().then(() => success(newState)).catch(() => {});
        } else {
		    postPause({}).then(() => success(newState)).catch(() => {});
        }
    }

    return windowWidth <= 992 ? (<>
        <div className="sv-switch">
            <span className="switch-label" onClick={() => handleSwitch(!state)}>
                Vandaag online Reserveren
            </span>
            <SwitchV2
                onChange={handleSwitch}
                state={state}
            />
        </div>
        {state ? (
            <p className="sv-description">
                Lopen er te veel mensen binnen? Zet voor vandaag dan online reserveren uit. Er kan dan online niet meer gereserveerd worden voor vandaag.
            </p>
        ) : (
            <Message
                content={<span>Er kan vandaag niet meer online gereserveerd worden. Reserveren is weer mogelijk vanaf <b>{moment().add(1, 'days').format('dddd DD MMMM')}</b></span>}
            />
        )}
    </>) : (<>
        <div className="sf-row">
            <div className="sf-row-label">
                Vandaag online Reserveren
            </div>
            <div className="sf-row-description">
                {state ? (
                    <p>
                        Lopen er te veel mensen binnen? Zet voor vandaag dan online reserveren uit. Er kan dan online niet meer gereserveerd worden voor vandaag.
                    </p>
                ) : (
                    <Message
                        content={<span>Er kan vandaag niet meer online gereserveerd worden. Reserveren is weer mogelijk vanaf <b>{moment().add(1, 'days').format('dddd DD MMMM')}</b></span>}
                    />
                )}
            </div>
            <div className="sf-row-action">
                <SwitchV2
                    onChange={handleSwitch}
                    state={state}
                />
            </div>
        </div>
    </>);
}

export default PauseReservations;

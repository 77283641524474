import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import Layout from '../../../components/Layout';
import TabNav from '../../../components/TabNav';
import { useChangeBtnLabel, useChangeNeeded } from '../../../hooks/useChange';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import Button from '../../../kit/Button';
import Modal from '../../../kit/Modal';
import { setChangeNeeded } from '../../../redux/change';
import UsersView from '../../Admin/UsersView';
import DietaryNeeds from './views/DietaryNeeds';
import FourSquareListView from './views/FourSquareListView';
import FourSquareFilterListView from './views/FourSquareFilterListView';
import FourSquareFilterEditView from './views/FourSquareFilterEditView';

const tabs = [{
    to: '/admin/online',
    label: 'Online reserveren',
    isActive: (loc: string) => loc === '/admin' || loc === '/admin/online',
}, {
    to: '/admin/users',
    label: 'Gebruikers',
    isActive: (loc: string) => loc === '/admin/users',
}, {
    to: '/admin/fsq',
    label: 'Foursquare',
    isActive: (loc: string) => loc === '/admin/fsq',
}, {
    to: '/admin/fsq-filters',
    label: 'Foursquare filters',
    isActive: (loc: string) => loc === '/admin/fsq-filters',
}];

const labels: { [key: string]: string } = {
    'dietary-needs': 'Dieëtwensen',
    'fsq': 'Foursquare',
    'create-filter': 'Foursquare filters',
    'edit-filter': 'Foursquare filters',
}

const FullView: FC = (): JSX.Element => {
    const { page, modal, id }: { page: string, modal: string, id: string } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const changeNeeded = useChangeNeeded();
    const changeBtnLabel = useChangeBtnLabel();
    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (windowWidth <= 768) {
            history.push('/admin');
        }
    }, [windowWidth, history]);
    
    const handleClose = (): void => {
        dispatchEvent(new CustomEvent('changes-canceled'));
        dispatch(setChangeNeeded(undefined));
        const back = window.location.pathname.split(`/${modal}`)
        history.push(back[0]);
    }

    return (
        <Layout noLine title="Admin" type="admin">
            <TabNav routes={tabs} />
			<div className="settings-full">
                <Card>
                    {(!page || page === 'online') && (<>
                        <h3 className="sf-title">Online reserveren</h3>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Dieetwensen
                            </div>
                            <div className="sf-row-description">
                                Intolerantie, Vegetarisch of een Allergie? Gasten kunnen tijdens het reserveren eenvoudig de dieetwens aanvinken.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/admin/online/dietary-needs"
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                    </>)}
                    {page === 'users' && (
                        <UsersView />
                    )}
                    {page === 'fsq' && (
                        <FourSquareListView />
                    )}
                    {page === 'fsq-filters' && (
                        <FourSquareFilterListView />
                    )}
                </Card>
                <Modal
                    open={modal !== undefined && modal !== ''}
                    onClose={handleClose}
                    header={labels[modal || ''] || ''}
                    cancelBtn={changeNeeded === undefined
                        ? undefined
                        : 'Annuleren'
                    }
                    approveBtn={changeNeeded === undefined
                        ? undefined
                        : changeBtnLabel
                    }
                    onApprove={changeNeeded === undefined
                        ? undefined
                        : () => dispatchEvent(new CustomEvent('changes-saved', { detail: { onSuccess: handleClose }}))
                    }
                    noButtons={changeNeeded === undefined}
                    preventClose={changeNeeded !== undefined}
                >
                    {modal === 'dietary-needs' && <DietaryNeeds />}
                    {modal === 'create-filter' && <FourSquareFilterEditView />}
                    {modal === 'edit-filter' && id && <FourSquareFilterEditView id={id} />}
                </Modal>
            </div>
		</Layout>
    );
}

export default FullView;

import axios from 'axios';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CheckBox from '../../../../components/CheckBox';
import SwitchV2 from '../../../../components/Switch/SwitchV2';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { toast } from '../../../../kit/NotificationCenter';
import { setChangeBtnLabel, setChangeNeeded } from '../../../../redux/change';
import styles from '../../../Dashboard/ReservationsView/FilterModal/styles.module.scss';
import Form from '../../../../kit/Form';

const BlockPeriod: FC = (): JSX.Element => {
    const [ selection, setSelection ] = useState<number[]>([]);
    const [ active, setActive ] = useState<boolean>(true);
    const [ expired, setExpired ] = useState<boolean>(false);
    const [ startQuery, setStartQuery ] = useState<string>();
    const [ endQuery, setEndQuery ] = useState<string>();
    const [ showRange, setShowRange ] = useState<boolean>(false);
    const [ isRange, setIsRange ] = useState<boolean>(false);
    const [ blocks, setBlocks ] = useState<any[]>();
    const [ forceAdd, setForceAdd ] = useState<boolean>(false);
    const[ type, setType ] = useState<number>(0);
    const auth = useAuth();
    const history = useHistory();
    const dispatch = useDispatch();

    const fetch = useCallback((): void => {
        toggleFullscreenLoader(true);
        axios.get(`/block-periods`).then(({ data }) => {
            setBlocks(data.data);
            toggleFullscreenLoader(false);

            if (data.data.length > 0) {
                dispatch(setChangeNeeded('add-block-period'));
                dispatch(setChangeBtnLabel('Toevoegen'));
                dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
            }
        });
    }, []);

    useEffect(() => {
        if (selection.length <= 0) {
            dispatch(setChangeNeeded('add-block-period'));
            dispatch(setChangeBtnLabel('Toevoegen'));
            dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
        } else {
            dispatch(setChangeNeeded('delete-block-period'));
            dispatch(setChangeBtnLabel('Verwijderen'));
            dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
        }
    }, [selection]);

    useEffect(() => {
        if (!auth || !auth.restaurant) return;
        fetch();
    }, [auth, fetch]);

    useChangeCallback('add-block-period', () => {
        setForceAdd(true);
    });
    useChangesCanceled('add-block-period', () => {
        history.push('/dashboard/settings');
    });

    useChangeCallback('block-period', () => {
        if (!auth) return;
		toggleFullscreenLoader(true);
		axios.post('/block-periods', {
            start_date: startQuery,
            end_date: endQuery,
            type,
        }).then(() => {
            setStartQuery(undefined);
            setEndQuery(undefined);
            setForceAdd(false);
            fetch();
            toggleFullscreenLoader(false);
            dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
        });
	}, [auth, startQuery, endQuery]);

    useChangeCallback('delete-block-period', () => {
        if (!auth) return;
		toggleFullscreenLoader(true);
		axios.post('/block-periods/delete', {
            selection
        }).then(() => {
            setStartQuery(undefined);
            setEndQuery(undefined);
            setForceAdd(false);
            setSelection([]);
            fetch();
            toggleFullscreenLoader(false);
            dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
        });
	}, [auth, startQuery, endQuery]);

	useChangesCanceled('block-period', () => {
		if (!auth || !auth.restaurant) return;
        setStartQuery(undefined);
        setEndQuery(undefined);
        setForceAdd(false);
        dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
	}, [auth]);

    useChangesCanceled('delete-block-period', () => {
		if (!auth || !auth.restaurant) return;
        setStartQuery(undefined);
        setEndQuery(undefined);
        setForceAdd(false);
        setSelection([]);
        dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
	}, [auth]);

    const handleRange = (dates: any): void => {
        if (isRange) {
            const s = moment(dates[0]).format('YYYY-MM-DD');
            const e = moment(dates[1]).format('YYYY-MM-DD');
            
            setStartQuery(s);
            setEndQuery(s > e ? s : e);
        } else {
            setStartQuery(moment(dates).format('YYYY-MM-DD'));
            setEndQuery(undefined);
        }
        setShowRange(false);
        dispatch(setChangeNeeded('block-period'));
    }

    const handleSelection = (id: number, state: boolean): void => {
        let newSelection = [ ...selection ];
        if (state) {
            newSelection.push(id);
        } else {
            newSelection = newSelection.filter((o, i) => o !== id);
        }
        setSelection(newSelection);
    }

    return !forceAdd && blocks && blocks.length > 0 ? (<>
        <p className="sv-description">
            Stel specifieke dagen of periodes in waarop je beschikbaar bent voor online reserveringen, naast je reguliere openingstijden, zodat jouw gasten gemakkelijk kunnen plannen.
        </p>
        <div className="block-period-list">
            <div className="bpl-section">
                <div
                    className="bpl-section-header"
                    onClick={() => setActive(!active)}
                >
                    <div>
                        <i className={`far fa-chevron-${active ? 'down' : 'right'}`} />
                        <span>Actief</span>
                    </div>
                    <span>({blocks.filter((o) => !o.is_expired).length})</span>
                </div>
                <div className={`bpl-section-content ${active ? 'open' : ''}`}>
                    <div style={{ height: 16 }} />
                    {blocks.filter((o) => !o.is_expired).map((d) => (
                        <div className="bpl-item" key={`item-${d.id}`}>
                            <div
                                className="bpl-item-border"
                                style={d.type === 0 ? { backgroundColor: '#BC0000' } : {}}
                            />
                            <div className="bpl-item-icon">
                                <i className="far fa-calendar-day" />
                            </div>
                            <div className="bpl-item-date">
                                {d.formatted}
                            </div>
                            <div className="bpl-item-checkbox">
                                <CheckBox
                                    onChange={(value) => handleSelection(d.id, value)}
                                    value={selection.includes(d.id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {blocks.filter((o) => o.is_expired).length > 0 && (
                <div className="bpl-section expired">
                    <div
                        className="bpl-section-header"
                        onClick={() => setExpired(!expired)}
                    >
                        <div>
                        <i className={`far fa-chevron-${expired ? 'down' : 'right'}`} />
                            <span>Verlopen</span>
                        </div>
                        <span>({blocks.filter((o) => o.is_expired).length})</span>
                    </div>
                    <div className={`bpl-section-content ${expired ? 'open' : ''}`}>
                        <div style={{ height: 16 }} />
                        {blocks.filter((o) => o.is_expired).map((d) => (
                            <div className="bpl-item" key={`item-${d.id}`}>
                                <div className="bpl-item-border" />
                                <div className="bpl-item-icon">
                                    <i className="far fa-calendar-day" />
                                </div>
                                <div className="bpl-item-date">
                                    {d.formatted}
                                </div>
                                <div className="bpl-item-checkbox">
                                    <CheckBox
                                        onChange={(value) => handleSelection(d.id, value)}
                                        value={selection.includes(d.id)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    </>) : (<>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SwitchV2
                labelOff="Periode"
                labelOn="Dag"
                onChange={() => setIsRange(!isRange)}
                state={!isRange}
                width={74}
            />
        </div>

		<div style={{ marginTop: 32 }}>
            <div
                className={styles.periodRange}
                onClick={() => setShowRange(!showRange)}
            >
                <div>
                    <div
                        className={startQuery ? '' : styles.placeholder}
                        style={isRange ? { fontWeight: 100 } : { fontWeight: 100, margin: 0 }}
                    >
                        {startQuery
                            ? moment(startQuery).format('DD-MM-YYYY')
                            : (isRange ? 'Begindatum' : 'Kies een dag')}
                        <i className={`far fa-calendar-${startQuery ? 'xmark' : 'day'}`} />
                    </div>
                </div>
                {isRange && (
                    <div>
                        <div
                            className={endQuery ? '' : styles.placeholder}
                            style={{ fontWeight: 100 }}
                        >
                            {endQuery
                                ? moment(endQuery).format('DD-MM-YYYY')
                                : 'Einddatum'
                            }
                            <i className={`far fa-calendar-${endQuery ? 'xmark' : 'day'}`} />
                        </div>
                    </div>
                )}
            </div>
            {showRange && (
                <div style={{ marginBottom: 16 }}>
                    <Calendar
                        className="mw-calendar"
                        locale="nl-NL"
                        nextLabel={<i className="far fa-chevron-right" />}
                        next2Label={null}
                        onChange={handleRange}
                        prevLabel={<i className="far fa-chevron-left" />}
                        prev2Label={null}
                        selectRange={isRange}
                        minDate={new Date()}
                        value={isRange
                            ? [moment(startQuery).toDate(), moment(endQuery || startQuery).toDate()]
                            : moment(startQuery).toDate()
                        }
                    />
                </div>
            )}
            <div style={{ paddingBottom: 80 }}>
                <Form.Dropdown
                    required
                    onChange={({ value }: any) => setType(value)}
                    options={[{
                        text: 'Dichtzetten',
                        value: 0,
                    }, {
                        text: 'Openzetten',
                        value: 1,
                    }]}
                    value={type}
                />
            </div>
        </div>
    </>);
}

export default BlockPeriod;

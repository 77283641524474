import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import Form from '../../../../kit/Form';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';
import { setChangeNeeded } from '../../../../redux/change';

const AccountInfo: FC = (): JSX.Element => {
    const auth = useAuth();
	const dispatch = useDispatch();
	const [ errors, setErrors ] = useState<any>({});
	const [ patchProfile ] = usePatch('/auth/profile?validate=1');
	const [ user, setUser ] = useState<any>({});

	useChangeCallback('account-info', (onSuccess) => {
		setErrors({});
		toggleFullscreenLoader(true);
		patchProfile(user)
			.then(({ data }) => {
				toggleFullscreenLoader(false);
				dispatch(updateProfile(data));
				toast({
					header: 'Gelukt!',
					content: 'Uw account is aangepast.',
				});
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch((err) => {
				toggleFullscreenLoader(false);
				dispatch(setChangeNeeded('account-info'));
				setErrors(err.response.data.errors);
			});
	}, [user]);

	useChangesCanceled('account-info', () => {
		setErrors({});
		setUser({
            first_name: auth?.first_name || '',
            affix: auth?.affix || '',
            last_name: auth?.last_name || '',
            telephone: auth?.telephone || '',
            email: auth?.email || '',
        });
	}, [auth]);

    useEffect(() => {
        setUser({
            first_name: auth?.first_name || '',
            affix: auth?.affix || '',
            last_name: auth?.last_name || '',
            telephone: auth?.telephone || '',
            email: auth?.email || '',
        });
    }, [auth]);

	const handleInput = ({ name, value }: any) => {
		dispatch(setChangeNeeded('account-info'));
		setUser({
			...user,
			[name]: value,
		});
	};

    return (<>
        <p className="sv-description">
            Hier staan persoonlijke gegevens waarmee jij geregistreerd staat en tevens de contactgegevens voor EasyZeats.
        </p>
        <Form.Input
			error={errors.first_name}
			name="first_name"
			onChange={handleInput}
			placeholder="Voornaam"
			value={user.first_name}
        />
		<Form.Input
			name="affix"
			onChange={handleInput}
			placeholder="Tussenvoegsel"
			value={user.affix}
        />
		<Form.Input
			error={errors.last_name}
			name="last_name"
			onChange={handleInput}
			placeholder="Achternaam"
			value={user.last_name}
        />
		<Form.Input
			error={errors.email}
			name="email"
			onChange={handleInput}
			placeholder="E-mailadres"
			value={user.email}
        />
		<Form.Input
			error={errors.telephone}
			name="telephone"
			onChange={handleInput}
			placeholder="Telefoonnummer"
			value={user.telephone}
        />
    </>);
}

export default AccountInfo;

import React, { FC } from 'react';
import { ApiReservation } from '../../../../api';
import CheckBox from '../../../../components/CheckBox';
import { openReservationDetailModal } from '../../../../components/ReservationDetailModal';
import STATUSES from '../../../../lib/statuses';
import styles from './styles.module.scss';

interface ReservationCardProps {
    onSelect?: (checked: boolean, reservation: ApiReservation) => void, 
    reservation: ApiReservation,
    selection?: number[],
}

const ReservationCard: FC<ReservationCardProps> = ({
    onSelect,
    reservation,
    selection,
}): JSX.Element => {
    return (
        <div className={styles.container} onClick={() => openReservationDetailModal(reservation)}>
            <div className={`${styles.stats} ${styles[`status${reservation.status.toLocaleLowerCase()}`]}`}>
                <div className={styles.innerStats}>
                    <div>
                        <i className="fad fa-users" />
                    </div>
                    <div>
                        {reservation.persons || 0}
                    </div>
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.name}>
                    <span className={styles.truncate}>
                        {reservation.name || 'Niet opgegeven'}
                    </span>
                    {reservation.status !== 'NEW' ? (
                        <span style={{ color: STATUSES[reservation.status].color }}>
                            {STATUSES[reservation.status].title}
                        </span>
                    ) : <span />}
                </div>
                <div className={styles.time}>
                    <i className="fal fa-clock" />
                    {reservation.timeslot && reservation.timeslot.modus === 'slot'
                        ? `${reservation.timeslot.time.start} - ${reservation.timeslot.time.end}`
                        : reservation.set_timeslot
                    }
                </div>
            </div>
            {onSelect && reservation.status !== 'DELETED' &&  (
                <div
                    className={styles.checkbox}
                    onClick={(e) => e.stopPropagation()}
                >
                    <CheckBox
                        onChange={(checked) => onSelect(checked, reservation)}
                        value={(selection || []).includes(reservation.id)}
                    />
                </div>
            )}
        </div>
    );
}

export default ReservationCard;

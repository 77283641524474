import React from "react";
import GoogleMapReact from "google-map-react";
import "./map.scss";
const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY as string;

const Marker = (props: any) => (
	<div className="marker">
		<i className="fas fa-map-marker-alt"></i>
	</div>
);

const Map = ({ lat, lng }: any) => {
	let location = {
		lat: parseFloat(lat),
		lng: parseFloat(lng),
	};
	return (
		<div className="map">
			<div className="map-overlay"></div>
			<GoogleMapReact
				bootstrapURLKeys={{ key: GOOGLE_KEY }}
				defaultCenter={location}
				defaultZoom={11}
			>
				<Marker lat={location.lat} lng={location.lng} />
			</GoogleMapReact>
		</div>
	);
};

export default Map;

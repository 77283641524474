import axios from 'axios';
import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Button from "../Button";
import Card from "../Card";
import StyledText from "../StyledText";
import { default as KitForm } from '../../kit/Form';
import Days from "./Days";
import Input from "../Input";
import usePost from "../../hooks/rest/usePost";
import usePatch from "../../hooks/rest/usePatch";
import moment from "moment";
import useDelete from "../../hooks/rest/useDelete";
import { useDispatch, useSelector } from "react-redux";
import useGet from "../../hooks/rest/useGet";
import Loading from "../Loading";
import ActionModal from "./ActionModal";
import { toast } from '../../kit/NotificationCenter';
import Message from '../../kit/Message';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { setChangeBtnLabel, setChangeNeeded } from '../../redux/change';
import { useChangeCallback, useChangesCanceled } from '../../hooks/useChange';

const EMPTY_TIMESLOT = {
	date: {
		start: "",
		end: "",
	},
	time: {
		start: "",
		end: "",
	},
	category: 'lunch',
	comment: "",
	capacity: "",
	days: [],
	frequency: 15,
	futureReservations: 0,
};

const Form = ({ refetch }: { refetch?: any }) => {
	const auth = useSelector((state: any) => state.auth);
	const { restaurant } = auth;
	const { id, type: routeType } = useParams() as any;
	const [saved, setSaved] = useState(false);
	const [ needFrequency, setNeedFrequency ] = useState<boolean>(false);
	const [fetchedAffected, setFetchedAffected] = useState<any[]>([]);
	const [showActionModal, setShowActionModal ] = useState<boolean>(false);
	const [actionModalType, setActionModalType ] = useState<'delete' | 'edit'>('delete');
	const [deleteTimeslot, loadingDelete] = useDelete(
		restaurant ? `/restaurants/${restaurant.id}/timeslots/${id}` : undefined
	);
	const [result, resultLoading, , refetchTimeslot] = useGet(
		restaurant && id ? `/restaurants/${restaurant.id}/timeslots/${id}` : undefined
	);
	const [patchTimeslot, loadingPatch] = usePatch(
		restaurant ? `/restaurants/${restaurant.id}/timeslots/${id}` : undefined
	);
	const history = useHistory();
	const windowWidth = useWindowWidth();
	const dispatch = useDispatch();
	const [createTimeslot, loading] = usePost(`/restaurants/my/timeslots`);
	const [type, setType] = useState(routeType || "days");
	const location = useLocation();
	const [timeslot, setTimeslot] = useState({
		...EMPTY_TIMESLOT,
	} as any);

	useEffect(() => {
		if (windowWidth > 992) return;
		dispatch(setChangeNeeded('delete-timeslots'));
		dispatch(setChangeBtnLabel('Verwijderen'));
	}, [dispatch, location, windowWidth]);

	useChangesCanceled('save-timeslots', () => {
		history.push('/dashboard/settings/online/timeslots');
	});
	useChangesCanceled('delete-timeslots', () => {
		history.push('/dashboard/settings/online/timeslots');
	});

	useChangeCallback('save-timeslots', () => {
		handleSubmit();
	});
	useChangeCallback('delete-timeslots', () => {
		handleDelete();
	});

	useEffect(() => {
		setNeedFrequency(auth.restaurant && auth.restaurant.modus === 'freq');
	}, [auth]);

	useEffect(() => {
		setType(routeType || "days");
		setTimeslot((t: any) => t.id ? t : { ...EMPTY_TIMESLOT });
	}, [routeType]);

	useEffect(() => {
		if (id && result.data) {
			let t = result.data || {};
			if (t.date.start && t.date.end) {
				setType("date");
			} else {
				setType("days");
			}
			setTimeslot({
				capacity: t.capacity,
				date: {
					start: t.date.start
						? moment(t.date.start, "DD-MM-YYYY").format(
								"YYYY-MM-DD"
						  )
						: "",
					end: t.date.end
						? moment(t.date.end, "DD-MM-YYYY").format("YYYY-MM-DD")
						: "",
				},
				time: {
					start: t.time.start,
					end: t.time.end,
				},
				comment: t.comment,
				days: t.days_indexs,
				frequency: t.frequency,
				category: t.category,
				futureReservations: t.futureReservations,
			});
		}
	}, [result, id]);

	const clear = () => {
		setTimeslot({ ...EMPTY_TIMESLOT });
	};

	const handleSubmit = (force: boolean = false) => {
		setSaved(true);
		let { time, date, days, capacity, comment, frequency, category } = timeslot;
		if (date.start && date.end) {
			if (
				moment(date.start).unix() > moment(date.end).unix() &&
				!days.length
			) {
				return;
			}
		}

		if (!capacity || !time.start || !time.end || (needFrequency && !frequency)) {
			toast({
				type: "error",
				header: "Oeps!",
				content: "Controleer of u de velden heeft ingevuld",
				onTop: true,
			});
			return;
		}
		let data = {
			days: days.length ? days : null,
			capacity: Number(capacity),
			frequency,
			category,
			start_time: moment(time.start).format("HH:mm"),
			end_time: moment(time.end).format("HH:mm"),
			comment,
			start_date: null,
			end_date: null,
		};

		setSaved(false);

		if (id) {
			if (date.start && date.end) {
				data.start_date =
					date.start !== result.data.start_date ? date.start : null;
				data.end_date =
					date.end !== result.data.end_date ? date.end : null;
			}

			axios.post(`/restaurants/${restaurant.id}/timeslots/${id}/check-changes`, data).then((res) => {
				if (res.data.length <= 0 || force) {
					setFetchedAffected([]);
					patchTimeslot(data)
						.then(() => {
							toast({
								header: "Gelukt!",
								content: 'Uw tijdvak is aangepast',
								onTop: true,
							});

							dispatchEvent(new CustomEvent('update-profile'));
							history.push("/dashboard/settings/online/timeslots");
							if (refetch) {
								refetch();
							}
						})
						.catch(handleError);
				} else {
					setFetchedAffected(res.data);
					setActionModalType('edit');
					setShowActionModal(true);
				}
			});
		} else {
			if (date.start && date.end) {
				data.start_date = date.start;
				data.end_date = date.end;
			}

			createTimeslot(data)
				.then(() => {
					toast({
						header: 'Gelukt!',
						content: 'Uw tijdvak is aangemaakt',
						onTop: true,
					});
					dispatchEvent(new CustomEvent('update-profile'));
					history.push("/dashboard/settings/online/timeslots");
					if (refetch) {
						refetch();
					}

					clear();
				})
				.catch(handleError);
		}
	};

	const handleError = (e: any) => {
		const errors = e?.response?.data.errors;
		let content = 'Probeer het opnieuwe';
		if (errors?.already_filled) {
			content = 'Er is al een tijdvak op deze dag + tijd';
		} else if (errors?.category) {
			content = 'Er is al een tijdvak met deze categorie';
		}

		toast({
			type: 'error',
			header: 'Er is iets misgegaan',
			onTop: true,
			content,
		})
	};

	const handleDelete = (ts?: any, force: boolean = false) => {
		const useMe = ts || result.data;
		if (useMe.futureReservations === 0 || force) {
			toast({
				onTop: true,
				header: "Let op",
				content: "Weet u zeker dat u dit tijdsvak wilt verwijderen",
				cancelBtn: 'Annuleren',
				onApprove: () => {
					deleteTimeslot(timeslot).then(() => {
						dispatchEvent(new CustomEvent('update-profile'));
						history.push("/dashboard/settings/online/timeslots");
					}).catch(() => {
						toast({
							type: 'error',
							header: 'Er is iets misgegaan',
							onTop: true,
						});
					});
				}
			});
		} else {
			setActionModalType('delete');
			setShowActionModal(true);
		}
	}

	const handleChange = (key: string, value: any) => {
		if (key === 'time') {
			let { end, start } = value;
			const beginningTime = moment(start, "HH:mm");
			const endTime = moment(end, "HH:mm");
			if (endTime.isBefore(beginningTime)) {
				toast({
					type: 'error',
					header: 'Er is iets misgegaan',
					content: 'Eind tijden mogen nooit voor de begintijd zijn',
					onTop: true,
				});
			} else {
				setTimeslot({
					...timeslot,
					[key]: value,
				});
			}
		} else {
			if (key === 'capacity' && auth.is_free && !auth.is_whitelisted && !auth.is_subscribed && value > 5) {
				value = 5;
			}

			setTimeslot({
				...timeslot,
				[key]: value,
			});
		}

		dispatch(setChangeNeeded('save-timeslots'));
		dispatch(setChangeBtnLabel('Opslaan'));
	};

	if (!restaurant || (id && resultLoading)) {
		return <Loading />;
	}

	if (!routeType && !id) {
		return <div className="tp2-form"></div>;
	}

	return (<>
		{/* <div className="tp2-form-buttons-mobile">
			<Button type="white-shadow" menu>
				<a href="/dashboard/settings/online/timeslots">
					<i className="fas fa-arrow-left"></i>
					<span>Annuleren</span>
				</a>
				{id && (
					<a href="/" onClick={(e) => {e.preventDefault(); handleDelete(); }}>
						<i className="fas fa-trash-alt"></i>
						<span>Verwijderen</span>
					</a>
				)}
				<a href="/" onClick={(e) => {e.preventDefault(); handleSubmit(); }}>
					<i className="fas fa-save"></i>
					<span>Opslaan</span>
				</a>
			</Button>
		</div> */}
		<div className="tp2-form-buttons">
			<Button
				className="tp2l"
				type="white-shadow"
				to="/dashboard/settings/online/timeslots"
			>
				<i className="fas fa-arrow-left"></i>
				<span>Annuleren</span>
			</Button>
			<div>
				{id && (
					<Button
						className="tp2l"
						onClick={() => handleDelete()}
						loading={loadingDelete}
						type="white-shadow"
						isDelete
					>
						<span>Verwijderen</span>
						<i className="fas fa-trash-alt"></i>
					</Button>
				)}
				<Button
					className="tp2l"
					type="white-shadow"
					onClick={() => handleSubmit()}
					loading={loading || loadingPatch}
				>
					<span>Opslaan</span>
					<i className="fas fa-save"></i>
				</Button>
			</div>
		</div>
		<Card className="tp2-form">
			<div className="tp2-form-type">
				<StyledText className="tp2-form-title">
					{restaurant.modus === 'freq' ? 'Categorie' : 'Type'}
				</StyledText>
				<div className="row type-row">
					<div className="item">
						{restaurant.modus === 'freq' ? (
							<KitForm.Dropdown
								options={['Ontbijt', 'Brunch', 'Lunch', 'Diner'].map((o) => ({
									text: o,
									value: o.toLowerCase(),
								}))}
								value={timeslot.category}
								onChange={({ value }: any) => handleChange('category', value)}
							/>
						) : (
							<KitForm.Dropdown
								options={[
									{ value: "days", text: "Weekdagen" },
									{ value: "date", text: "Datum" },
								]}
								value={type}
								onChange={(t) => {
									setType(t.value);
									if (t.value === "days")
										setTimeslot({ ...timeslot, date: {} });
									if (t.value === "date")
										setTimeslot({ ...timeslot, days: [] });
									dispatch(setChangeNeeded('save-timeslots'));
									dispatch(setChangeBtnLabel('Opslaan'));
								}}
							/>
						)}
					</div>
					<div className="item">
						<div className="type">
							{type === "days" ? (
								<Days
									type="primary"
									onChange={(days) => handleChange("days", days)}
									activeIndexs={timeslot.days}
								/>
							) : (
								<div className="date-row row">
									<Input
										label="van"
										showLabel={true}
										value={timeslot?.date?.start}
										onChange={(start) => {
											handleChange("date", {
												...timeslot.date,
												start,
											});
										}}
										date
										isRequired={
											saved &&
											!timeslot?.date?.start &&
											type !== "days"
										}
									/>
									<Input
										label="tot"
										showLabel={true}
										value={timeslot?.date?.end}
										onChange={(end) =>
											handleChange("date", {
												...timeslot.date,
												end,
											})
										}
										date
										isRequired={
											saved &&
											!timeslot?.date?.end &&
											type !== "days"
										}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="item">
					<StyledText className="tp2-form-title">Tijdvak</StyledText>
					<div className="date-row">
						<div className="row" style={{ marginBottom: 10 }}>
							<div className="time-row">
								Start
							</div>
							<Input
								showLabel
								value={timeslot.time.start}
								onChange={(start) => {
									handleChange("time", {
										...timeslot.time,
										start,
									});
								}}
								time
								isRequired={saved && !timeslot.time.start}
							/>
						</div>
						<div className="row">
							<div className="time-row">
								Eind
							</div>
							<Input
								showLabel
								value={timeslot.time.end}
								onChange={(end) =>
									handleChange("time", { ...timeslot.time, end })
								}
								isRequired={saved && !timeslot.time.end}
								time
							/>
						</div>
					</div>

					{needFrequency && (
						<div style={{ marginTop: 32 }}>
							<StyledText className="tp2-form-title">Frequentie</StyledText>
							<div className="tp2-form-freq">
								{[15, 30, 45, 60].map((f) => (
									<div
										key={f}
										className={`tp2-form-freq-option ${f === timeslot.frequency ? 'tp2-form-freq-option-active' : ''}`}
										onClick={() => handleChange('frequency', f)}
									>
										{f}
									</div>
								))}
							</div>
						</div>
					)}

					<div style={{ marginTop: 32 }}>
						<StyledText className="tp2-form-title">Stoelen</StyledText>
						<Input
							type="number"
							value={timeslot.capacity}
							max={auth.is_free && !auth.is_whitelisted && !auth.is_subscribed ? 5 : undefined}
							placeholder="0"
							isRequired={saved && !timeslot.capacity}
							increments
							onChange={(val) => handleChange("capacity", val)}
						/>
					</div>
				</div>
				<div className="item item-note" style={{ flex: 1 }}>
					<StyledText className="tp2-form-title">Notitie</StyledText>
					{/* <Input
						multiline
						placeholder="Opmerking of notitie voor jouw collega"
						value={timeslot.comment}
						onChange={(comment) => handleChange("comment", comment)}
					/> */}
					<KitForm.Textarea
						name="comment"
						placeholder="Opmerking of notitie voor jouw collega"
						onChange={({ value }: any) => handleChange("comment", value)}
						value={timeslot.comment || ''}
						autoGrow
					/>
				</div>
			</div>
			<div className="item">
				{needFrequency && timeslot.time.start && timeslot.time.end && (
					<Message
						content={`Online kunnen gasten nu elke ${timeslot.frequency || 15} minuten reserveren tussen ${timeslot.time.start.format ? timeslot.time.start.format('HH:mm') : timeslot.time.start} & ${timeslot.time.end.format ? timeslot.time.end.format('HH:mm') : timeslot.time.end}.  Er kunnen in totaal elke ${timeslot.frequency} minuten ${timeslot.capacity} stoelen gereserveerd worden.`}
					/>
				)}
			</div>
		</Card>
		{id && result.data && result.data.futureReservations > 0 && <ActionModal
			visible={showActionModal}
			onClose={(rerun) => {
				setShowActionModal(false);
				if (refetchTimeslot && rerun) {
					if (actionModalType === 'delete') {
						toast({
							header: 'Gelukt!',
							content: 'Uw tijdvak is verwijderd',
							onTop: true,
						});
						history.push('/dashboard/settings/online/timeslots');
					} else {
						setTimeout(() => handleSubmit(true), 2010);
					}
				}
			}}
			fetchedAffected={fetchedAffected}
			type={actionModalType}
			timeslot={result.data}
		/>}
	</>);
};

export default Form;

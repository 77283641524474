import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import "./admin-nav.scss";
import Logo from "../../Logo";
import StyledText from "../../StyledText";
import { motion } from "framer-motion";
import ToggleButton from "./ToggleButton";
import useBlur from "../../../hooks/useBlur";

const transition = {
	duration: 0.4,
	delay: 0.2,
	ease: "easeInOut",
};

const variants = {
	open: {
		x: 0,
		transition,
	},
	closed: {
		x: "-100vw",
		transition,
	},
};

const AdminNav = ({ title }: { title?: string }) => {
	const { is_admin, is_whitelisted, is_subscribed, action_needed } = useSelector(
		(state: any) => state.auth
	);
	const [expanded, setExpanded] = useState(false);
	const location = useLocation();
	const [visible, setVisible] = useState(false);
	const [ viewMode, setViewMode ] = useState('list');
	const navRef = useRef(null);
	useBlur(navRef, () => setVisible(false));
	useEffect(() => setVisible(false), [location]);

	useEffect(() => {
		const handleViewMode = (): void => {
			const mode = localStorage.getItem('view-mode');
			if (mode) setViewMode(mode);
		}
		handleViewMode();
		window.addEventListener('nav-view-mode', handleViewMode, true);
		return () => window.removeEventListener('nav-view-mode', handleViewMode, true);
	}, []);

	const handleLogout = () => {
		setVisible(false);
		if (localStorage.getItem('superToken')) {
			localStorage.removeItem('superToken');
			window.location.href = '/admin/users';
		} else {
			localStorage.removeItem('token');
			window.location.href = '/';
		}
	};

	useEffect(() => setExpanded(false), [location]);

	return (
		<div ref={navRef}>
			<motion.div
				initial={false}
				animate={visible ? "open" : "closed"}
				className={`admin-nav-header`}
			>
				<div className="container">
					{/* <div className="admin-nav-header-logo">
						<Logo type="circle-white-stacked" />
					</div>
					<div className="admin-nav-header-logo_mobile">
						{title ? (
							<StyledText type="bold">{title}</StyledText>
						) : (
							<Logo />
						)}
					</div> */}
					<ToggleButton
						onClick={() => {
							// dispatchEvent(new CustomEvent('toggle-sidebar', { detail: { state: !visible }}));
							document.body.classList.toggle('sidebar-open', !visible);
							setVisible(!visible);
						}}
					/>
				</div>
			</motion.div>
			<motion.nav
				variants={variants}
				initial={false}
				animate={visible ? "open" : "closed"}
				className={`admin-nav ${expanded ? "admin-nav-expanded" : ""} ${
					visible && "admin-nav-slided"
				}`}
			>
				<motion.div
					variants={{
						open: {
							transition,
						},
						closed: {
							transition,
						},
					}}
					initial={false}
					animate={expanded ? "open" : "closed"}
					className="expand-icon"
				>
					<ToggleButton
						onClick={() => {
							setExpanded(!expanded);
						}}
					/>
				</motion.div>
				<NavLink to="/" className="admin-nav-logo">
					<Logo
						type={
							expanded ? "circle-white" : "circle-white-stacked"
						}
					/>
				</NavLink>
				<div className="admin-nav-items">
					{is_subscribed || is_whitelisted ? (
						<>
							<NavItem
								to="/dashboard"
								label="Dashboard"
								icon="chart-line"
								image={require("../../../assets/images/dashboard-icons/dashboard.svg")}
								className={
									!location.pathname.includes('settings') && location.pathname.includes('dashboard')
										? "admin-nav-item-active"
										: ""
								}
							/>
							{action_needed && action_needed > 0 ? (
								<NavItem
									to={`/reservations/attention`}
									label="Actie vereist"
									badge={action_needed}
									icon="exclamation-circle"
									className={
										location.pathname.includes("/reservations/attention")
											? "admin-nav-item-active"
											: ""
									}
								/>
							) : null}
							<NavItem
								to={`/reservations?view=${viewMode}`}
								label="Reserveringen"
								icon="envelope"
								image={require("../../../assets/images/dashboard-icons/reserveringen.svg")}
								className={
									location.pathname.includes("/reservations") && !location.pathname.includes("attention")
										? "admin-nav-item-active"
										: ""
								}
							/>
						</>
					) : (
						false
					)}

					{/* <NavItem
						to="/account/restaurant"
						label={
							is_admin && !is_subscribed
								? "Mijn account"
								: "Mijn restaurant"
						}
						image={require("../../../assets/images/dashboard-icons/mijn-restaurant.svg")}
						icon="utensils"
						className={
							location.pathname.includes("/account") && !location.pathname.includes("settings")
								? "admin-nav-item-active"
								: ""
						}
					/> */}
					<NavItem
						to="/dashboard/settings"
						label={"Instellingen"}
						icon="cog"
						className={
							location.pathname.includes('settings')
								? "admin-nav-item-active"
								: ""
						}
					/>
					<NavItem
						to="/ideas"
						label={"Ideeënbus"}
						image={require("../../../assets/images/dashboard-icons/ideeenbus.svg")}
						icon="help"
						className={
							location.pathname.includes('ideas')
								? "admin-nav-item-active"
								: ""
						}
					/>
					{/* <NavItem
						to="/need-help"
						label={"Hulp nodig"}
						image={require("../../../assets/images/dashboard-icons/hulp-nodig.svg")}
						icon="help"
					/> */}
					{is_admin ? (
						<NavItem
							to="/admin/users"
							label="Admin"
							icon="users"
						/>
					) : (
						false
					)}
					<NavItem
						onClick={handleLogout}
						label="Uitloggen"
						image={require("../../../assets/images/dashboard-icons/uitloggen.svg")}
						icon="sign-out"
					/>
				</div>
			</motion.nav>
		</div>
	);
};

type NavItemProps = {
	to?: string;
	label: string;
	badge?: number;
	icon: string;
	count?: number;
	onClick?: () => void;
	className?: string;
	image?: any;
};

const NavItem = ({
	to,
	label,
	badge,
	icon,
	count,
	onClick,
	className,
	image,
}: NavItemProps) => {
	const badgeItem = badge === undefined || badge <= 0 ? null : (
		<span className="admin-nav-item-badge badge" style={{ backgroundColor: '#d00', marginLeft: 10, fontSize: 11, padding: '1px 5px', borderRadius: 4 }}>{badge}</span>
	);

	if (onClick) {
		return (
			<div onClick={onClick} className="admin-nav-item">
				<div className="icon">
					{image ? (
						<img src={image} alt="" />
					) : (
						<i className={`fa${icon === 'cog' || icon.includes('exclamation') ? 's' : 'l'} fa-${icon}`}></i>
					)}
				</div>
				<StyledText>{label}</StyledText>
				{badgeItem}
			</div>
		);
	}

	return (
		<NavLink
			to={to || "/"}
			className={`admin-nav-item ${className || ""}`}
			// activeClassName="admin-nav-item-active"
		>
			<div className="icon">
				{image ? (
					<img src={image} alt="" />
				) : (
					<i className={`fa${icon === 'cog' || icon.includes('exclamation') ? 's' : 'l'} fa-${icon}`}></i>
				)}
			</div>
			<StyledText>{label}</StyledText>
			{badgeItem}
		</NavLink>
	);
};

export default AdminNav;

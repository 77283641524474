import React, { FC, useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
	useLocation,
	Prompt,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//Auth Routes
import LoginView from "./views/Auth/LoginView/index";
import RegisterView from "./views/Auth/RegisterView";
//Dashboard Routes
import HomeView from "./views/HomeView";
import RestaurantsView from "./views/RestaurantsView";
import { updateProfile } from "./redux/auth";
import SubscriptionView from "./views/SubscriptionView";
import ReservationsView from "./views/Dashboard/ReservationsView/index";
import RestaurantsProfileView from "./views/RestaurantsView/RestaurantsProfileView";
import AlertContainer from "./components/AlertContainer";
import ResetPasswordView from "./views/Auth/ResetPasswordView";
import NotFoundView from "./views/NotFoundView";
import PrivacyView from "./views/Legal/PrivacyView";
import TermsAndConditionsView from "./views/Legal/TermsAndConditionsView";
import ForPartnersView from "./views/Info/ForPartnersView/v2";
import ContactView from "./views/Info/ContactView/index";
import SubscriptionsView from "./views/Info/SubscriptionsView";
import DashboardView from "./views/Dashboard/DashboardView";
import DashboardSettingsView from "./views/Dashboard/SettingsView";
import HelpView from "./views/HelpView";
import IdeaView from "./views/Dashboard/IdeaView";
import HelpDashboardView from "./views/Dashboard/HelpView";
import PauseTimeslotView from "./views/Dashboard/ReservationsView/PauseTimeslotView";
import CheckoutView from "./views/CheckoutView";
import axios from "./lib/bootstrap";
import Success from "./components/Success";
import SingleActionModal from "./components/SingleActionModal";
import NotificationCenter from "./kit/NotificationCenter";
import FullscreenLoader from "./kit/FullscreenLoader";
import DoReservationModal from "./components/DoReservationModal";
import ReservationDetailModal from "./components/ReservationDetailModal";
import AdminSettingsView from "./views/Dashboard/AdminSettingsView";
import RestaurantEmbedView from "./views/RestaurantsView/EmbedView";

const RedirectToDashboardRoute = ({
	component: Component,
	auth,
	...rest
}: any) => (
	<Route
		{...rest}
		auth={auth}
		render={(props: any) =>
			!auth.id ? (
				<Component {...props} />
			) : (
				<Redirect
					to={
						auth.is_admin
							? LOGGEDIN_ADMIN_REDIRECT
							: LOGGEDIN_REDIRECT
					}
				/>
			)
		}
	/>
);

export const LOGGEDIN_ADMIN_REDIRECT = "/admin/users";
export const LOGGEDIN_REDIRECT = "/reservations";

const RedirectToSubscription = ({ component: Component, ...rest }: any) => {
	const auth = useSelector((state: any) => state.auth);
	let allow = false;

	if (auth.is_subscribed || auth.is_whitelisted || auth.is_admin) {
		allow = true;
	}

	return (
		<Route
			{...rest}
			auth={auth}
			render={(props: any) =>
				auth.id && !allow ? (
					<Redirect to="/subscription" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

const BeforeLeave: FC = (): JSX.Element => {
	const [ leavePrompt, setLeavePrompt ] = useState<boolean>(true);
	const location = useLocation();

	useEffect(() => {
		const handleLeave = ({ detail }: any): void => {
			setLeavePrompt(detail.state);
		}
		window.addEventListener('leave-prompt', handleLeave, true);
		return () => window.removeEventListener('leave-prompt', handleLeave, true);
	}, []);

	useEffect(() => {
		setLeavePrompt(false);
	}, [location]);

	return (
		<Prompt
			when={leavePrompt}
			message="Weet je zeker dat je pagina wilt verlaten? Niet alle wijzigingen zijn opgeslagen"
		/>
	);
}

const Routes = () => {
	const auth = useSelector((state: any) => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		const update = (): void => {
			axios.get('/auth/profile').then(({ data }) => {
				dispatch(updateProfile({
					...auth,
					action_needed: data.data.action_needed,
					timeslotcount: data.data.timeslotcount,
				}));
			}).catch(() => {});
		}

		window.addEventListener('update-profile', update, true);
		return () => window.removeEventListener('update-profile', update, true);
	}, [dispatch, auth]);

	useEffect(() => {
		let token = localStorage.getItem('token');
		if (localStorage.getItem('superToken')) {
			token = localStorage.getItem('superToken');
		}
		if (token && token !== '') {
			axios.get('/auth/profile').then(({ data }) => {
				dispatch(updateProfile(data.data));
			}).catch(() => {});
		}
	}, [dispatch]);

	return (
		<Router>
			<BeforeLeave />
			<NotificationCenter />
			<AlertContainer />
			<ScrollTop />
			<Switch>
				<Route path="/" key="home" exact component={HomeView} />)
				
				<Route path="/privacy-policy" exact component={PrivacyView} />
				<Route path="/terms-and-conditions" exact component={TermsAndConditionsView} />
				<Route path="/contact" key="contact" exact component={ContactView} />
				<Route path="/help" key="help" exact component={HelpView} />
				<Route path="/for-partners" exact component={ForPartnersView} />
				
				<Route path="/subscriptions" exact component={SubscriptionsView} />
				<Route path="/subscription" exact component={SubscriptionView} />
				<Route path="/checkout/:plan" exact component={CheckoutView} />

				<RedirectToDashboardRoute
					auth={auth}
					path="/login"
					exact
					component={LoginView}
				/>
				<Route path="/signup" exact component={RegisterView} />
				<Route path="/reset-password/:token?" exact component={ResetPasswordView} />
					
				<Route path="/restaurants/:type/:location?" exact component={RestaurantsView} />
				<Route path="/restaurants/profile/:id/show" exact component={RestaurantsProfileView} />
				<Route path="/restaurants/profile/:id/timeslots/now" exact component={RestaurantEmbedView}/>
				<Route path="/restaurants/profile/:id/:type?" exact component={RestaurantsProfileView} />
				
				<RedirectToSubscription path="/dashboard" exact component={DashboardView} />
				<RedirectToSubscription path="/dashboard/settings" exact component={DashboardSettingsView} />
				<RedirectToSubscription path="/dashboard/settings/:page" exact component={DashboardSettingsView} />
				<RedirectToSubscription path="/dashboard/settings/:page/:modal" exact component={DashboardSettingsView} />
				<RedirectToSubscription path="/dashboard/settings/:page/:modal/create/:type" exact component={DashboardSettingsView} />
				<RedirectToSubscription path="/dashboard/settings/:page/:modal/:id" exact component={DashboardSettingsView} />
				<RedirectToSubscription path="/ideas" component={IdeaView} />
				<RedirectToSubscription path="/need-help" exact component={HelpDashboardView} />
				<RedirectToSubscription path="/reservations" exact component={ReservationsView} />
				<RedirectToSubscription path="/reservations/:type" exact component={ReservationsView} />
				<RedirectToSubscription path="/reservations/pause/timeslot" exact component={PauseTimeslotView} />
				
				{auth && auth.is_admin && (<>
					<Route path="/admin" exact component={AdminSettingsView} />
					<Route path="/admin/:page" exact component={AdminSettingsView} />
					<Route path="/admin/:page/:modal" exact component={AdminSettingsView} />
					<Route path="/admin/:page/:modal/:id" exact component={AdminSettingsView} />
				</>)}

				<Route path="*" component={NotFoundView} />
			</Switch>
			<Success />
			{auth.id && (<>
				<SingleActionModal />
				<ReservationDetailModal />
			</>)}
			<FullscreenLoader />
			<DoReservationModal />
		</Router>
	);
};

const ScrollTop = () => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	return <></>;
};

export default Routes;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./reservation-detail-modal.scss";
import Modal from "../Modal";
import StyledText from "../StyledText";
import { deleteReservation } from "../../components/SingleActionModal";
import moment from "moment";
import {
	PersonCount,
	TimeslotIconButton,
} from "../../views/Dashboard/ReservationsView/MobileReservationItem";
import Badge from "../Badge";
import Button from "../Button";
import { openDoReservationModal } from "../DoReservationModal";

export const openReservationDetailModal = (reservation: any): void => {
	dispatchEvent(new CustomEvent('open-reservation-detail-modal', { detail: { reservation }}));
}

const ReservationDetailModal = () => {
	const [ options, setOptions ] = useState<boolean>(false);
	const [ showInfo, setShowInfo ] = useState<boolean>(false);
	const [ open, setOpen ] = useState<boolean>(false);
	const [ reservation, setReservation ] = useState<any>();
	const auth = useSelector((state: any) => state.auth);

	useEffect(() => {
		const handleOpen = ({ detail }: any): void => {
			setReservation(detail.reservation);
			setOpen(true);
		}

		window.addEventListener('open-reservation-detail-modal', handleOpen, true);
		return () => window.removeEventListener('open-reservation-detail-modal', handleOpen, true);
	}, []);

	const handleClose = (): void => {
		setShowInfo(false);
		setOpen(false);
	}

	if (!reservation) {
		return <div />;
	}

	return (
		<Modal
			title="Reservering details"
			visible={open}
			onClose={handleClose}
			type={showInfo ? 'large' : ''}
		>
			{showInfo ? (
				<div className="reservation-detail-modal rdm-info">
					<div className="user-info">
						<div>
							<div className="status-warning">
								<div className="status-warning-icon">
									<i className="far fa-info-circle"></i>
								</div>
								<div>
									<StyledText>De reservering die jij bekijkt is terug te vinden op de pagina actie vereist met de onderstaande types</StyledText>
								</div>
							</div>
							<div className="rs">
								{reservation?.statuses?.map((status: any, i: number) => (
									<span className="row-status" key={`rs-${i}${reservation?.id}`}>
										{status.status}
									</span>
								))}
							</div>
						</div>

						<Button
							onClick={() => setShowInfo(false)}
							type="grey-shadow"
							light
						>
							<i className="fas fa-arrow-left"></i>
							<span>Terug</span>
						</Button>
					</div>
					<div className="info">
						<div className="text">
							<StyledText type="bold">Overbezetting</StyledText>
							<StyledText>
								Reservering waarvan het originele tijdvak is verwijderd en is verplaatst naar een ander bestaand tijdvak met niet voldoen stoelen.
							</StyledText>
						</div>
						<div className="text">
							<StyledText type="bold">Tijd gewijzigd</StyledText>
							<StyledText>
								Reservering waarvan de tijd van zijn tijdvak is gewijzigd.
							</StyledText>
						</div>
						<div className="text">
							<StyledText type="bold">Dag niet beschikbaar</StyledText>
							<StyledText>
								Reservering waarvan de dag van zijn tijdvak is uitgezet of is verplaats naar een datum type tijdvak en de datum reeks erbuiten valt.
							</StyledText>
						</div>
						<div className="text">
							<StyledText type="bold">Stoel niet beschikbaar</StyledText>
							<StyledText>
								Reservering waarvan het aantal stoelen is gewijzigd in het tijdvak en de reserveringen niet meer past in het huidige tijdvak.
							</StyledText>
						</div>
					</div>
				</div>
			) : (<>
				<div className="reservation-detail-modal">
					<div className="user-info">
						<div>
							<StyledText type="label">{reservation?.name}</StyledText>
							<StyledText>
								{reservation?.email || "Geen email"}
							</StyledText>
							<StyledText>
								{reservation?.telephone || "Geen telefoon"}
							</StyledText>
						</div>
						<div className={`buttons ${options ? 'show' : ''}`}>
							<div className="buttons-toggle" onClick={() => setOptions(!options)}>
								<Button>
									<span>{options ? 'Sluiten' : 'Opties'}</span>
									<i className={`far ${options ? 'fa-times' : 'fa-chevron-up'}`}></i>
								</Button>
							</div>
							{reservation?.telephone && (
								<Button
									light
									to={`tel:${reservation.telephone}`}
									className="button-flip-icon"
								>
									<i className="fas fa-phone"></i>
									<span>Bellen</span>
								</Button>
							)}
							{reservation?.email && (
								<Button
									light
									to={`mailto:${reservation.email}`}
								>
									<i className="fas fa-paper-plane"></i>
									<span>Mailen</span>
								</Button>
							)}
							<Button
								onClick={() => {
									// dispatchEvent(new CustomEvent('edit-reservation', { detail: { reservation } }));
									openDoReservationModal(auth.restaurant.id, reservation);
									handleClose();
								}}
								light
							>
								<i className="fas fa-pencil"></i>
								<span>Wijzigen</span>
							</Button>
							<Button
								onClick={() => {
									deleteReservation([reservation]);
									handleClose();
								}}
								light
							>
								<i className="fas fa-times"></i>
								<span>Annuleren</span>
							</Button>
						</div>
					</div>
					<div className="info">
						<div className="info-part">
							<div className="row">
								<StyledText type="label">
									Gereserveerd op
								</StyledText>
								<StyledText>
									{moment(reservation.created_at).format(
										"DD MMMM YYYY"
									)}
								</StyledText>
							</div>
							<div className="row">
								<StyledText type="label">Voor</StyledText>
								<StyledText>
									{moment(reservation.date).format(
										"DD MMMM YYYY"
									)}
								</StyledText>
							</div>
							<div style={{ marginTop: 15 }} className="row">
								<div className="tag">
									<PersonCount count={reservation?.persons} />
								</div>
								<div className="tag">
									<TimeslotIconButton
										time={auth.restaurant.modus === 'freq'
											? (reservation?.set_timeslot || '')
											: `${reservation?.timeslot?.time.start} - ${reservation?.timeslot?.time.end}`
										}
									/>
								</div>
							</div>
						</div>
						{(!auth.restaurant.settings || auth.restaurant.settings.allow_comment) && (
							<div className="info-part info-part-block">
								<StyledText type="label">Opmerking</StyledText>
								<StyledText>
									{reservation?.comment || "Geen opmerking"}
								</StyledText>
							</div>
						)}
						<div className="info-part info-part-no-border">
							<div className="row row-justify">
								<StyledText type="label">Eén huishouden</StyledText>
								<Badge type={reservation?.is_same_household ? '' : 'red'}>
									{reservation?.is_same_household ? "Ja" : "Nee"}
								</Badge>
							</div>
							{!reservation?.is_same_household && (
								<StyledText>
									{reservation?.household_comment || ""}
								</StyledText>
							)}
						</div>
					</div>
				</div>
				{reservation?.statuses && reservation.statuses.length > 0 && (
					<div className="status-warning">
						<div className="status-warning-icon">
							<i className="far fa-info-circle"></i>
						</div>
						<div className="status-warning-status">
							{reservation?.statuses?.map((status: any, i: number) => (
								<span className="row-status" key={`rs-${i}${reservation?.id}`}>
									{status.status}
								</span>
							))}
						</div>
						<div className="status-warning-info" onClick={() => setShowInfo(true)}>
							<StyledText>
								Meer informatie
							</StyledText>
						</div>
					</div>
				)}
			</>)}
		</Modal>
	);
};

export default ReservationDetailModal;

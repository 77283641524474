import React from "react";
import Slider from "react-slick";

import "./instructions.scss";
import StyledText from "../StyledText";
import { motion } from "framer-motion";

const INSTRUCTIONS = [
	{
		text: "Locatie invoeren",
		image: require("../../assets/images/icons/icon-1.svg"),
	},
	{
		text: "Kies een restaurant",
		image: require("../../assets/images/icons/icon-2.svg"),
	},
	{
		text: "Kies een datum",
		image: require("../../assets/images/icons/icon-4.svg"),
	},
	{
		text: "Kies een tijdvak & aantal personen",
		image: require("../../assets/images/icons/icon-3.svg"),
	},
];

const variants = {
	container: {
		enter: {
			y: 0,
			opacity: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: 0.2,
			},
		},
		exit: { y: -20, opacity: 0 },
	},
	card: {
		enter: {
			y: 0,
			opacity: 1,
		},
		exit: {
			y: -20,
			opacity: 0,
		},
	},
};
const Instructions = () => {
	return (
		<section className="instructions">
			<div className="container">
				<Slider
					slidesToShow={4}
					responsive={[
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							},
						},
						{
							breakpoint: 480,
							settings: {
								arrows: true,
								slidesToShow: 1,
								slidesToScroll: 1,
								dots: true,
							},
						},
					]}
				>
					{INSTRUCTIONS.map((instruction: any, index: number) => (
						<Instruction
							key={instruction.text}
							count={index + 1}
							is_last={INSTRUCTIONS.length === index + 1}
							{...{ ...instruction }}
						/>
					))}
				</Slider>
			</div>
		</section>
	);
};

type InstructionProps = {
	text: string;
	image: any;
	count: number;
	is_last: boolean;
};

const Instruction = ({ text, image, count, is_last }: InstructionProps) => (
	<motion.div
		variants={variants.card}
		className={`instruction ${is_last ? "instruction-last" : ""}`}
	>
		<div className="instruction-image">
			<div className="instruction-wrapper">
				<div className="instruction-badge">
					<span>{count}</span>
				</div>
			</div>
			<img src={image} alt="" />
		</div>
		<StyledText>{text}</StyledText>
		<div className="instruction-angle">
			<i className="fas fa-angle-right"></i>
		</div>
	</motion.div>
);

export default Instructions;

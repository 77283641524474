import { find } from 'lodash';
import { parse } from 'qs';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Card from '../../../components/Card';
import Layout from '../../../components/Layout';
import styles from './styles.module.scss';
import axios from 'axios';
import { ApiReservation } from '../../../api';
import ReservationCard from './ReservationCard';
import FullscreenLoader from '../../../kit/FullscreenLoader';
import { useAuth } from '../../../hooks/useAuth';
import ReservationTable from './ReservationTable';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { openDoReservationModal } from '../../../components/DoReservationModal';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { deleteReservation } from '../../../components/SingleActionModal';
import { useChangeCallback, useChangeNeeded, useChangesCanceled } from '../../../hooks/useChange';
import { setChangeNeeded } from '../../../redux/change';
import DebouncedInput from './DebounceInput';
import FilterModal from './FilterModal';
import Modal from '../../../components/Modal';

type ViewMode = 'calendar' | 'list';

const ReservationsView: FC = (): JSX.Element => {
    const [ selection, setSelection ] = useState<number[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ selectedDate, setSelectedDate ] = useState<string>(moment().format('YYYY-MM-DD'));
    const [ startQuery, setStartQuery ] = useState<string>(moment().format('YYYY-MM-DD'));
    const [ endQuery, setEndQuery ] = useState<string>(moment().format('YYYY-MM-DD'));
    const [ counter, setCounter ] = useState<{ [key: string]: number }>({});
    const [ viewMode, setViewMode ] = useState<ViewMode>('calendar');
    const [ reservations, setReservations ] = useState<ApiReservation[]>([]);
    const [ searchQuery, setSearchQuery ] = useState<string>('');
    const [ statuses, setStatuses ] = useState<string[]>(['gewijzigd', 'geannuleerd', 'verlopen']);
    const [ timeslots, setTimeslots ] = useState<any[]>([]);
    const [ init, setInit ] = useState<boolean>(false);
    const [ showFilter, setShowFilter ] = useState<boolean>(false);
    const [ toggleMobileSearch, setToggleMobileSearch ] = useState<boolean>(false);
    const auth = useAuth();
    const dispatch = useDispatch();
    const { type } = useParams() as { type?: string };
    const location = useLocation();
    const windowWidth = useWindowWidth();
    const history = useHistory();
    const changeNeeded = useChangeNeeded();

    const fetch = useCallback((params?: any, q?: string, counter: boolean = false): void => {
        setIsLoading(true);

        let url = 'reservations/overview/' + (counter ? 'counter/' : '');
        url += (viewMode === 'list' || type === 'attention')
            ? `${params?.start || startQuery}?end=${params?.end || endQuery}&`
            :`${params?.date || selectedDate}?`;
        url += `${type === 'attention' ? 'attention=1&' : ''}q=${q === undefined ? searchQuery : q}`;
        url += `&view=${type === 'attention' ? 'list' : viewMode}`;

        if (params?.statuses || statuses) {
            url += `&status=${(params?.statuses || statuses).join(',')}`;
        }
        if (params?.timeslots || timeslots) {
            url += `&timeslots=${(params?.timeslots || timeslots).join(',')}`;
        }

        axios.get(url).then(({ data }) => {
            setIsLoading(false);
            if (counter) {
                setCounter(data.split && data.length <= 0 ? {} :data);
            } else {
                setReservations(data.data);
                setSelection([]);
            }
        });
    }, [endQuery, searchQuery, selectedDate, startQuery, type, viewMode, timeslots]);

    useEffect(() => {
        fetch();
    }, [type]); // eslint-disable-line

    const doSearch = useCallback((q) => {
        fetch(undefined, q);
        if (viewMode === 'calendar') {
            fetch(undefined, q, true);
        }
    }, [fetch, viewMode]);

    useEffect(() => {
        const handleRefetch = (): void => {
            fetch();
            fetch(undefined, undefined, true);
        }

        if (!init) {
            // fetch();
            setInit(true);
        }

        window.addEventListener('reservation-refresh', handleRefetch, true);
        return () => window.removeEventListener('reservation-refresh', handleRefetch, true);
    }, [fetch, init, type]);

    useEffect(() => {
        if (viewMode === 'calendar') {
            setSelectedDate(startQuery);
            fetch({ date: moment(startQuery).startOf('month').format('YYYY-MM-DD') }, undefined, true);
        }
    }, [viewMode]); // eslint-disable-line

    useEffect(() => {
        const query = parse(location.search.substring(1));
        if (query && query.view) {
            setViewMode(query.view as ViewMode);
            localStorage.setItem('view-mode', query.view as ViewMode);
            dispatchEvent(new CustomEvent('nav-view-mode'));
        }
    }, [location]);

    useChangeCallback('group-action', () => {
        handleMultiDelete();
        setSelection([]);
        dispatch(setChangeNeeded(undefined));
    });
    useChangesCanceled('group-action', () => {
        setSelection([]);
        dispatch(setChangeNeeded(undefined));
    });

    const handleMonthChange = ({ action, activeStartDate }: any): void => {
        if (/prev|next|drilldown/i.test(action)) {
            const date = moment(activeStartDate).format('YYYY-MM-DD');
            setSelectedDate(date);
            // fetch({ date }, undefined, true);
            fetch({ date });
        }
    }

    const handleSearch = (q: string): void => {
        setSearchQuery(q);
        doSearch(q);
    }

    const handleMenuPosition = (e: any): void => {
        const menu = e.nextSibling;
        if (!menu) return;
        const rect = menu.getBoundingClientRect();
        const calendar = document.querySelector('.mw-calendar')
        if (!calendar || !menu.classList.contains('button-menu')) return;
        if (rect.x < calendar.getBoundingClientRect().x) {
            menu.classList.add('button-menu-left');
        }
    }

    const renderTile = ({ date }: any) => {
        const dt = moment(date).format('YYYY-MM-DD');

        const actions = (
            <Button
                className="options"
                type="white-shadow"
                menu
                menuIcon="fa-solid fa-ellipsis"
                onMenu={handleMenuPosition}
            >
                <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        openDoReservationModal(
                            auth?.restaurant?.id || 0,
                            undefined,
                            false,
                            dt
                        );
                    }}>
                    <i className="fas fa-plus"></i>
                    <span>Reservering toevoegen</span>
                </a>
            </Button>
        );

        if (counter[dt]) {
            return (<>
                <div
                    className={styles.counter} onClick={() => {
                        if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                            history.push('/reservations?view=list');
                        }
                    }}
                >
                    <span>{counter[dt]}</span>
                    Reservering{counter[dt] === 1 ? '' : 'en'}
                </div>
                {actions}
            </>);
        }

        return actions;
    }

    const toggleSelection = (checked: boolean, id: number): void => {
        let newSelection = [];
		if (checked) {
			newSelection = [...selection, id];
		} else {
			newSelection = [...selection.filter(o => o !== id)];
		}

        setSelection(newSelection);
        dispatch(setChangeNeeded(newSelection.length > 0 ? 'group-action' : undefined));
	}

    const handleMultiDelete = () => {
		deleteReservation(reservations.filter((o: any) => selection.includes(o.id)));
	};

    const reservationList = (
        <div className={styles.list}>
            {isLoading
                ? <FullscreenLoader relative state text="laden" />
                : (reservations.length > 0 ? (
                    <Card>
                        {reservations.map((reservation) => (
                            <ReservationCard
                                key={`res-${reservation.id}`}
                                reservation={reservation}
                                onSelect={(checked, res) => toggleSelection(checked, res.id)}
                                selection={selection}
                            />
                        ))}
                    </Card>
                ) : <em>Geen reserveringen vandaag</em>)
            }
        </div>
    );

    const advancedSearch = (
        <Button
            className="options"
            type="white-shadow"
            menu
            menuIcon="fa-solid fa-ellipsis"
        >
            <a
                href="/"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowFilter(true);
                }}>
                <i className="fas fa-sliders"></i>
                <span>Uitgebreid zoeken</span>
            </a>
        </Button>
    )

    const search = (
        <div className={styles.search}>
            <i className="fal fa-search"></i>
            <DebouncedInput
                onUpdate={(value) => handleSearch(value)}
                value={searchQuery}
            />
            {advancedSearch}
        </div>
    )

    const renderMe = (<>
        <div className={`${styles.mobileSearchContainer} ${toggleMobileSearch ? styles.show : ''}`}>
            <div className={styles.searchcont}>
                {search}
            </div>
            {!toggleMobileSearch ? (
                <i
                    className="far fa-search"
                    onClick={() => setToggleMobileSearch(true)}
                />
            ) : (<>
                <i
                    className="fas fa-circle-xmark"
                    onClick={() => {
                        setToggleMobileSearch(false);
                        handleSearch('');
                    }}
                />
                {toggleMobileSearch && advancedSearch}
            </>)}
        </div>

        <div className={styles.container}>
            <div className={`${styles.filterContainer} ${styles[`filterContainer${viewMode}`]}`}>
                <div>
                    <div className={`${styles.buttons} ${styles.green}`}>
                        <div onClick={() => openDoReservationModal(auth?.restaurant?.id || 0)}>
                            <i className="far fa-plus" />
                            <div className="tooltip">
                                Nieuwe reservering<br />toevoegen
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.buttons}>
                        <div onClick={() => {}}>
                            <i className="fas fa-filter" />
                            <div className="tooltip">
                                Uitgebreid zoeken
                            </div>
                        </div>
                    </div> */}
                    {search}
                    {(viewMode === 'list' || type === 'attention') && (
                        <div className={`${styles.period} ${searchQuery === '' ? '' : styles.disabledPeriod}`}>
                            <div>
                                <Input
                                    className="date-input"
                                    date
                                    selectsStart
                                    startDate={new Date(startQuery)}
                                    endDate={new Date(endQuery)}
                                    placeholder="Datum"
                                    onChange={(value) => {
                                        setStartQuery(value);
                                        if (value > endQuery) {
                                            setEndQuery(value);
                                            fetch({ start: value, end: value });
                                        } else {
                                            fetch({ start: value, end: endQuery });
                                        }
                                    }}
                                    value={startQuery}
                                />
                                <Input
                                    className={`date-input ${startQuery === endQuery && 'date-input-disabled'}`}
                                    date
                                    selectsEnd
                                    startDate={new Date(startQuery)}
                                    endDate={new Date(endQuery)}
                                    minDate={new Date(startQuery)}
                                    placeholder="Datum"
                                    onChange={(value) => {
                                        setEndQuery(value);
                                        fetch({ end: value });
                                    }}
                                    value={endQuery}
                                />
                            </div>
                            <span>
                                <i className="fas fa-calendar-day"></i>
                            </span>
                        </div>
                    )}
                </div>
                <div>
                    {selection.length > 0 && (
                        <Button
                            type="white-shadow"
                            menu
                            menuLabel="Groep actie"
                            menuIcon="fas fa-check"
                        >
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatchEvent(new CustomEvent('edit-reservation', {
                                        detail: {
                                            multi: true,
                                            reservation: find(reservations, { id: selection[0] })
                                        }
                                    }))
                                }}>
                                <i className="fas fa-pencil"></i>
                                <span>Wijzigen</span>
                            </a>
                            <a href="/" onClick={(e) => { e.preventDefault(); handleMultiDelete(); }}>
                                <i className="far fa-times"></i>
                                <span>Annuleren</span>
                            </a>
                        </Button>
                    )}
                    {type !== 'attention' && (viewMode === 'calendar' || window.innerWidth <= 768 || window.innerWidth >= 1200) && (
                        <div className={styles.buttons}>
                            <div
                                className={viewMode === 'calendar' ? styles.isActive : ''}
                                onClick={() => {
                                    setViewMode('calendar');
                                    localStorage.setItem('view-mode', 'calendar');
                                    dispatchEvent(new CustomEvent('nav-view-mode'));
                                }}
                            >
                                <i className="fas fa-calendar-day" />
                                <div className="tooltip">
                                    Toon kalender
                                </div>
                            </div>
                            <div
                                className={viewMode === 'list' ? styles.isActive : ''}
                                onClick={() => {
                                    setViewMode('list');
                                    localStorage.setItem('view-mode', 'list');
                                    dispatchEvent(new CustomEvent('nav-view-mode'));
                                }}
                            >
                                <i className="far fa-list" />
                                <div className="tooltip">
                                    Toon als lijst
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {type !== 'attention' && viewMode === 'calendar' && (
                <div className={styles.grid}>
                    <Card className={styles.card}>
                        <Calendar
                            className="mw-calendar mw-calendar-large"
                            formatShortWeekday={(locale, date) => moment(date).format(window.innerWidth > 768 ? 'dddd' : 'dd')}
                            locale="nl-NL"
                            nextLabel={<i className="far fa-chevron-right" />}
                            next2Label={null}
                            onChange={(date: any) => {
                                const dt = moment(date).format('YYYY-MM-DD');
                                setStartQuery(dt);
                                setSelectedDate(dt);
                                if (dt > endQuery) {
                                    setEndQuery(dt);
                                }
                                fetch({ date: dt });
                                fetch({ date: dt }, undefined, true);
                            }}
                            onActiveStartDateChange={handleMonthChange}
                            prevLabel={<i className="far fa-chevron-left" />}
                            prev2Label={null}
                            tileContent={renderTile}
                            value={moment(selectedDate).toDate()}
                        />
                    </Card>
                    <div className={styles.calendarList}>
                        {reservationList}
                    </div>
                    <div className={styles.calendarItems}>
                        {reservations.length > 0 && (
                            <Link
                                className={styles.reservationListLink}
                                to={`/reservations?view=list`}
                            >
                                <span>{reservations.length}</span>
                                Reservering{reservations.length === 1 ? '' : 'en'}
                            </Link>
                        )}
                    </div>
                </div>
            )}
            {(type === 'attention' || viewMode === 'list') && (
                windowWidth < 820
                    ? reservationList
                    : <ReservationTable
                        data={reservations}
                        loading={false}
                        type={type || 'all'}
                        onSelect={(s) => setSelection(s)}
                        // onNextPage={(p) => setPage(p)}
                        // currentPage={page}
                    />
            )}
        </div>
        <FilterModal
            className={styles.filtermodal}
            view={type === 'attention' || viewMode === 'list' ? 'list' : 'calendar'}
            header="Uitgebreid zoeken"
            open={showFilter}
            onClose={() => setShowFilter(false)}
            start={startQuery}
            end={endQuery}
            query={searchQuery}
            onUpdate={(data) => {
                setSearchQuery(data.searchQuery);
                setStartQuery(data.startQuery);
                setStatuses(data.statuses);
                setEndQuery(data.endQuery);
                setTimeslots(data.timeslots);
                fetch({ timeslots: data.timeslots, start: data.startQuery, end: data.endQuery, statuses: data.statuses }, data.searchQuery);
                fetch({ date: moment(selectedDate).startOf('month').format('YYYY-MM-DD'), timeslots: data.timeslots, statuses: data.statuses }, data.searchQuery, true);
            }}
            cancelBtn={changeNeeded === undefined && windowWidth > 992
                ? undefined
                : 'Annuleren'
            }
            approveBtn={changeNeeded === undefined && windowWidth > 992
                ? undefined
                : 'Toepassen'
            }
            onApprove={changeNeeded === undefined && windowWidth > 992
                ? undefined
                : () => dispatchEvent(new CustomEvent('changes-saved', { detail: { onSuccess: () => setShowFilter(false) }}))
            }
            onCancel={changeNeeded === undefined && windowWidth > 992
                ? undefined
                : () => { dispatchEvent(new CustomEvent('changes-canceled')); setShowFilter(false); return true; }
            }
            noButtons={changeNeeded === undefined && windowWidth > 992}
        />
    </>)

    return auth ? (
        <Layout
            noLine
            title={type === 'attention' ? 'Actie vereist' : 'Reserveringen'}
            type="admin"
        >
            {viewMode === 'list' && type !== 'attention' && window.innerWidth > 768 && window.innerWidth <= 1200 ? (
                <Modal
                    title="Reserveringen"
                    visible={true}
                    onClose={() => {
                        setViewMode('calendar')
                        localStorage.setItem('view-mode', 'calendar');
                        dispatchEvent(new CustomEvent('nav-view-mode'));
                    }}
                    type="fullscreen"
                >
                    {renderMe}
                </Modal>
            ) : renderMe}
        </Layout>
    ) : <div />;
}

export default ReservationsView;

import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { useChangeCallback, useChangeNeeded, useChangesCanceled } from '../../../../hooks/useChange';
import Button from '../../../../kit/Button';
import Form from '../../../../kit/Form';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';
import { setChangeBtnLabel, setChangeNeeded } from '../../../../redux/change';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { useHistory, useLocation } from 'react-router-dom';

const AccountInfo: FC = (): JSX.Element => {
	const [ modus, setModus ] = useState<'slot' | 'freq'>('slot');
	const [ originalModus, setOriginalModus ] = useState<'slot' | 'freq'>('slot');
    const auth = useAuth();
	const dispatch = useDispatch();
	const [ patchProfile ] = usePatch('/auth/profile?validate=1');
	const windowWidth = useWindowWidth();
	const changeNeeded = useChangeNeeded();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (windowWidth > 992) return;
		dispatch(setChangeNeeded('open-timeslots'));
		dispatch(setChangeBtnLabel('Instellingen'));
		dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
	}, [dispatch, location, windowWidth]);

	useChangeCallback('open-timeslots', () => {
		dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
		history.push('/dashboard/settings/online/timeslots');
	}, []);

	useChangesCanceled('open-timeslots', () => {
		dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
		history.push('/dashboard/settings');
	}, []);

	useEffect(() => {
        if (!auth) return;
		setModus(auth.restaurant?.modus || 'slot');
		setOriginalModus(auth.restaurant?.modus || 'slot');
    }, [auth]);

	const save = () => {
		if (!auth || !auth.restaurant) return;
		toggleFullscreenLoader(true);
		patchProfile({ ...auth, restaurant: { ...auth.restaurant, modus } }).then(({ data }) => {
			toggleFullscreenLoader(false);
			dispatch(updateProfile(data));
			toast({
				header: 'Gelukt!',
				content: 'De reserverings modus is aangepast.',
			});
			dispatch(setChangeNeeded(undefined));
			dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
		})
		.catch(() => {
			toggleFullscreenLoader(false);
			dispatch(setChangeNeeded('reservation-modus'))
		});
	};

	useChangeCallback('reservation-modus', save, [modus]);

	useChangesCanceled('reservation-modus', () => {
		if (!auth) return;
		setModus(auth.restaurant?.modus || 'slot');
		setTimeout(() => {
			dispatch(setChangeNeeded('open-timeslots'));
			dispatch(setChangeBtnLabel('Instellingen'));
		}, 20);
		dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: false }}));
	}, [auth]);

    const handleInput = ({ value }: any) => {
		dispatch(setChangeNeeded(originalModus === value ? undefined : 'reservation-modus'));
		setModus(value);
		dispatchEvent(new CustomEvent('leave-prompt', { detail: { state: originalModus !== value }}));
	};

    return windowWidth <= 992 ? (<>
		<p className="sv-description">
            Maak een keuze of gasten online voor een tijdvak kunnen reserveren of een tijdstip binnen een bepaalde frequentie.
        </p>
        <Form.Dropdown
			options={[{
				text: 'Tijdvak',
				value: 'slot',
			}, {
				text: 'Tijdfrequentie',
				value: 'freq',
			}]}
			onChange={handleInput}
			value={modus}
        />

		{!changeNeeded && (
			<Button
				color="white"
				label="Instellingen"
				href="/dashboard/settings/online/timeslots"
				block
			/>
		)}
    </>) : (<>
        <div className="sf-row">
            <div className="sf-row-label">
                Reserverings modus
            </div>
            <div className="sf-row-description">
				Maak een keuze of gasten online voor een tijdvak kunnen reserveren of een tijdstip binnen een bepaalde frequentie.
            </div>
            <div className="sf-row-action">
				{changeNeeded ? (
					<Button
						color="green"
						label="Opslaan"
						onClick={save}
					/>
				) : (
					<Button
						color="white"
						label="Instellingen"
						href="/dashboard/settings/online/timeslots"
					/>
				)}
				<Form.Dropdown
					options={[{
						text: 'Tijdvakken',
						value: 'slot',
					}, {
						text: 'Tijdfrequentie',
						value: 'freq',
					}]}
					onChange={handleInput}
					value={modus}
				/>
            </div>
        </div>
    </>);
}

export default AccountInfo;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeNeeded } from '../redux/change';

export const useChangeNeeded = (): string | undefined => {
    const change = useSelector((state: any) => state.change);
    return change.needsChange;
}

export const useChangeBtnLabel = (): string | undefined => {
  const change = useSelector((state: any) => state.change);
  return change.btnLabel;
}

export const useChangeCallback = (
  doChange: string,
  callback: (onSuccess?: () => void) => void,
  dependencies: any[] = []
): void => {
    const dispatch = useDispatch();
    const needed = useChangeNeeded();

    useEffect(() => {
		const doCallback = ({ detail }: any) => {
            if (doChange !== needed) return;
            console.log('hallo?');
            callback(detail?.onSuccess);
            dispatch(setChangeNeeded(undefined));
		};

		window.addEventListener('changes-saved', doCallback, true);
		return () => window.removeEventListener('changes-saved', doCallback, true);
	}, [ dispatch, doChange, callback, needed, ...dependencies ]); // eslint-disable-line
}

export const useChangesCanceled = (doChange: string, callback: () => void, dependencies: any[] = []): void => {
    const dispatch = useDispatch();
    const needed = useChangeNeeded();

    useEffect(() => {
		const doCallback = () => {
            if (doChange !== needed) return;
            callback();
            dispatch(setChangeNeeded(undefined));
		};

		window.addEventListener('changes-canceled', doCallback, true);
		return () => window.removeEventListener('changes-canceled', doCallback, true);
	}, [ dispatch, doChange, callback, needed, ...dependencies ]);// eslint-disable-line
}

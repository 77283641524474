import React from "react";
import { NavLink } from "react-router-dom";
type NavItemProps = {
	to: string;
	label: string;
	type?: string;
	className?: string;
};

const NavItem = ({ to, label, type, className }: NavItemProps) => {
	const classes = ["nav-item"];
	if (type) {
		classes.push(`nav-item-${type}`);
	}

	return (
		<div className={`nav-item-wrapper ${className || ""}`}>
			<NavLink
				className={classes.join(" ")}
				activeClassName="nav-item-active"
				to={to}
			>
				{label}
			</NavLink>
		</div>
	);
};

export default NavItem;

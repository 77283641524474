import { useEffect, useState } from 'react';

export const useWindowWidth = (): number => {
    const [ width, setWidth ] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = (): void => {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize, true);
        return () => window.removeEventListener('resize', handleResize, true);
    }, []);

    return width;
}

import React from "react";
import CheckBox from "../../../../components/CheckBox";
import STATUSES from "../../../../lib/statuses";
import "./mobile-reservation-item.scss";

const MobileReservationItem = ({
	reservation,
	onShow,
	selection,
	onSelect,
}: {
	reservation: any;
	onShow: any;
	selection: number[],
	onSelect: (checked: boolean) => void,
}) => {
	return (
		<div className="list-item" onClick={() => onShow(reservation)}>
			<div className={`list-item-count list-item-count-${STATUSES[reservation.status].type}`}>
				<span><i className="fad fa-user-friends"></i></span>
				<span>{reservation.persons}</span>
			</div>
			<div className="list-item-info">
				<p>
					<span className="trunc">{reservation.name || 'Niet opgegeven'}</span>
					<span className={`row-status row-status-${STATUSES[reservation.status].type}`}>
						{STATUSES[reservation.status].title}
					</span>
				</p>
				<p>
					<span>
						<i className="fal fa-clock"></i>
						{' '}{reservation.timeslot ? `${reservation.timeslot.time.start} - ${reservation.timeslot.time.end}` : reservation.set_timeslot}
					</span>
					{reservation.status !== 'DELETED' &&  (<div onClick={(e) => e.stopPropagation()}>
						<CheckBox
							onChange={(checked) => onSelect(checked)}
							value={selection.includes(reservation.id)}
						/>
					</div>
					)}
				</p>
			</div>
		</div>
	)

	// return (
	// 	<div
	// 		{...{ ref }}
	// 		style={{ borderLeftColor: status.color }}
	// 		className={`${name} `}
	// 	>
	// 		<div className={`${name}-head`}>
	// 			<div className="row">
	// 				<PersonCount count={reservation.persons} />
	// 				<p>{truncate(reservation.name)}</p>
	// 			</div>
	// 			<span style={{ color: status.color }} className="status">
	// 				{status.title}
	// 			</span>
	// 		</div>
	// 		<Line colors={["#cdcdcd", "#fff"]} />
	// 		<div className={`${name}-body`}>
	// 			<div className="row">
	// 				<div className="image">
	// 					<img
	// 						src={require("../../../../assets/images/dashboard-icons/clock.svg")}
	// 						alt=""
	// 					/>
	// 				</div>
	// 				{timeslot ? <p>
	// 					{timeslot.time.start} - {timeslot.time.end}
	// 				</p> : <p>{reservation.set_timeslot}</p>}
	// 			</div>
	// 			<div onClick={toggle} className="more-button">
	// 				{toggled ? "Minder" : "Meer"}
	// 			</div>
	// 		</div>
	// 		<div className={`${name}-actions ${toggled && "toggled"}`}>
	// 			<div className="action">
	// 				<div onClick={() => onShow(reservation)} className="icon">
	// 					<img
	// 						src={require("../../../../assets/images/dashboard-icons/person.svg")}
	// 						alt=""
	// 					/>
	// 				</div>
	// 			</div>
	// 			<div className="action">
	// 				<EditReservation
	// 					{...{ reservation: reservation, refetch }}
	// 				/>
	// 			</div>
	// 			<div className="action">
	// 				<DeleteReservation
	// 					{...{ reservation: reservation, onDelete }}
	// 				/>
	// 			</div>
	// 			<Line vertical size="80%" colors={["#cdcdcd", "#fff"]} />
	// 			<div className="action action-delete">
	// 				<div onClick={toggle} className="close-button">
	// 					<img
	// 						src={require("../../../../assets/images/dashboard-icons/close.svg")}
	// 						alt=""
	// 					/>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
};

export const PersonCount = ({ count }: { count: number }) => (
	<div className="person-count">
		<div className="image">
			<i className="fad fa-user-friends"></i>
		</div>
		<Line size="80%" vertical colors={["#ffffff"]} />
		<p>{count}</p>
	</div>
);

export const TimeslotIconButton = ({ time }: { time: string }) => (
	<div className="person-count person-count-width">
		<div className="image">
			<i className="fal fa-clock"></i>
		</div>
		<Line size="80%" vertical colors={["#ffffff"]} />
		<p>{time}</p>
	</div>
);

const Line = ({
	vertical,
	colors,
	size,
}: {
	vertical?: boolean;
	colors: string[];
	size?: any;
}) => {
	const name = "multi-line";
	const classes = [name];
	if (vertical) classes.push(`${name}-vertical`);
	if (!vertical) classes.push(`${name}-horizontal`);

	let style = {
		borderColor: colors[0],
		backgroundColor: colors[1],
		height: vertical ? size || "100%" : undefined,
		width: !vertical ? size || "100%" : undefined,
	};

	return <div {...{ style }} className={classes.join(" ")} />;
};

export default MobileReservationItem;

import React from "react";
import "./title-box.scss";
import StyledText from "../StyledText";

const TitleBox = ({
	title,
	text,
	button,
}: {
	title: string;
	text?: string;
	button?: any;
}) => {
	return (
		<div className="title-box">
			<StyledText type="title" className="big-title">
				{title}
			</StyledText>
			{text && <StyledText>{text}</StyledText>}
			{button || false}
		</div>
	);
};

export default TitleBox;

import React from "react";
import Dropdown from "./index";
import useGet from "../../hooks/rest/useGet";
import { useSelector } from "react-redux";

type TimeslotDropdownProps = {
	value?: any;
	onChange: (value: number, item?: any) => void;
	isRequired?: boolean;
	placeholder?: string;
	filterId?: number;
	isClear?: boolean;
	extraOptions?: any[],
};
const TimeslotDropdown = ({
	value,
	onChange,
	isRequired,
	placeholder,
	filterId,
	isClear,
	extraOptions,
}: TimeslotDropdownProps) => {
	const restaurant = useSelector((state: any) => state.auth.restaurant);
	const [result] = useGet(`/restaurants/${restaurant.id}/timeslots?all=1`);
	let data =
		result && result?.data?.length
			? result.data.map((item: any) => ({
					value: item.id,
					label: `${item.time.start} - ${item.time.end}`,
			  }))
			: [];
	if (filterId) {
		data = data.filter((d: any) => d.value !== Number(filterId));
	}
	return (
		<Dropdown
			placeholder={placeholder || "Tijdvak"}
			label="Tijdvak"
			value={value}
			onChange={onChange}
			data={[...data, ...(extraOptions || [])]}
			// search
			isRequired={isRequired}
			{...{ isClear }}
		/>
	);
};

export default TimeslotDropdown;

import React, { useState, useRef, useEffect } from "react";
import "./dropdown.scss";
import useBlur from "../../hooks/useBlur";
import Input from "../Input";

type Option = {
	value: any;
	label: string;
};

type DropdownProps = {
	label?: string;
	placeholder?: string;
	data: Option[];
	onChange: (id: any, item?: any) => void;
	value?: any;
	isRequired?: boolean;
	search?: boolean;
	isClear?: boolean;
};

const Dropdown = ({
	data,
	onChange,
	placeholder,
	value,
	isRequired,
	search,
	isClear,
}: DropdownProps) => {
	let item = data.find((i) => i.value === value);
	if (!item && placeholder) {
		item = {
			label: placeholder,
			value: null,
		};
	}
	const [query, setQuery] = useState("");
	const classes = ["dropdown"];
	const [visible, setVisible] = useState(false);
	const ref = useRef(null);
	useBlur(ref, () => setVisible(false));

	if (visible) classes.push("dropdown-open");
	if (value) classes.push("dropdown-value");
	if (isRequired) classes.push("dropdown-error");
	if (search) classes.push("dropdown-search");

	useEffect(() => {
		let item = data.find((i) => i.value === value);

		setQuery(item?.label || "");
	}, [data, value]);

	let items = data;
	if (query !== item?.label) {
		items = items.filter((i) =>
			i.label.toLowerCase().includes(query.toLowerCase())
		);
	}

	return (
		<div
			onClick={() => setVisible(!visible)}
			ref={ref}
			className={classes.join(" ")}
		>
			<div className="dropdown-placeholder">
				{search ? (
					<Input
						onChange={setQuery}
						value={query}
						placeholder={placeholder}
					/>
				) : (
					<span>{item?.label ? item.label : placeholder}</span>
				)}

				<div className="icon">
					{value && isClear && !visible ? (
						<i
							onClick={(e) => {
								onChange(undefined);
								if (e) e.stopPropagation();
							}}
							className={`fal fa-times`}
						></i>
					) : (
						<i
							className={`fal fa-angle-${
								visible ? "up" : "down"
							}`}
						></i>
					)}
				</div>
			</div>
			<div className="dropdown-options">
				{items.map((item: any) => (
					<div
						key={item.label + item.value}
						onClick={() => {
							onChange(item.value, item);
							if (search) {
								setQuery(item.label);
							}
							setVisible(false);
						}}
						className="dropdown-options-item"
					>
						{item.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default Dropdown;

import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout';
import TimeslotPickerV2 from '../../../components/TimeslotPickerV2';
import usePost from '../../../hooks/rest/usePost';
import { useAuth } from '../../../hooks/useAuth';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { toggleFullscreenLoader } from '../../../kit/FullscreenLoader';
import { toast } from '../../../kit/NotificationCenter';
import FullView from './FullView';
import './settings-view.scss';
import AccountInfo from './views/AccountInfo';
import AllowComment from './views/AllowComment';
import Contact from './views/Contact';
import Embed from './views/Embed';
import Faq from './views/Faq';
import Household from './views/Household';
import How from './views/How';
import MaxCapacity from './views/MaxCapacity';
import DietaryNeeds from './views/DietaryNeeds';
import Modus from './views/Modus';
import Notifications from './views/Notifications';
import PauseReservations from './views/PauseReservations';
import RestaurantProfile from './views/RestaurantProfile';
import ShareLink from './views/ShareLink';
import BlockPeriod from './views/BlockPeriod';

const settingsTitles: any = {
	account: 'Gegevens',
	comment: 'Opmerking toevoegen',
	embed: 'Reserveren via jouw website',
	faq: 'Veelgestelde vragen',
	how: 'Hoe werkt het?',
	household: 'Huishoud controle',
	modus: 'Reserverings modus',
	notifications: 'Reservering notificatie',
	pause: 'Vandaag online reserveren',
	'restaurant-profile': 'Gegevens & Profiel',
	'share-link': 'Reserveren via Social Media',
	'max-capacity': 'Max. aantal personen',
	'dietary-needs': 'Dieëtwensen',
	online: 'Tijdvakken instellen',
	block: 'Dag of periode beheren',
};

interface SettingsTabProps {
	icon: string,
	label: string,
	onClick?: (e: any) => void,
	to: string,
}

const SettingsTab: FC<SettingsTabProps> = ({ icon, label, onClick, to}): JSX.Element => {
	return (
		<Link
			to={`/dashboard/settings/${to}`}
			className="settings-tab"
			onClick={onClick}
		>
			<div>
				<i className={icon} />
			</div>
			<span>
				{label}
			</span>
			<i className="far fa-chevron-right" />
		</Link>
	);
}

const SettingsView: FC = (): JSX.Element => {
	const [ showPage, setShowPage ] = useState<boolean>(false);
	const [ title, setTitle ] = useState<string>('Instellingen');
	const [ requestPassword ] = usePost('/auth/password/create');
	const { page }: { page: string } = useParams();
	const auth = useAuth();
	const windowWidth = useWindowWidth();

	useEffect(() => {
		setShowPage(page !== undefined);
		setTitle(page === undefined ? 'Instellingen' : settingsTitles[page]);
	}, [page]);

	const sendNewPassword = (e?: any) => {
		if (!auth) return;
		if (e) e.preventDefault();

		toggleFullscreenLoader(true);
		requestPassword({ email: auth.email }).then(() => {
			toggleFullscreenLoader(false);
			toast({
				header: 'Gelukt!',
				content: 'U ontvangt een e-mail met een wijzig link.',
			});
		}).catch(() => {});
	};
	
	if (windowWidth > 992) {
		return <FullView sendNewPassword={sendNewPassword} />
	}

	return (
		<Layout
			className="settings-view"
			type="admin"
			title={title}
			back={showPage ? '/dashboard/settings' : undefined}
		>
			<div className="settings-header">
				Account
			</div>
			<SettingsTab
				icon="fal fa-user"
				label="Gegevens"
				to="account"
			/>
			<SettingsTab
				icon="fal fa-shield-check"
				label="Wachtwoord"
				onClick={sendNewPassword}
				to="account"
			/>

			<div className="settings-header">
				Online reserveren
			</div>
			<SettingsTab
				icon="fas fa-mobile-alt"
				label="Vandaag online reserveren"
				to="pause"
			/>
			<SettingsTab
				icon="far fa-minus-circle"
				label="Dag of periode beheren"
				to="block"
			/>
			<SettingsTab
				icon="far fa-home-lg-alt"
				label="Huishoud controle"
				to="household"
			/>
			<SettingsTab
				icon="far fa-comment-alt-lines"
				label="Opmerking toevoegen"
				to="comment"
			/>
			<SettingsTab
				icon="fal fa-leaf-heart"
				label="Dieëtwensen"
				to="dietary-needs"
			/>
			<SettingsTab
				icon="far fa-user-plus"
				label="Max. aantal personen"
				to="max-capacity"
			/>
			<SettingsTab
				icon="far fa-retweet"
				label="Reserverings modus"
				to="modus"
			/>

			<div className="settings-header">
				Meldingen
			</div>
			<SettingsTab
				icon="far fa-envelope-open"
				label="Reserveringen notificatie"
				to="notifications"
			/>

			<div className="settings-header">
				Mijn restaurant
			</div>
			<SettingsTab
				icon="far fa-presentation"
				label="Gegevens &amp; profiel"
				to="restaurant-profile"
			/>
			<SettingsTab
				icon="far fa-share-alt"
				label="Reserveren via Social Media"
				to="share-link"
			/>
			<SettingsTab
				icon="far fa-laptop-code"
				label="Reserveren via jouw website"
				to="embed"
			/>

			<div className="settings-header">
				Hulp nodig?
			</div>
			<SettingsTab
				icon="far fa-phone-laptop"
				label="Hoe werkt het?"
				to="how"
			/>
			<SettingsTab
				icon="far fa-question-circle"
				label="Veel gestelde vragen"
				to="faq"
			/>
			<SettingsTab
				icon="far fa-user-headset"
				label="Contact met Support"
				to="contact"
			/>

			<div className={`settings-page${showPage ? ' settings-page-show' : ''}`}>
				{page === 'account' && <AccountInfo />}
				{page === 'comment' && <AllowComment />}
				{page === 'contact' && <Contact />}
				{page === 'embed' && <Embed />}
				{page === 'faq' && <Faq />}
				{page === 'household' && <Household />}
				{page === 'how' && <How />}
				{page === 'modus' && <Modus />}
				{page === 'notifications' && <Notifications />}
				{page === 'pause' && <PauseReservations />}
				{page === 'restaurant-profile' && <RestaurantProfile />}
				{page === 'share-link' && <ShareLink />}
				{page === 'online' && <TimeslotPickerV2 />}
				{page === 'max-capacity' && <MaxCapacity />}
				{page === 'dietary-needs' && <DietaryNeeds />}
				{page === 'block' && <BlockPeriod />}
			</div>
		</Layout>
	);
}

export default SettingsView;

import { useState } from "react";
import axios from "axios";

const usePatch = (route?: string) => {
	const [loading, setLoading] = useState(false);

	const patchFunction = async (data: any) => {
		if (!route) return;
		setLoading(true);
		return axios
			.patch(route, data)
			.then(({ data }) => data)
			.finally(() => setLoading(false));
	};

	return [patchFunction, loading] as [(data: any) => Promise<any>, boolean];
};

export default usePatch;

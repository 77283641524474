import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../components/Card';
import Layout from '../../../components/Layout';
import TabNav from '../../../components/TabNav';
import { useChangeBtnLabel, useChangeNeeded } from '../../../hooks/useChange';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import Button from '../../../kit/Button';
import Modal from '../../../kit/Modal';
import AccountInfo from './views/AccountInfo';
import AllowComment from './views/AllowComment';
import Household from './views/Household';
import Modus from './views/Modus';
import Notifications from './views/Notifications';
import PauseReservations from './views/PauseReservations';
import RestaurantProfile from './views/RestaurantProfile';
import ShareLink from './views/ShareLink';
import Embed from './views/Embed';
import { useAuth } from '../../../hooks/useAuth';
import How from './views/How';
import Faq from './views/Faq';
import Contact from './views/Contact';
import TimeslotPickerV2 from '../../../components/TimeslotPickerV2';
import InvoicesView from '../../Account/InvoicesView';
import SubscriptionBox from '../../Account/SubscriptionBox';
import MaxCapacity from './views/MaxCapacity';
import DietaryNeeds from './views/DietaryNeeds';
import BlockPeriod from './views/BlockPeriod';

const tabs = [{
    to: '/dashboard/settings/online',
    label: 'Online reserveren',
    isActive: (loc: string) => loc === '/dashboard/settings' || loc === '/dashboard/settings/online',
}, {
    to: '/dashboard/settings/notifications',
    label: 'Meldingen',
}, {
    to: '/dashboard/settings/restaurant',
    label: 'Mijn restaurant',
}, {
    to: '/dashboard/settings/account',
    label: 'Account',
}, {
    to: '/dashboard/settings/help',
    label: 'Hulp nodig?',
}];

const labels: { [key: string]: string } = {
    info: 'Gegevens',
    profile: 'Gegevens & Profiel',
    share: 'Reserveren via Social Media',
    embed: 'Reserveren via jouw website',
    how: 'Hoe werkt het?',
    faq: 'Veelgestelde vragen',
    contact: 'Mailen',
    call: 'Bellen',
    invoices: 'Facturen',
    subscription: 'Abonnement',
    'dietary-needs': 'Dieëtwensen',
    'max-capacity': 'Max. aantal personen',
    'block': 'Dag of periode beheren',
}

const FullView: FC<{ sendNewPassword: () => void }> = ({ sendNewPassword }): JSX.Element => {
    const { page, modal }: { page: string, modal: string } = useParams();
    const history = useHistory();
    const auth = useAuth();
    const changeNeeded = useChangeNeeded();
    const windowWidth = useWindowWidth();
    const approveLabel = useChangeBtnLabel();

    useEffect(() => {
        if (windowWidth <= 992) {
            history.push('/dashboard/settings');
        }
    }, [windowWidth, history]);
    
    const handleClose = (): void => {
        dispatchEvent(new CustomEvent('changes-canceled'));
        const back = window.location.pathname.split(`/${modal}`)
        history.push(back[0]);
    }

    return (
        <Layout noLine title="Instellingen" type="admin">
            <TabNav routes={tabs} />
			<div className="settings-full">
                <Card>
                    {page === 'account' && (<>
                        <h3 className="sf-title">Account</h3>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Gegevens
                            </div>
                            <div className="sf-row-description">
                                Hier staan persoonlijke gegevens waarmee jij geregistreerd staat en tevens de contactgegevens voor EasyZeats.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/account/info"
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Wachtwoord
                            </div>
                            <div className="sf-row-description">
                                Met dit wachtwoord kan jij inloggen op jouw EasyZeats restaurant omgeving.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    onClick={sendNewPassword}
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                    </>)}
                    {(!page || page === 'online') && (<>
                        <h3 className="sf-title">Online reserveren</h3>
                        <PauseReservations />
                        <Household />
                        <AllowComment />
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Dag of periode beheren
                            </div>
                            <div className="sf-row-description">
                                Stel specifieke dagen of periodes in waarop je beschikbaar bent voor online reserveringen, naast je reguliere openingstijden, zodat jouw gasten gemakkelijk kunnen plannen.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/online/block"
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Dieetwensen
                            </div>
                            <div className="sf-row-description">
                                Intolerantie, Vegetarisch of een Allergie? Gasten kunnen tijdens het reserveren eenvoudig de dieetwens aanvinken.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/online/dietary-needs"
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Max. aantal personen
                            </div>
                            <div className="sf-row-description">
                                Wijzig het maximaal aantal personen waarvoor online een reservering geplaatst mag worden.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/online/max-capacity"
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                        <Modus />
                    </>)}
                    {page === 'notifications' && (<>
                        <h3 className="sf-title">Meldingen</h3>
                        <Notifications />
                    </>)}
                    {page === 'restaurant' && (<>
                        <h3 className="sf-title">Mijn restaurant</h3>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Gegevens &amp; profiel
                            </div>
                            <div className="sf-row-description">
                                Hier staan de gegevens en instellingen van jouw restaurant en zijn zichtbaar op jouw persoonlijke pagina.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/restaurant/profile"
                                    label="Wijzigen"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Reserveren via Social Media
                            </div>
                            <div className="sf-row-description">
                                Gebruik een unieke link waarmee gasten en bezoekers direct een reservering kunnen maken voor jouw restaurant.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/restaurant/share"
                                    label="Gebruiken"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Reserveren via jouw website
                            </div>
                            <div className="sf-row-description">
                                Gebruik een simpele HTML module waarmee gasten en bezoekers direct kunnen reservering via jouw website.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/restaurant/embed"
                                    label="Gebruiken"
                                />
                            </div>
                        </div>
                        <div className="sf-row sf-row--expected">
                            <div className="sf-row-label">
                                Faciliteiten
                            </div>
                            <div className="sf-row-description">
                                Selecteer hier de facaliteiten beschikbaar voor jouw gasten. Denk hierbij bijvoorbeeld aan WiFi of Toegankelijkheid.
                            </div>
                            <div className="sf-row-action">
                                <span>Wordt verwacht</span>
                            </div>
                        </div>
                        <div className="sf-row sf-row--expected">
                            <div className="sf-row-label">
                                Menu
                            </div>
                            <div className="sf-row-description">
                                Geef de gasten alvast een voorproefje door jouw menu online zichtbaar te maken voor het reserveren.
                            </div>
                            <div className="sf-row-action">
                                <span>Wordt verwacht</span>
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Facturen &amp; abonnement
                            </div>
                            <div className="sf-row-description">
                                {auth?.current_subscription && auth?.current_subscription.cycle_ends_at
                                    ? `Jouw Easyzeats abonnement wordt op ${moment(auth?.current_subscription?.cycle_ends_at || '').format('DD MMMM YYYY')} verlengd.`
                                    : 'Je maakt gebruik van een gratis Easyzeats account.'
                                }
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    label="Facturen"
                                    href="/dashboard/settings/restaurant/invoices"
                                />
                                <Button
                                    color="white"
                                    label="Abonnement"
                                    href="/dashboard/settings/restaurant/subscription"
                                />
                            </div>
                        </div>
                    </>)}
                    {page === 'help' && (<>
                        <h3 className="sf-title">Hulp nodig?</h3>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Hoe werkt het?
                            </div>
                            <div className="sf-row-description">
                                In korte videos van minder dan een minuut leggen wij uit hoe EasyZeats het makkelijk maakt voor jouw restaurant.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/help/how"
                                    label="Bekijken"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Veel gestelde vragen
                            </div>
                            <div className="sf-row-description">
                                EasyZeats staat achter het priciepe dat het zo makkelijk moet zijn dat vragen eigenlijk tot het verleden horen. Toch ontkomen wij er niet extra duidelijkheid te geven over bepaald onderwerpen.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/help/faq"
                                    label="Bekijken"
                                />
                            </div>
                        </div>
                        <div className="sf-row">
                            <div className="sf-row-label">
                                Contact met support
                            </div>
                            <div className="sf-row-description">
                                Wil je ons een compliment of tips geven? Dat mag natuurlijk altijd net zo als vragen stellen.
                            </div>
                            <div className="sf-row-action">
                                <Button
                                    color="white"
                                    href="/dashboard/settings/help/contact"
                                    label="Mailen"
                                />
                                <Button
                                    color="white"
                                    href="/dashboard/settings/help/call"
                                    label="Bellen"
                                />
                            </div>
                        </div>
                    </>)}
                </Card>
                <Modal
                    open={modal !== undefined && modal !== ''}
                    onClose={handleClose}
                    header={labels[modal || ''] || ''}
                    cancelBtn={changeNeeded === undefined
                        ? undefined
                        : 'Annuleren'
                    }
                    approveBtn={changeNeeded === undefined
                        ? undefined
                        : (changeNeeded === 'contact' ? "Versturen" : approveLabel)
                    }
                    onApprove={changeNeeded === undefined
                        ? undefined
                        : () => dispatchEvent(new CustomEvent('changes-saved', { detail: { onSuccess: handleClose }}))
                    }
                    size={/faq|how|timeslots|invoices/.test(modal) ? 'large' : undefined}
                    noButtons={changeNeeded === undefined}
                    preventClose={changeNeeded !== undefined}
                    fill={modal === 'timeslots'}
                >
                    {modal === 'timeslots' && <TimeslotPickerV2 />}
                    {modal === 'info' && <AccountInfo />}
                    {modal === 'profile' && <RestaurantProfile />}
                    {modal === 'share' && <ShareLink onCopy={handleClose} />}
                    {modal === 'embed' && <Embed onCopy={handleClose} />}
                    {modal === 'how' && <How />}
                    {modal === 'faq' && <Faq />}
                    {modal === 'contact' && <Contact onSend={handleClose} />}
                    {modal === 'invoices' && <InvoicesView />}
                    {modal === 'subscription' && <SubscriptionBox />}
                    {modal === 'max-capacity' && <MaxCapacity />}
                    {modal === 'dietary-needs' && <DietaryNeeds />}
                    {modal === 'block' && <BlockPeriod />}
                    {modal === 'call' && (<div>
                        <p className="modal-v2-text" style={{ marginBottom: 32 }}>
                            Wil je ons een compliment of tip geven? Dat mag natuurlijk altijd net zo als vragen stellen.
                        </p>
                        <p className="modal-v2-text">
                            Bereikbaar op: +31 (0)6 214 611 45
                        </p>
                    </div>)}
                </Modal>
            </div>
		</Layout>
    );
}

export default FullView;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./login-view.scss";
import StyledText from "../../../components/StyledText";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import usePost from "../../../hooks/rest/usePost";
import { updateProfile } from "../../../redux/auth";
import useAlert from "../../../hooks/useAlert";
import Logo from "../../../components/Logo";
import { LOGGEDIN_REDIRECT, LOGGEDIN_ADMIN_REDIRECT } from "../../../Routes";

const LoginView = () => {
	const [sendAlert] = useAlert();
	const dispatch = useDispatch();
	const history = useHistory();
	const [loginUser, loading] = usePost("/auth/login");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = (e?: any) => {
		if (e) {
			e.preventDefault();
		}
		if (!email || !password) {
			return;
		}
		loginUser({ email, password })
			.then(({ data }: any) => {
				if (data.id) {
					dispatch(updateProfile(data));
					history.push(
						data.is_admin
							? LOGGEDIN_ADMIN_REDIRECT
							: LOGGEDIN_REDIRECT
					);
				}
			})
			.catch((error) => {
				sendAlert({
					type: "error",
					title: "Er is iets misgegaan",
				});
			});
	};

	return (
		<AuthPage>
			<form onSubmit={handleLogin}>
				<Input
					type="email"
					placeholder="E-mailadres"
					onChange={setEmail}
					value={email}
				/>
				<Input
					type="password"
					placeholder="Wachtwoord"
					onChange={setPassword}
					value={password}
					className="no-margin"
				/>
				<Button
					className="forgot"
					to="/reset-password"
					type="transparent"
				>
					Wachtwoord vergeten
				</Button><span />
				<Button loading={loading} onClick={handleLogin}>
					Inloggen
				</Button>
				<div className="no-account-cta">
					<StyledText>Nog geen partner account?</StyledText>
					<Button to="/subscriptions" type="transparent">
						Word nu partner
					</Button>
				</div>
				<button style={{ display: "none" }} type="submit"></button>
			</form>
		</AuthPage>
	);
};

export const AuthPage = ({ children }: any) => (
	<div className="login-view">
		<Card type="no-shadow">
			<div className="login-view-logo">
				<Logo to="/" type="circle-white-stacked" />
				<span className="caption">Partner omgeving</span>
			</div>
			{children}
		</Card>
	</div>
);

export default LoginView;

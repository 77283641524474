import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise = undefined as any;
let STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
if (STRIPE_KEY) {
	stripePromise = loadStripe(STRIPE_KEY);
}

const StripeWrapper = ({ children }: { children: any }) => {
	if (stripePromise) {
		return <Elements stripe={stripePromise}>{children}</Elements>;
	}
	return children;
};

export default StripeWrapper;

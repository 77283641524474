import React, { useState } from "react";
import {
	IdealBankElement,
	useStripe,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import Card from "../../components/Card";
import Button from "../../components/Button";
import StripeWrapper from "../../lib/StripeWrapper";

type CheckoutProps = {
	plan: string;
	price: any;
};

export const IDEAL_OPTIONS = {
	style: {
		base: {
			padding: "15px 30px",
		},
	},
};

const Checkout = ({ plan, price }: CheckoutProps) => {
	const [loading, setLoading] = useState(false);
	const stripe = useStripe() as any;
	const [type, setType] = useState("ideal");
	const auth = useSelector((state: any) => state.auth);
	const [name] = useState(auth.full_name || "");

	const handleSubmit = async () => {
		setLoading(true);
		stripe
			.createSource({
				type: "ideal",
				amount: price * 100,
				currency: "eur",
				statement_descriptor: `EasyZeats Subscribtion: ${plan}`,
				owner: {
					name,
					email: auth.email,
				},
				redirect: {
					return_url: `${window.location.origin}/checkout/${plan}`,
				},
			})
			.then(({ source }: any) => {
				window.location = source.redirect.url;
			});
	};

	return (
		<Card type="no-padding" className="checkout">
			<div className="checkout-head">
				<CheckoutTab
					title="Ideal"
					icon="fab fa-ideal"
					onClick={() => setType("ideal")}
					active={type === "ideal"}
				/>
				{/* <CheckoutTab
					title="CreditCard"
					icon="fab fa-cc-mastercard"
					onClick={() => setType("creditcard")}
					active={type === "creditcard"}
				/> */}
			</div>
			<div className="checkout-body">
				{type === "ideal" && (
					<IdealBankElement
						options={IDEAL_OPTIONS as any}
						className="ideal-element"
					/>
				)}
				<div className="buttons">
					<Button
						{...{ loading }}
						disabled={!stripe}
						onClick={handleSubmit}
					>
						Afrekenen
					</Button>
				</div>
			</div>
		</Card>
	);
};

type CheckoutTabProps = {
	active: boolean;
	title: string;
	icon: string;
	onClick: () => void;
};

const CheckoutTab = ({ active, title, icon, onClick }: CheckoutTabProps) => {
	const classes = ["checkout-tab"];
	if (active) {
		classes.push("checkout-tab_active");
	}

	let className = classes.join(" ");

	return (
		<div {...{ className, onClick }}>
			<div className="icon">
				<i className={icon}></i>
			</div>
			<p className="label">{title}</p>
		</div>
	);
};

export default (props: CheckoutProps) => (
	<StripeWrapper>
		<Checkout {...{ ...props }} />
	</StripeWrapper>
);

import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import queryString from "query-string";
import "./restaurants-view.scss";
import Layout from "../../components/Layout";
import useGet, { usePagination } from "../../hooks/rest/useGet";
import { useParams } from "react-router-dom";
import Card from "../../components/Card";
import StyledText from "../../components/StyledText";
import RestaurantCardMinimal from "../../components/RestaurantCard/RestaurantCardMinimal";
import Loading from "../../components/Loading";
import CheckBox from "../../components/CheckBox";
import Button from "../../components/Button";

const RestaurantsView = () => {
	const { location } = useParams() as any;
	const [type, setType] = useState("");
	let [lat, lng] = location ? location.split(",") : ["", ""];
	let params = {
		category: type,
		lat,
		lng,
		page: 1,
	};
	const [visible, setVisible] = useState(false);
	const q = queryString.stringify(params);
	const [result, , error, next] = usePagination(`/restaurants?${q}`);
	const [loading, setLoading] = useState(true);
	const meta = result?.meta || null;
	const hasMore = meta && meta.current_page !== meta.last_page;
	const total = meta?.total || 0;

	useEffect(() => setVisible(false), [type]);
	useEffect(() => {
		if (result?.data || error) {
			setLoading(false);
		}
	}, [result, error]);

	return (
		<Layout
			isSearch
			error={error ? "Er is iets misgegeaan" : undefined}
			loading={loading}
			className=""
		>
			<div className="container">
				<section className="restaurants-view">
					<div className={`filter ${visible && "filter-visible"}`}>
						<div className="filter-button">
							<Button
								onClick={() => setVisible(!visible)}
								type="secondary"
								icon={`fa fa-${visible ? "times" : "filter"}`}
							>
								{visible ? "Sluiten" : "Filter"}
							</Button>
						</div>
						<div className="filter-items">
							<div className="filter-content">
								<div className="filter-item">
									<div className="restaurants-view-label">
										Type keuken
									</div>
									<TypeList value={type} onChange={setType} />
								</div>
							</div>
						</div>
					</div>

					<div className="overview">
						<div className="restaurants-view-label">
							<span className="secondary">{total}</span>{" "}
							{total === 1 ? "Restaurant" : "Restaurants"}{" "}
							gevonden
						</div>
						<List {...{ hasMore, result, next }} />
					</div>
				</section>
			</div>
		</Layout>
	);
};

const TypeList = ({
	value,
	onChange,
}: {
	value: any;
	onChange: (i: any) => void;
}) => {
	const { location } = useParams() as any;
	let [lat, lng] = location ? location.split(",") : ["", ""];
	let params = {
		lat,
		lng,
	};
	const [result] = useGet(`/categories?${queryString.stringify(params)}`);
	// const [showAll, setShowAll] = useState(false);

	const data = result?.data && result.data.length ? result.data : [];
	let filtered = data.filter((item: any) => item.restaurant_count);

	return (
		<ul className="filter-list">
			{filtered.map((item: any) => (
				<li
					onClick={() =>
						onChange(value === item.slug ? "" : item.slug)
					}
					key={item.id + item.slug}
				>
					<div className="label">
						<CheckBox
							value={value === item.slug}
							onChange={() => {}}
						/>
						<p className="name">{item.name}</p>
					</div>
					<span className="count">{item.restaurant_count}</span>
				</li>
			))}
			{/* <span onClick={() => setShowAll(!showAll)} className="more">
				Toon {!showAll ? "meer" : "minder"}
			</span> */}
		</ul>
	);
};

const List = ({ hasMore, result, next }: any) => (
	<InfiniteScroll
		pageStart={1}
		loadMore={() => next()}
		hasMore={hasMore}
		loader={
			<div className="loader" key={0}>
				<Loading />
			</div>
		}
	>
		{result.data && result.data.length ? (
			result.data.map((item: any) => (
				<RestaurantCardMinimal
					key={item.name + item.id}
					{...{ restaurant: item }}
				/>
			))
		) : (
			<Card>
				<StyledText>
					Er zijn op dit moment geen restaurants bij u in de buurt
				</StyledText>
			</Card>
		)}
	</InfiniteScroll>
);

export default RestaurantsView;

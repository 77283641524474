import React, { useEffect, useState } from "react";
import "./button.scss";
import { Link } from "react-router-dom";
import saveIcon from "../../assets/images/icons/save.svg";
import phoneIcon from "../../assets/images/icons/call-white.svg";
import refreshIcon from "../../assets/images/icons/refresh.svg";
import cancelIcon from "../../assets/images/icons/cancel.svg";

type ButtonProps = {
	children?: any;
	onClick?: () => void;
	to?: string;
	loading?: boolean;
	disabled?: boolean;
	type?: string;
	icon?: string;
	iconPosition?: string;
	className?: string;
	isSave?: boolean;
	isDelete?: boolean;
	isPhone?: boolean;
	isBlank?: boolean;
	isRefresh?: boolean;
	isPdf?: boolean;
	isFilter?: boolean;
	noUppercase?: boolean;
	isCancel?: boolean;
	light?: boolean;
	menu?: boolean;
	menuLabel?: string;
	menuIcon?: string;
	style?: any,
	onMenu?: (e: any, state: boolean) => void,
};

const Button = ({
	children,
	onClick,
	loading,
	type,
	to,
	icon,
	iconPosition,
	disabled,
	className,
	isSave,
	isDelete,
	isBlank,
	noUppercase,
	isPhone,
	isRefresh,
	isFilter,
	isCancel,
	light,
	menu,
	menuLabel,
	menuIcon,
	onMenu,
	style,
}: ButtonProps) => {
	const [ showMenu, setShowMenu ] = useState<boolean>(false);
	const classes = ["button"];
	if (className) classes.push(className);
	if (loading) classes.push("button-loading");
	if (disabled) classes.push("button-disabled");
	if (type) classes.push(`button-${type}`);
	if (!children) classes.push("button-empty");
	if (isSave || isFilter) classes.push("button-is-svg");
	if (isDelete) classes.push("button-is-delete");
	if (noUppercase) classes.push("button-no-uppercase");
	if (light) classes.push("button-light");
	if (menu) classes.push("button-has-menu");
	if (icon) {
		classes.push("button-has-icon");
		if (iconPosition === "right") {
			classes.push("button-icon-right");
		} else {
			classes.push("button-icon-left");
		}
	}

	useEffect(() => {
		const hideMenu = (e: any): void => {
			if (e.target.tagName !== 'SPAN') {
				setShowMenu(false);
			}
		}
		document.addEventListener('click', hideMenu, true);
		return () => document.removeEventListener('click', hideMenu, true);
	}, [menu]);

	const handleClick = (e: any) => {
		if (menu) {
			e.stopPropagation();
			const newState = !showMenu;
			setShowMenu(newState);
			if (onMenu) {
				const target = e.target
				setTimeout(() => onMenu(target, newState), 5);
			}
		} else if (onClick) {
			onClick();
		}
	}

	const renderChildren = () => {
		if (isSave) {
			return <img src={saveIcon} alt="" />;
		}
		if (isRefresh) {
			return <img src={refreshIcon} alt="" />;
		}
		if (isPhone) {
			return <img src={phoneIcon} alt="" />;
		}
		if (isCancel) return <img src={cancelIcon} alt="" />;
		if (isFilter)
			return (
				<img
					alt="filter"
					src={require("../../assets/images/dashboard-icons/filter.svg")}
				/>
			);

		if (icon) {
			if (iconPosition === "right") {
				return (
					<>
						<span className="spacer"></span>
						{children}
						<i className={icon}></i>
					</>
				);
			}
			return (
				<>
					<i className={icon}></i>
					{children}
					<span className="spacer"></span>
				</>
			);
		}

		if (menu) {
			return (<>
				<i className={menuIcon || "fas fa-ellipsis-h"}></i>
				{menuLabel && <span>{menuLabel}</span>}
				{showMenu && (
					<div
						className="button-menu"
						onClick={() => setTimeout(() => setShowMenu(false), 50)}
					>
						{children}
					</div>
				)}
			</>)
		}

		return children;
	};

	if (to) {
		if (isBlank || /^(tel:|mailto:)/.test(to)) {
			return (
				<a
					className={classes.join(" ")}
					href={to}
					rel="noopener noreferrer"
					target="_blank"
				>
					{loading ? (
						<i className="fal fa-spin fa-spinner-third"></i>
					) : (
						renderChildren()
					)}
				</a>
			);
		}
		return (
			<Link className={classes.join(" ")} to={to} style={style}>
				{loading ? (
					<i className="fal fa-spin fa-spinner-third"></i>
				) : (
					renderChildren()
				)}
			</Link>
		);
	}

	return (
		<div onClick={handleClick} className={classes.join(" ")}>
			{loading ? (
				<i className="fal fa-spin fa-spinner-third"></i>
			) : (
				renderChildren()
			)}
		</div>
	);
};

export default Button;

import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SwitchV2 from '../../../../components/Switch/SwitchV2';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangeNeeded, useChangesCanceled } from '../../../../hooks/useChange';
import Button from '../../../../kit/Button';
import Form from '../../../../kit/Form';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';
import { setChangeNeeded } from '../../../../redux/change';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';

const AccountInfo: FC = (): JSX.Element => {
	const [ state, setState] = useState<boolean>(true);
	const [ errors, setErrors ] = useState<any>({});
	const [ user, setUser ] = useState<any>({});
    const auth = useAuth();
	const dispatch = useDispatch();
	const [ patchProfile ] = usePatch('/auth/profile?validate=1');
	const windowWidth = useWindowWidth();
	const changeNeeded = useChangeNeeded();

	useEffect(() => {
        if (!auth) return;
        setState(auth.allow_email);
		setUser(auth);
    }, [auth]);

	const save = (): void => {
		toggleFullscreenLoader(true);
		setErrors({});
		patchProfile(user)
			.then(({ data }) => {
				toggleFullscreenLoader(false);
				dispatch(updateProfile(data));
				toast({
					header: 'Gelukt!',
					content: 'Uw account is aangepast.',
				});
				dispatch(setChangeNeeded(undefined));
			})
			.catch((err) => {
				toggleFullscreenLoader(false);
				dispatch(setChangeNeeded('notification-email'));
				setErrors(err.response.data.errors);
			});
	}

	useChangeCallback('notification-email', save, [user]);

	useChangesCanceled('notification-email', () => {
		setErrors({});
		setUser(auth || {});
	}, [auth]);

    const handleSwitch = (newState: boolean): void => {
        if (!auth) return;
        setState(newState);
        toast({
			header: 'Gelukt!',
			content: `U ontvangt vanaf nu ${newState ? '' : 'geen'} reservering notificaties ${newState ? 'op ' + auth.notification_email : 'meer'}.`,
		});

		patchProfile({ ...auth, allow_email: newState }).then(({ data }) => {
            dispatch(updateProfile(data))
        }).catch(() => {});
    }

	const handleInput = ({ name, value }: any) => {
		dispatch(setChangeNeeded('notification-email'));
		setUser({
			...user,
			[name]: value,
		});
	};

    return windowWidth <= 992 ? (<>
		<div className="sv-switch">
            <span className="switch-label" onClick={() => handleSwitch(!state)}>
				Reserveringen notificatie
            </span>
            <SwitchV2
                onChange={handleSwitch}
                state={state}
            />
        </div>
        <p className="sv-description">
			Ontvang een mail op onderstaande e-mailadres als er wijzigingen zijn of nieuwe reserveringen.
        </p>
        <Form.Input
			error={errors.notification_email}
			name="notification_email"
			onChange={handleInput}
			placeholder="E-mailadres"
			value={user.notification_email}
        />

		<Button
			block	
            color="grey"
            label="Meer informatie"
			style={{ marginTop: 32 }}
        />
    </>) : (<>
        <div className="sf-row">
            <div className="sf-row-label">
				Reserveringen notificatie
            </div>
            <div className="sf-row-description">
				Ontvang een mail op onderstaande e-mailadres als er wijzigingen zijn of nieuwe reserveringen.
				<div className="sf-rd-input">
					<div style={{ width: 300 }}>
						<Form.Input
							error={errors.notification_email}
							name="notification_email"
							onChange={handleInput}
							placeholder="E-mailadres"
							value={user.notification_email}
						/>
					</div>
					{changeNeeded && (
						<Button
							color="green"
							onClick={save}
							label="Opslaan"
						/>
					)}
				</div>
            </div>
            <div className="sf-row-action">
                <SwitchV2
                    onChange={handleSwitch}
                    state={state}
                />
            </div>
        </div>
    </>);
}

export default AccountInfo;

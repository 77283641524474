import axios from 'axios';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CheckBox from '../../../../components/CheckBox';
import { useChangeCallback } from '../../../../hooks/useChange';
import Form from '../../../../kit/Form';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { setChangeBtnLabel, setChangeNeeded } from '../../../../redux/change';

const DietaryNeeds: FC = (): JSX.Element => {
    const [ dietaryNeeds, setDietaryNeeds ] = useState<any[]>([]);
    const [ selected, setSelected ] = useState<number[]>([]);
    const [ edit, setEdit ] = useState<number | boolean>(false);
    const [ error, setError ] = useState<boolean>(false);
    const [ needName, setNeedName ] = useState<string>('');
    const dispatch = useDispatch();

    const reset = useCallback((): void => {
        setEdit(false);
        setError(false);
        setNeedName('');
        dispatch(setChangeNeeded('admin-add-dietary-needs'));
        dispatch(setChangeBtnLabel('Toevoegen'));
    }, [dispatch]);

    const fetch = (): void => {
        axios.get('/dietary-needs').then(({ data }) => {
            setDietaryNeeds(data.data);
        });
    }
    useEffect(() => {
        reset();
        fetch();
    }, [reset]);

    useEffect(() => {
        if (edit !== false) {
            dispatch(setChangeNeeded('admin-add-dietary-needs'));
        }
    }, [dispatch, edit]);

    useChangeCallback('admin-add-dietary-needs', () => {
        if (edit === false) {
		    setEdit(true);
        } else {
            if (needName === '') {
                setError(true);
            } else {
                setError(false);
                toggleFullscreenLoader(true);
                axios.post('/admin/dietary-needs', { name: needName }).then(() => {
                    reset();
                    toggleFullscreenLoader(false);
                    fetch();
                });
            }
        }
    });

    useChangeCallback('admin-delete-dietary-needs', () => {
		toggleFullscreenLoader(true);
        axios.post('/admin/dietary-needs/delete', { selected }).then(() => {
            reset();
            toggleFullscreenLoader(false);
            fetch();
        });
    });

    const handleCheck = (needId: number, state: boolean): void => {
        let newSelected = [ ...selected ];
        if (state) {
            newSelected.push(needId);
        } else {
            newSelected = newSelected.filter((o) => o !== needId);
        }

        setSelected(newSelected);
        
        if (newSelected.length > 0) {
            dispatch(setChangeNeeded('admin-delete-dietary-needs'));
            dispatch(setChangeBtnLabel('Verwijderen'));
        } else {
            reset();
        }
    }

    return edit !== false ? (<>
        <p className="sv-description">
            Voeg hieronder de beschikbare dieëtwensen en/of allergieën toe. Restaurants kunnen uit deze lijst kiezen welke opties de gast te zien krijgt bij het reserveren.
        </p>
        <Form.Input
            error={error}
            onChange={({ value }: any) => setNeedName(value)}
            value={needName}
        />
    </>) : (<>
		<p className="sv-description">
            Voeg hieronder de beschikbare dieëtwensen en/of allergieën toe. Restaurants kunnen uit deze lijst kiezen welke opties de gast te zien krijgt bij het reserveren.
        </p>
        <div style={{ marginTop: 32 }}>
            {dietaryNeeds.map((need: any) => (
                <div style={{ marginBottom: 16 }} key={`need-${need.id}`}>
                    <CheckBox
                        label={need.name}
                        onChange={(state) => handleCheck(need.id, state)}
                        value={selected.includes(need.id)}
                    />
                </div>
            ))}
        </div>
    </>);
}

export default DietaryNeeds;

import React, { FC } from 'react';
import AccordionItem from '../../../../kit/AccordionItem';

const How: FC = (): JSX.Element => {
    return (
        <div>
            <AccordionItem
                title="Een reservering maken en wijzigen"
                content={<>
                    <p>In deze video laten we je zien hoe gasten reserveren en hoe deze reservering gemakkelijk te wijzigen is.</p>
                    <div style={{ margin: '32px 0 0 0' }}>
                        <div className="embed">
                            <iframe src={`https://www.youtube-nocookie.com/embed/${''}`} title="YouTube video player" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </>}
            />
            <AccordionItem
                title="Stel online beschikbaarheid in"
                content={<>
                    <p>In deze video laten we je zien hoe gasten reserveren en hoe deze reservering gemakkelijk te wijzigen is.</p>
                    <div style={{ margin: '32px 0 0 0' }}>
                        <div className="embed">
                            <iframe src={`https://www.youtube-nocookie.com/embed/${''}`} title="YouTube video player" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </>}
            />
            <AccordionItem
                title="Een tijdvak aanmaken voor Online Reserveren"
                content={<>
                    <p>In deze video laten we je zien hoe gasten reserveren en hoe deze reservering gemakkelijk te wijzigen is.</p>
                    <div style={{ margin: '32px 0 0 0' }}>
                        <div className="embed">
                            <iframe src={`https://www.youtube-nocookie.com/embed/${''}`} title="YouTube video player" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </>}
            />
        </div>
    );
}

export default How;

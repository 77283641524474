import findIndex from "lodash/findIndex";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Table from "../../../../kit/Table";
import SwitchV2 from "../../../../components/Switch/SwitchV2";
import axios from "../../../../lib/bootstrap";
import Form from "../../../../kit/Form";

export const debounce = (callback: Function, delay = 500): Function => {
	let timeoutId: ReturnType<typeof setTimeout>;
	return function (this: any, ...args: any[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => callback.apply(this, args), delay);
	};
}

const FourSquareListView = () => {
	const [ restaurants, setRestaurants ] = useState<any[]>([]);
	const [ meta, setMeta ] = useState<any>({});
	const [ show, setShow ] = useState<string>('');
    const queryRef = useRef<string>('');

    const fetch = useCallback((page: number = 1): void => {
        axios.get(`admin/fsq?query=${queryRef.current}&page=${page}${show === '' ? '' : `&show=${show}`}`).then(({ data }) => {
            setRestaurants(data.data);
			setMeta(data.meta);
        });
    }, [show]);
    useEffect(() => {
        fetch();
    }, [fetch]);

	const search = debounce(() => {
		fetch(1);
	});

	const handleShow = (restaurant: any, state: boolean): void => {
		const newRestaurants = [ ...restaurants ];
		const index = findIndex(newRestaurants, { id: restaurant.id });
		if (index === -1) return;
		axios.post(`admin/fsq/${restaurant.id}/toggle`, { state });
		newRestaurants[index].show_in_list = state;
		setRestaurants(newRestaurants);
	}

    return (<>
		<div className="fsq-search">
			<i className="fal fa-search" />
			<input
				onChange={(e: any) => {
					queryRef.current = e.target.value;
					search();
				}}
				style={{ flex: 1 }}
			/>
			<div>
				<Form.Dropdown
					options={[{
						text: 'Toon alles',
						value: '',
					}, {
						text: 'Toon Aan',
						value: '1',
					}, {
						text: 'Toon uit',
						value: '0'
					}]}
					onChange={({ value }) => setShow(value)}
					value={show}
				/>
			</div>
		</div>
		<Table>
			<thead>
				<Table.Row>
					<Table.HeaderCell align="left">Restaurant ({meta.total})</Table.HeaderCell>
					<Table.HeaderCell align="left">Adres</Table.HeaderCell>
					<Table.HeaderCell align="left">Categorieën</Table.HeaderCell>
					<Table.HeaderCell align="left" collapsing>Toon in lijst</Table.HeaderCell>
				</Table.Row>
			</thead>
			<tbody>
				{restaurants.map((restaurant) => (
					<Table.Row key={restaurant.id}>
						<Table.Cell primary name="Resturant">
							{restaurant.name}<br />
                            {restaurant.website && (
                                <small><a href={restaurant.website} target="_blank">{restaurant.website}</a> | </small>
                            )}<small style={{ color: '#a9a9a9' }}>{restaurant.fsq_id}</small>
						</Table.Cell>
						<Table.Cell name="Adres">
                            {restaurant.street} {restaurant.house_number}<br />
                            {restaurant.zipcode} {restaurant.city}
                        </Table.Cell>
						<Table.Cell name="Categorieën">
							{restaurant.fsq_categories.map((o: any) => o.name).join(', ')}
						</Table.Cell>
						<Table.Cell actions>
							<div style={{ display: 'flex' }}>
								<SwitchV2
                                    onChange={(state) => handleShow(restaurant, state)}
                                    state={restaurant.show_in_list}
                                />
							</div>
						</Table.Cell>
					</Table.Row>
				))}
			</tbody>
		</Table>
	</>);
};


export default FourSquareListView;

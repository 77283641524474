import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setChangeBtnLabel, setChangeNeeded } from '../../../../redux/change';
import { useChangeCallback } from '../../../../hooks/useChange';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import axios from 'axios';
import Form from '../../../../kit/Form';
import Button from '../../../../kit/Button';
import { toast } from '../../../../kit/NotificationCenter';

const FourSquareFilterEditView: FC<{ id?: string }> = ({ id }): JSX.Element => {
    const [ categories, setCategories ] = useState<any[]>([]);
    const [ fsqFilter, setFsqFilter ] = useState<any>({
        name: '',
        type: 1,
        options: [{
            type: 1,
            category: undefined,
        }],
    });
    const [ errors, setErrors ] = useState<any>({});
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            axios.get(`/admin/fsq/filters/${id}`).then(({ data }) => {
                setFsqFilter(data.data);
            });
        }
        axios.get('/admin/fsq/filters/categories').then(({ data }) => {
            setCategories(data.map((o: any) => ({
                text: `(${o.id}) ${o.name}`,
                value: o.id,
            })));
        });
    }, [id]);

    useEffect(() => {
        dispatch(setChangeNeeded('admin-add-fsq-filter'));
        dispatch(setChangeBtnLabel(id ? 'Opslaan' : 'Toevoegen'));
    }, [id, fsqFilter]);

    const reset = useCallback((): void => {
        setErrors({});
    }, [dispatch]);

    useChangeCallback('admin-add-fsq-filter', () => {
        setErrors({});
        toggleFullscreenLoader(true);
        axios.post(`/admin/fsq/filters${id ? `/${id}` : ''}`, fsqFilter).then(() => {
            reset();
            toggleFullscreenLoader(false);
            toast({
                header: 'Gelukt!',
                content: 'De filter is opgeslagen.',
            });
            history.push('/admin/fsq-filters');
        }).catch((err) => {
            console.log(err);
        });
    }, [id, history]);

    const handleOption = (index: number, name: string, value: any): void => {
        const newOptions = [ ...fsqFilter.options ];
        newOptions[index][name] = value;
        setFsqFilter({
            ...fsqFilter,
            options: newOptions,
        });
    }

    const addOption = (): void => {
        setFsqFilter({
            ...fsqFilter,
            options: [
                ...fsqFilter.options,
                { type: 1, category: undefined },
            ],
        });
    }

    const removeOption = (index: number): void => {
        setFsqFilter({
            ...fsqFilter,
            options: fsqFilter.options.filter((o: any, i: number) => i !== index),
        });
    }

    return (<>
        <Form.Input
            error={errors.name}
            label="Naam"
            onChange={({ value }: any) => setFsqFilter((f: any) => ({ ...f, name: value }))}
            required
            value={fsqFilter.name}
        />
        <Form.Dropdown
            label="Type"
            required
            onChange={({ value }: any) => setFsqFilter((f: any) => ({ ...f, type: value }))}
            options={[{
                text: 'Altijd aan',
                value: 1,
            }, {
                text: 'Altijd uit',
                value: 0,
            }]}
            value={fsqFilter.type}
        />
        <Form.Group label="Regel(s)" required style={{ paddingBottom: 50 }}>
            {fsqFilter.options.map((option: any, index: number) => (
                <div
                    className="fsq-filter-rule"
                    key={`option-${index}`}
                >
                    <div>
                        Bevat
                        <Form.Dropdown
                            onChange={({ value }: any) => handleOption(index, 'type', value)}
                            options={[{
                                text: 'wel',
                                value: 1,
                            }, {
                                text: 'niet',
                                value: 0,
                            }]}
                            value={option.type}
                        />
                        de categorie:
                    </div>
                    <Form.Dropdown
                        options={categories}
                        onChange={({ value }: any) => handleOption(index, 'category', value)}
                        value={option.category}
                    />
                    {index !== 0 && (
                        <Button onClick={() => removeOption(index)}>
                            <i className="fal fa-trash-alt" />
                        </Button>
                    )}
                </div>
            ))}
            <Button
                color="white"
                onClick={() => addOption()}
            >
                <i className="fal fa-plus" />
            </Button>
        </Form.Group>
    </>);
}

export default FourSquareFilterEditView;

import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../../../../components/Input';
import usePatch from '../../../../hooks/rest/usePatch';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { toast } from '../../../../kit/NotificationCenter';
import { updateProfile } from '../../../../redux/auth';
import { setChangeNeeded } from '../../../../redux/change';

const MaxCapacity: FC = (): JSX.Element => {
    const [ state, setState ] = useState<number>(20);
    const [ patchProfile ] = usePatch('/auth/profile');
    const auth = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth || !auth.restaurant) return;
        setState(auth.restaurant.settings
            ? auth.restaurant.settings.max_capacity
            : 20
        );
    }, [auth]);

    useChangeCallback('max-capacity', (onSuccess) => {
        if (!auth) return;
		toggleFullscreenLoader(true);
		patchProfile({
            restaurant: {
                ...auth.restaurant,
                settings: {
                    ...(auth.restaurant?.settings || {}),
                    max_capacity: state,
                }
            }
        }).then(({ data }) => {
            toggleFullscreenLoader(false);
            dispatch(updateProfile(data));
            toast({
                header: 'Gelukt!',
                content: 'Uw account is aangepast.',
            });
            if (onSuccess) {
                onSuccess();
            }
        })
        .catch(() => {
            toggleFullscreenLoader(false);
            dispatch(setChangeNeeded('max-capacity'));
        });
	}, [auth]);

	useChangesCanceled('max-capacity', () => {
		if (!auth || !auth.restaurant) return;
        setState(auth.restaurant.settings
            ? auth.restaurant.settings.max_capacity
            : 20
        );
	}, [auth]);

    const handleInput = (newState: string): void => {
        setState(parseInt(newState));
        dispatch(setChangeNeeded('max-capacity'));
    };

    return (<>
		<p className="sv-description">
            Stel hier onder het maximaal aantal personen in waarvoor online een reservering geplaatst mag worden.
        </p>
        <div style={{ marginTop: 32 }}>
            <Input
                type="number"
                value={state}
                placeholder="20"
                increments
                onChange={handleInput}
            />
        </div>
    </>);
}

export default MaxCapacity;

import React, { FC, useEffect, useState, useCallback } from 'react';
import { find } from 'lodash';
import useDelete from '../../hooks/rest/useDelete';
import useGet from '../../hooks/rest/useGet';
import ReservationTable from '../../views/Dashboard/ReservationsView/ReservationTable';
import Button from '../Button';
import TimeslotDropdown from '../Dropdown/TimeslotDropdown';
import Input from '../Input';
import Modal from '../Modal';

import StyledText from '../StyledText';
import Switch from '../Switch';
import './action-modal.scss';
import usePost from '../../hooks/rest/usePost';
import axios from 'axios';

interface ActionModalProps {
    onClose: (rerun?: boolean) => void,
    visible: boolean,
    type: 'delete' | 'edit',
    timeslot: any,
    fetchedAffected: any[],
    forceAffected?: boolean,
}

const ActionModal: FC<ActionModalProps> = ({ visible, onClose, type, timeslot, fetchedAffected, forceAffected }): JSX.Element => {
    const [ affected, setAffected ] = useState<number>(timeslot.futureReservations);
    const [ step2, setStep2 ] = useState<string>('');
    const [ noCanDo, setNoCanDo ] = useState<number>(0);
    const [ subStep, setSubStep ] = useState<number>(1);
    const [ params, setParams ] = useState<any>({alert: true});
    const [ selection, setSelection ] = useState<number[]>([]);
    const [ page, setPage ] = useState<number>(1);
    const [ deleteTimeslot ] = useDelete(
        `/restaurants/${timeslot.restaurant.id}/timeslots/${timeslot.id}`
    );
    const [ doTimeslotActions ] = usePost(
        `/restaurants/${timeslot.restaurant.id}/timeslots/${timeslot.id}/do-actions`
    );
    const [result, loading, , refetch] = useGet(
        `/restaurants/${timeslot.restaurant.id}/timeslots/${timeslot.id}/action-required`,
        `type=${type}${forceAffected ? `&force=${fetchedAffected.map((o) => o.id).join(',')}` : ''}`,
    );

    const filterEdit = useCallback((o: any) => {
        if (type === 'edit') {
            return find(fetchedAffected, { id: o.id }) !== undefined;
        }
        return true;
    }, [type, fetchedAffected]);

    useEffect(() => {
        if (forceAffected) {
            setAffected(fetchedAffected.length);
        }
    }, [fetchedAffected, forceAffected]);

    useEffect(() => {
        const reset = (): void => {
            refetch();
        }

		window.addEventListener('reservation-refresh', reset, true);
		return () => {
			window.removeEventListener('reservation-refresh', reset, true);
		}
	}, [refetch])

    useEffect(() => {
        if (visible && (forceAffected ? fetchedAffected.length : affected) <= 0) {
            onClose(true);
        }
    }, [affected, visible, fetchedAffected, forceAffected]); // eslint-disable-line

    useEffect(() => {
        if (result.data) {
            setNoCanDo(0);
            setAffected(result.data.filter(filterEdit).length);
        }
    }, [result, filterEdit]);

    useEffect(() => {
        setSubStep(1);

        if (step2 === 'cancel') {
            setParams((p: any) => ({ ...p, timeslot: 'cancel' }));
        } else if (step2 === 'move') {
            setParams((p: any) => ({ ...p, timeslot: '' }));
        } else if (step2 === '') {
            setSelection([]);
        }
    }, [step2]);

    const checkForMore = (timeslotId: any): void => {
        axios.post(`/restaurants/${timeslot.restaurant.id}/timeslots/${timeslotId}/check-changes-for-these`, {
            checkThese: selection.length > 0 ? selection : fetchedAffected.map(o => o.id),
        }).then(({ data }) => {
            setNoCanDo(data.length);
        });
    }

    const handleOnClose = (reRun?: boolean, close?: boolean) => {
        setStep2('');
        setSubStep(1);
        setParams({ alert: true });
        setSelection([]);
        setNoCanDo(0);
        dispatchEvent(new CustomEvent('update-profile'));
        if (close !== false) {
            onClose(reRun);
        }
    }

    const handleConfirm = () => {
        if (step2 === 'cancel' || step2 === 'move') {
            if (type === 'delete') {
                deleteTimeslot({
                    timeslot: params.timeslot,
                    alert: params.alert || false,
                    comment: params.comment || '',
                    selection,
                }).then(() => {
                    dispatchEvent(new CustomEvent('update-profile'));
                    if (selection.length > 0 && selection.length !== affected) {    
                        refetch();
                        handleOnClose(undefined, false);
                    } else {
                        handleOnClose(true);
                    }
                });
            } else {
                doTimeslotActions({
                    timeslot: params.timeslot,
                    alert: params.alert || false,
                    comment: params.comment || '',
                    affected: fetchedAffected,
                    selection,
                }).then(() => {
                    dispatchEvent(new CustomEvent('update-profile'));
                    if (selection.length > 0 && selection.length !== affected) {    
                        refetch();
                        handleOnClose(undefined, false);
                    } else {
                        handleOnClose(true);
                    }
                });
            }
        }
    }

    const directAction = () => {
        if (type === 'delete') {
            deleteTimeslot({
                timeslot: 'cancel',
                alert: false,
                comment: '',
                setAction: true,
            }).then(() => {
                dispatchEvent(new CustomEvent('update-profile'));
                handleOnClose(true);
            });
        } else {
            doTimeslotActions({
                timeslot: 'cancel',
                alert: false,
                comment: '',
                affected: fetchedAffected,
                setAction: true,
            }).then(() => {
                handleOnClose(true);
            });
        }
    }

    return (
        <Modal
            type={step2 === '' || step2 === 'view' ? 'large' : 'small'}
            title={type === 'delete' ? 'Tijdvak verwijderen' : 'Tijd wijzigen'}
            visible={visible}
            onClose={handleOnClose}
        >
            {noCanDo > 0 ? (
                <div className="action-modal-alert">
                    <i className="far fa-exclamation-triangle"></i>
                    LET OP! Er zijn <b>{noCanDo}</b> van de <b>{selection.length > 0 ? selection.length : affected}</b> reserveringen die niet passen in het gekozen tijdvak. Indien je doorgaat, zijn deze terug te vinden onder <b>Actie vereist</b>.
                </div>
            ) : (
                selection.length > 0 && step2 !== 'view' ? (
                    <div className="action-modal-alert">
                        <i className="far fa-exclamation-triangle"></i>
                        LET OP! U gaat <b>{selection.length}</b> van de <b>{affected}</b> reservaringen {step2 === 'cancel' ? 'annuleren' : 'verplaatsen'} voor dit tijdvak.
                    </div>
                ) : (
                    <div className="action-modal-alert">
                        <i className="far fa-exclamation-triangle"></i>
                        LET OP! Er {affected === 1 ? 'is' : 'zijn'} <b>{affected}</b> reservering{affected === 1 ? '' : 'en'} voor dit tijdvak. Wat wil je met deze reserveringen doen?
                    </div>
                )
            )}

            {step2 === '' && (
                <div className="action-modal-options">
                    <div>
                        <div className="action-modal-option" onClick={() => setStep2('move')}>
                            <div>
                                <div className="action-modal-option-icon">
                                    <i className="fad fa-expand-arrows-alt"></i>
                                </div>
                                <StyledText>
                                    Verplaats de reserveringen naar een ander tijdvak en stuur jouw gasten wanneer nodig automatisch een e-mail.
                                </StyledText>
                            </div>
                            <Button light>
                                Verplaatsen
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="action-modal-option" onClick={() => setStep2('cancel')}>
                            <div>
                                <div className="action-modal-option-icon">
                                    <i className="fad fa-ban"></i>
                                </div>
                                <StyledText>
                                    Annuleer de reserveringen en stuur jouw gasten wanneer nodig automatisch een e-mail.
                                </StyledText>
                            </div>
                            <Button light>
                                Annuleren
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="action-modal-option" onClick={() => setStep2('view')}>
                            <div>
                                <div className="action-modal-option-icon">
                                    <i className="fad fa-eye"></i>
                                </div>
                                <StyledText>
                                    Bekijk de reservering{affected === 1 ? '' : `en`} direct en neem actie waar nodig.
                                </StyledText>
                            </div>
                            <Button light>
                                Bekijken
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="action-modal-option" onClick={() => directAction()}>
                            <div>
                                <div className="action-modal-option-icon">
                                    <i className="fad fa-clipboard-list-check"></i>
                                </div>
                                <StyledText>
                                    {type === 'delete' ? 'Verwijder het tijdvak' : 'Werk het tijdvak bij'} en bekijk de reserveringen op een later moment onder de tab <span style={{ color: '#C13030' }}>Actie vereist</span>.
                                </StyledText>
                            </div>
                            <Button light>
                                Later bekijken
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {(step2 === 'cancel' || step2 === 'move') && (
                <div className="action-modal-step2">
                    <div className="acms-icon">
                        <i className={`fad ${step2 === 'move' ? 'fa-expand-arrows-alt' : 'fa-ban'}`}></i>
                    </div>
                    <div>
                        {step2 === 'move' ? (
                            <div className="acms-steps">
                                <div className={`${subStep >= 1 ? 'active' : ''} ${subStep > 1 ? 'done' : ''}`}>
                                    {subStep > 1 ? <i className="far fa-check"></i> : 1}
                                </div>
                                <div className={`${subStep >= 2 ? 'active' : ''} ${subStep > 2 ? 'done' : ''}`}>
                                    {subStep > 2 ? <i className="far fa-check"></i> : 2}
                                </div>
                                <div className={`${subStep >= 3 ? 'active' : ''}`}>
                                    3
                                </div>
                            </div>
                        ) : (
                            <div className="acms-steps">
                                <div className={`${subStep >= 1 ? 'active' : ''} ${subStep > 1 ? 'done' : ''}`}>
                                    {subStep > 1 ? <i className="far fa-check"></i> : 1}
                                </div>
                                <div className={`${subStep >= 2 ? 'active' : ''}`}>
                                    2
                                </div>
                            </div>
                        )}

                        {step2 === 'move' && subStep === 1 && (
                            <div className="acms-dropdown">
                                <TimeslotDropdown
                                    filterId={timeslot.id}
                                    placeholder="Kies een tijdvak"
                                    value={params.timeslot}
                                    onChange={(value, item) => {
                                        checkForMore(value);

                                        setParams({
                                            ...params,
                                            timeslot: value,
                                            label: item.label,
                                        });
                                    }}
                                />
                            </div>
                        )}
                        {((step2 === 'move' && subStep === 2) || (step2 === 'cancel' && subStep === 1))  && (
                            <div className="acms-dropdown" style={{ maxWidth: 460 }}>
                                <Switch
                                    onChange={(value) =>
                                        setParams({
                                            ...params,
                                            alert: value,
                                        })
                                    }
                                    value={params.alert}
                                    label={`Gasten op de hoogte brengen van de reservering ${step2 === 'move' ? 'wijziging' : 'annulering'}?`}
                                    aan="Ja"
                                    uit="Nee"
                                />
                                {params.alert && <div style={{ marginTop: 20 }}>
                                    <Input
                                        multiline
                                        placeholder="Stuur een extra bericht mee in de e-mail."
                                        value={params.comment || ''}
                                        onChange={(comment) => setParams({
                                            ...params,
                                            comment,
                                        })}
                                    />
                                </div>}
                            </div>
                        )}
                        {((step2 === 'move' && subStep === 3) || (step2 === 'cancel' && subStep === 2)) && (
                            <div className="acms-dropdown" style={{ maxWidth: 460 }}>
                                <div className="acms-dropdown" style={{ maxWidth: '100%', textAlign: 'center' }}>
                                    <StyledText>Laatste controle</StyledText>
                                    <div style={{ marginTop: 15 }}>
                                        <StyledText>
                                            {step2 === 'move' && <span>
                                                <i className="far fa-clock"></i>
                                                {params.label}
                                            </span>}
                                            <span>
                                                <i className="fal fa-envelope-open-text"></i>
                                                {params.alert ? 'Ja' : 'Nee'}
                                            </span>
                                        </StyledText>
                                    </div>
                                </div>
                                {params.alert && <div style={{ marginTop: 20 }}>
                                    <Input
                                        readOnly
                                        multiline
                                        value={params.comment || ''}
                                        onChange={() => {}}
                                    />
                                </div>}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {step2 === 'view' && (<>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        disabled={selection.length <= 0}
                        type="white-shadow"
                        menu
                        menuLabel="Groep actie"
                        menuIcon="fas fa-check"
                    >
                        <a href="/" onClick={(e) => { e.preventDefault(); setStep2('move')}}>
                            <i className="far fa-expand-arrows-alt"></i>
                            <span>Verplaatsen</span>
                        </a>
                        <a href="/" onClick={(e) => { e.preventDefault(); setStep2('cancel')}}>
                            <i className="far fa-times"></i>
                            <span>Annuleren</span>
                        </a>
                    </Button>
                </div>
                <div>
                    <ReservationTable
                        data={result.data ? result.data.filter(filterEdit) : []}
                        loading={loading}
                        type="all"
                        showStatus={type === 'edit' ? fetchedAffected : undefined}
                        onSelect={(s) => setSelection(s)}
                        onNextPage={(p) => setPage(p)}
                        currentPage={page}
                    />
                </div>
            </>)}

            {step2 !== '' ? (
                <div className="action-modal-buttons">
                    <Button
                        onClick={() => {
                            if (subStep === 1) {
                                setStep2('');
                            } else {
                                setSubStep(subStep - 1);
                            }
                        }}
                        type="grey-shadow"
                        light
                    >
                        <i className="fas fa-arrow-left"></i>
                        <span>Terug</span>
                    </Button>
                    {step2 !== 'view' && (
                        ((step2 === 'move' && subStep === 3) || (step2 === 'cancel' && subStep === 2)) ? (
                            <Button onClick={() => handleConfirm()}>
                                <span>Tijdvak {type === 'delete' ? 'verwijderen' : 'verplaatsen'}</span>
                                <i className="fas fa-check"></i>
                            </Button>
                        ) : (
                            <Button onClick={() => setSubStep(subStep + 1)} disabled={!params.timeslot}>
                                <span>Volgende</span>
                                <i className="fas fa-arrow-right"></i>
                            </Button>
                        )
                    )}
                </div>
            ) : (
                <div className="action-modal-buttons">
                    <Button
                        className="button-transparent"
                        to="/need-help"
                        type="link"
                        light
                    >
                        Hulp
                    </Button>
                    <Button
                        className="button-transparent"
                        onClick={() => handleOnClose()}
                        light
                    >
                        Annuleren
                    </Button>
                </div>
            )}
        </Modal>
    );
}

export default ActionModal;

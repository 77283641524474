import { useState } from "react";
import axios from "axios";

const usePost = (route: string) => {
	const [loading, setLoading] = useState(false);

	const postFunction = async (data: any) => {
		setLoading(true);
		return axios
			.post(route, data)
			.then(({ data }) => data)
			.finally(() => setLoading(false));
	};

	return [postFunction, loading] as [(data: any) => Promise<any>, boolean];
};

export default usePost;

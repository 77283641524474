import React, { ChangeEvent, createRef, FC, MouseEvent, RefObject, useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface MediaUploadProps {
    media?: string,
    onChange: (file: File) => void,
    onDelete?: () => void,
    square?: boolean,
}

const MediaUpload: FC<MediaUploadProps> = ({ media, onChange, onDelete, square }): JSX.Element => {
    const [ file, setFile ] = useState<File>();
    const [ preview, setPreview ] = useState<string>(media || '');
    const inputRef: RefObject<HTMLInputElement> = createRef();

    useEffect(() => {
        setFile(undefined);
        setPreview(media || '');
    }, [media]);

    useEffect(() => {
        if (!file) return;
        const reader = new FileReader();
        reader.onload = () => {
            setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
    }, [file]);

    const handleDelete = (e: MouseEvent): void => {
        e.stopPropagation();
        setFile(undefined);
        setPreview('');
        if (onDelete) {
            onDelete();
        }
    }

    const handleFile = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.currentTarget.files) {
            const f = e.currentTarget.files[0];
            setFile(f);
            onChange(f);
        }
    }

    const triggerUpload = (): void => {
        if (!inputRef.current) return;
        inputRef.current.click();
    }

    return (
        <div
            className={`${styles.container} ${square || preview !== '' ? styles.square : ''}`}
            onClick={triggerUpload}
            style={{ paddingBottom: square ? '100%' : 'inherit' }}
        >
            <input
                accept="image/*"
                onChange={handleFile}
                ref={inputRef}
                type="file"
            />
            {preview === '' && (
                <div className={styles.placeholder}>
                    <i className="fas fa-image" />
                </div>
            )}
            {preview !== '' && (<>
                <div
                    className={styles.preview}
                    style={{
                        backgroundImage: `url(${preview})`,
                        backgroundSize: square ? 'cover' : 'contain',
                        paddingBottom: square ? '100%' : 'inherit',
                    }}
                >
                    {!square && <img src={preview} alt="" style={{ opacity: 0, maxWidth: '100%' }} />}
                </div>
                <div
                    className={styles.deleteBtn}
                    onClick={handleDelete}
                >
                    <i className="far fa-trash-alt" />
                </div>
            </>)}
        </div>
    );
}

export default MediaUpload;

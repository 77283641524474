import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import usePost from '../../../../hooks/rest/usePost';
import { useAuth } from '../../../../hooks/useAuth';
import { useChangeCallback, useChangesCanceled } from '../../../../hooks/useChange';
import Form from '../../../../kit/Form';
import { toggleFullscreenLoader } from '../../../../kit/FullscreenLoader';
import { toast } from '../../../../kit/NotificationCenter';
import { setChangeNeeded } from '../../../../redux/change';

const Contact: FC<{ onSend?: () => void }> = ({ onSend }): JSX.Element => {
    const auth = useAuth();
	const dispatch = useDispatch();
	const [ errors, setErrors ] = useState<any>({});
	const [ info, setInfo ] = useState<any>({});
	const [ postMessage ] = usePost('/contact?validate=1');

	useChangeCallback('contact', () => {
		toggleFullscreenLoader(true);
		setErrors({});
		postMessage(info).then(() => {
			toggleFullscreenLoader(false);
			toast({
				header: 'Bedankt voor uw bericht!',
				content: 'Wij proberen uw bericht zsm te beantwoorden.',
				onTop: true,
			});
			setInfo({
				name: auth?.first_name || '',
				email: auth?.email || '',
				telephone: auth?.restaurant?.telephone || '',
				message: '',
				allow_telephone: true,
			});
			if (onSend) {
				onSend();
			}
		}).catch((err) => {
			toggleFullscreenLoader(false);
			setErrors(err.response.data.errors);
			dispatch(setChangeNeeded('contact'));
		});
	}, [auth, dispatch, info]);
	

	useChangesCanceled('contact', () => {
		setErrors({});
		setInfo({
			name: auth?.first_name || '',
			email: auth?.email || '',
			telephone: auth?.restaurant?.telephone || '',
			message: '',
			allow_telephone: true,
		});
	}, [auth]);

    useEffect(() => {
		dispatch(setChangeNeeded('contact'));
        setInfo({
			name: auth?.first_name || '',
			email: auth?.email || '',
			telephone: auth?.restaurant?.telephone || '',
			message: '',
			allow_telephone: true,
		});
    }, [auth, dispatch]);

	const handleInput = ({ name, value }: any) => {
		dispatch(setChangeNeeded('contact'));
		setInfo({
			...info,
			[name]: value,
		});
	};

    return (<>
        <p className="sv-description">
            Wil je ons een compliment of tips geven? Dat mag natuurlijk altijd, net zo als vragen stellen.
        </p>
		<Form.Input
			error={errors.name}
			name="name"
			onChange={handleInput}
			placeholder="Naam"
			value={info.name}
        />
		<Form.Input
			error={errors.telephone}
			name="telephone"
			onChange={handleInput}
			placeholder="Telefoonnummer"
			value={info.telephone}
        />
		<Form.Input
			error={errors.email}
			name="email"
			onChange={handleInput}
			placeholder="E-mailadres"
			value={info.email}
        />
		<Form.Textarea
			error={errors.message}
			name="message"
			onChange={handleInput}
			placeholder="Typ je bericht"
			rows={6}
			value={info.message}
        />
    </>);
}

export default Contact;
